import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.ACTION_CREATE_SUCCESS, action: data.action });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.ACTION_CREATE_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const actionCreate = (data) => {
  return async (dispatch) => {
    dispatch({ type: CONST.ACTION_CREATE_REQUEST });

    axios.post('/actions', data).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
