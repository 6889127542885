import Swal from 'sweetalert2';
import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.SUBSCRIPTION_EDIT_ADDRESS_SUCCESS, subscription: data.subscription });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.SUBSCRIPTION_EDIT_ADDRESS_ERROR, error });
  Swal.fire({
    title: 'Error',
    type: 'error',
    text: error?.message,
    timer: 3500,
  });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const subscriptionEditAddress = (subscriptionId, addressId) => {
  return async (dispatch) => {
    dispatch({ type: CONST.SUBSCRIPTION_EDIT_ADDRESS_REQUEST });
    const body = { address_id: addressId };

    axios
      .patch(`/subscriptions/${subscriptionId}/address`, body)
      .then((response) => {
        handleResponse(dispatch, response);
      })
      .catch((error) => {
        onFailed(dispatch, error?.response?.data);
      });
  };
};
