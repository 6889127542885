import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const planQuickFiltersIsLoading = (isLoading) => {
  return {
    type: CONST.PLAN_QUICK_FILTERS_LOADING,
    isLoading,
  };
};
export const planQuickFiltersList = (params) => {
  return async (dispatch) => {
    dispatch(planQuickFiltersIsLoading(true));

    return axios
      .get('/plan-quick-filters', { params })
      .then((response) => {
        dispatch({
          type: CONST.PLAN_QUICK_FILTERS_LIST_REQUEST,
          data: response.data,
        });
      })
      .finally(() => {
        dispatch(planQuickFiltersIsLoading(false));
      });
  };
};

export const createQuickFilter = (body) => {
  return async (dispatch) => {
    dispatch(planQuickFiltersIsLoading(true));

    return axios
      .post('/plan-quick-filters', body)
      .then((response) => {
        dispatch({
          type: CONST.PLAN_QUICK_FILTERS_CREATE_REQUEST,
          data: response.data,
        });
      })
      .finally(() => {
        dispatch(planQuickFiltersIsLoading(false));
      });
  };
};

export const updateQuickFilter = (body) => {
  return async (dispatch) => {
    dispatch(planQuickFiltersIsLoading(true));

    return axios
      .put(`/plan-quick-filters/${body.id}`, body)
      .then((response) => {
        dispatch({
          type: CONST.PLAN_QUICK_FILTERS_UPDATE_REQUEST,
          data: response.data,
        });
      })
      .finally(() => {
        dispatch(planQuickFiltersIsLoading(false));
      });
  };
};
