import { Grid } from '@material-ui/core';
import { camelizeKeys } from 'humps';
import React, { Component } from 'react';

import { axios } from '../../../services/axios';
import EditMealAttributesButton from '../attributes/EditMealAttributesButton';
import ChangeStatus from '../changeStatus/changeStatus';
import DependentOrdersModal from '../components/dependentOrdersModal';
import DuplicateMeal from '../components/duplicate';
import EditMeal from '../edit/edit';
import { AMAZON_CLOUD_FRONT_URL } from '../../../constants/meals';

export default class ShowMeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meal: {},
      loading: true,
      merchants: [],
      allProductTypes: [],
    };
    this.getData = this.getData.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.listAllMerchants = this.listAllMerchants.bind(this);
    this.listProductTypes = this.listProductTypes.bind(this);
  }

  componentDidMount() {
    document.title = 'Meal Details';
    this.getData();
    this.listAllMerchants();
    this.listProductTypes();
  }

  parseProductTypes(productTypes) {
    return productTypes.map((productType) => ({
      ...productType,
      value: productType.id || productType.value,
      label: productType.enName,
    }));
  }

  listProductTypes = () => {
    axios
      .get('/product-types')
      .then((res) => camelizeKeys(res.data))
      .then((productTypes) => {
        this.setState({
          allProductTypes: this.parseProductTypes(productTypes),
        });
      });
  };

  getData() {
    axios.get(`/meals/${this.props.match.params.id}`).then((response) => {
      this.setState(() => {
        return {
          meal: { ...response.data, images: JSON.parse(response.data.images) },
          loading: false,
        };
      });
    });
  }
  handleDuplicate(data) {
    this.setState({
      meal: data.meal,
      loading: false,
    });
  }
  listAllMerchants() {
    axios
      .get('/merchants')
      .then((response) => {
        this.setState({
          merchants: response.data.data,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  render() {
    if (this.state.loading) {
      return <div className={'loader'} />;
    } else {
      return (
        <div className={'row'}>
          <DependentOrdersModal />
          <div className={'col-md-7'}>
            {/* begin::Portlet*/}
            <div className={'m-portlet m-portlet--tab'}>
              <div className={'m-portlet__head'}>
                <div className={'m-portlet__head-caption'}>
                  <div className={'m-portlet__head-title'}>
                    <span className={'m-portlet__head-icon m--hide'}>
                      <i className={'la la-gear'} />
                    </span>
                    <h3 className={'m-portlet__head-text'}>{'Meal Details'}</h3>
                  </div>
                </div>

                <div className={'m-portlet__head-tools'}>
                  <ChangeStatus
                    mealId={this.state.meal.item_id}
                    mealStatus={this.state.meal.status}
                  />
                  {JSON.parse(localStorage.getItem('user')) &&
                    JSON.parse(localStorage.getItem('user')).user_permissions.includes(
                      'EDIT_MEALS',
                    ) && (
                      <EditMeal
                        meal={this.state.meal}
                        merchants={this.state.merchants}
                        allProductTypes={this.state.allProductTypes}
                      />
                    )}

                  <EditMealAttributesButton meal={this.state.meal} />
                  <DuplicateMeal
                    handleDuplicated={this.handleDuplicate}
                    meal={this.state.meal}
                    merchants={this.state.merchants}
                    allProductTypes={this.state.allProductTypes}
                    onMenuDuplicated={this.getData}
                  />
                </div>
              </div>

              <div className={'m-portlet__body'} id={'meal-view-data-section'}>
                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'SKU'}</label>
                  <div className={'col-9'}>
                    <label id={'meal-sku-number'} className={'col-form-label'}>
                      {this.state.meal.sku}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Arabic Name'}</label>
                  <div className={'col-9'}>
                    <label id={'meal-ar-name'} className={'col-form-label'}>
                      {this.state.meal.ar_name}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'English Name'}</label>
                  <div className={'col-9'}>
                    <label id={'meal-en-name'} className={'col-form-label'}>
                      {this.state.meal.en_name}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Arabic Description '}</label>
                  <div className={'col-9'}>
                    <label
                      id={'meal-ar-description'}
                      className={'col-form-label'}
                      dangerouslySetInnerHTML={{ __html: this.state.meal.ar_description }}
                    />
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'English Description '}</label>
                  <div className={'col-9'}>
                    <label
                      id={'meal-en-description'}
                      className={'col-form-label'}
                      dangerouslySetInnerHTML={{ __html: this.state.meal.en_description }}
                    />
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Arabic Full Description '}</label>
                  <div className={'col-9'}>
                    <label
                      id={'meal-ar-full-description'}
                      className={'col-form-label'}
                      dangerouslySetInnerHTML={{ __html: this.state.meal.ar_full_description }}
                    />
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'English Full Description '}</label>
                  <div className={'col-9'}>
                    <label
                      id={'meal-en-full-description'}
                      className={'col-form-label'}
                      dangerouslySetInnerHTML={{ __html: this.state.meal.en_full_description }}
                    />
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Premium Price'}</label>
                  <div className={'col-9'}>
                    <label
                      id={'meal-en-description'}
                      className={'col-form-label'}
                      dangerouslySetInnerHTML={{ __html: this.state.meal.premium_price }}
                    />
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Status'}</label>
                  <div className={'col-9'}>
                    <label
                      id={'meal-en-description'}
                      className={'col-form-label'}
                      dangerouslySetInnerHTML={{ __html: this.state.meal.status }}
                    />
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Merchant '}</label>
                  <div className={'col-9'}>
                    <label id={'meal-merchant'} className={'col-form-label'}>
                      {(this.state.meal.merchant || {}).restaurant_name}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Dish'}</label>
                  <div className={'col-9'}>
                    <label id={'meal-dish'} className={'col-form-label'}>
                      {this.state.meal.dish}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Cost'}</label>
                  <div className={'col-9'}>
                    <label id={'meal-cost'} className={'col-form-label'}>
                      {this.state.meal.cost}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Market Price'}</label>
                  <div className={'col-9'}>
                    <label id={'meal-market-price'} className={'col-form-label'}>
                      {this.state.meal.market_price}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Stock'}</label>
                  <div className={'col-9'}>
                    <label id={'meal-stock'} className={'col-form-label'}>
                      {this.state.meal.stock}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Color'}</label>
                  <div className={'col-9'}>
                    <label
                      id={'meal-color'}
                      className={'col-form-label'}
                      style={{ width: '60px', height: '30px', background: this.state.meal.color }}
                    />
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Meal Number'}</label>
                  <div className={'col-9'}>
                    <label id={'meal-number'} className={'col-form-label'}>
                      {!!this.state.meal.label_number
                        ? this.state.meal.label_number
                        : 'This meal has no number'}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Calories '}</label>
                  <div className={'col-9'}>
                    <label id={'meal-calories'} className={'col-form-label'}>
                      {this.state.meal.calories}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Protein '}</label>
                  <div className={'col-9'}>
                    <label id={'meal-protein'} className={'col-form-label'}>
                      {this.state.meal.protein}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Fats '}</label>
                  <div className={'col-9'}>
                    <label id={'meal-fats'} className={'col-form-label'}>
                      {this.state.meal.fat}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Carbs '}</label>
                  <div className={'col-9'}>
                    <label id={'meal-carbs'} className={'col-form-label'}>
                      {this.state.meal.carb}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Product Types: '}</label>
                  <div className={'col-9'}>
                    <label id={'meal-productTypes'} className={'col-form-label'}>
                      {this.state.meal?.product_types
                        ?.map((productType) => productType.en_name)
                        .join(', ')}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Zoho ID '}</label>
                  <div className={'col-9'}>
                    <label id={'meal-zoho-id'} className={'col-form-label'}>
                      <label className={'col-form-label'}>
                        {this.state.meal.zoho_id ?? (
                          <label className={'text-danger'}>{'not Set'}</label>
                        )}
                      </label>
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row align-items-center'}>
                  <label className={'col-3 col-form-label'}>{'Tags '}</label>
                  <div className={'col-9 d-flex align-items-center'}>
                    <label id={'meal-tags'} className={'col-form-label'}>
                      {this.state.meal?.tags?.map((tag) => tag.name).join(', ')}
                    </label>
                    {JSON.parse(localStorage.getItem('user')) &&
                      JSON.parse(localStorage.getItem('user')).user_permissions.includes(
                        'EDIT_MEALS',
                      ) && (
                        <EditMeal
                          meal={this.state.meal}
                          merchants={this.state.merchants}
                          editButtonClassName={'btn-sm ml-2'}
                        />
                      )}
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Ratings Average '}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.meal?.rating_info?.ratings_average}
                    </label>
                  </div>
                </div>
              </div>
              {/* end::Portlet*/}
            </div>
          </div>
          <div className={'col-md-5'}>
            {/* begin::Portlet*/}
            <div className={'m-portlet m-portlet--tab'}>
              <div className={'m-portlet__head'}>
                <div className={'m-portlet__head-caption'}>
                  <div className={'m-portlet__head-title'}>
                    <span className={'m-portlet__head-icon m--hide'}>
                      <i className={'la la-gear'} />
                    </span>
                    <h3 className={'m-portlet__head-text'}>{'Meal Images'}</h3>
                  </div>
                </div>
              </div>

              <div className={'m-portlet__body'}>
                <div className={'m-form__group row'}>
                  <label className={'col-12 col-form-label'}>{'Cover Image'}</label>
                  <div className={'col-12'}>
                    {this.state.meal.cover_image && (
                      <img
                        className={'m--img-rounded m--marginless'}
                        style={{ width: '320px', height: '220px' }}
                        src={`${AMAZON_CLOUD_FRONT_URL}/meals/${this.state.meal.cover_image}`}
                      />
                    )}
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-12 col-form-label'}>{'Outside Image'}</label>
                  <div className={'col-12'}>
                    {this.state.meal.photo && (
                      <img
                        className={'m--img-rounded m--marginless'}
                        style={{ width: '320px', height: '220px' }}
                        src={`${AMAZON_CLOUD_FRONT_URL}/meals/${this.state.meal.photo}`}
                      />
                    )}
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-12 col-form-label'}>{'Images'}</label>
                  <div className={'col-12'}>
                    {this.state.meal?.images ? (
                      <>
                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                          {this.state.meal.images?.map?.((image, index) => {
                            return (
                              <Grid spacing={2} key={index} item xs={2} sm={4} md={4}>
                                <img
                                  alt={image.name}
                                  className={'m--img-rounded m--marginless'}
                                  style={{ width: '120px', height: '84px', objectFit: 'cover' }}
                                  src={image}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* end::Portlet*/}
            </div>
          </div>
        </div>
      );
    }
  }
}
