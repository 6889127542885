import { camelizeKeys } from 'humps';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { deleteImage, uploadImage } from '../../../api/meals/mealMultiImages';
import { MB } from '../../../constants/filesSizes';
import { axios } from '../../../services/axios';
import Alert from '../../../utils/alert';
import MealForm from '../components/form';

export default class EditMeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showLoader: true,
      loaderMessage: '',
      showBody: false,
      previousMeal: this.props.meal,
      ar_name: this.props.meal.ar_name,
      en_name: this.props.meal.en_name,
      ar_description: this.props.meal.ar_description,
      en_description: this.props.meal.en_description,
      ar_full_description: this.props.meal.ar_full_description,
      en_full_description: this.props.meal.en_full_description,
      merchant_id: this.props.meal.merchant_id,
      premium_price: this.props.meal.premium_price,
      protein: this.props.meal.protein,
      carbs: this.props.meal.carb,
      fats: this.props.meal.fat,
      calories: this.props.meal.calories,
      dish: this.props.meal.dish,
      cost: this.props.meal.cost,
      market_price: this.props.meal.market_price,
      stock: this.props.meal.stock,
      color: this.props.meal.color,
      label_number: this.props.meal.label_number,
      coverImg: this.props.meal.cover_image,
      outsideImg: this.props.meal.photo,
      tags: this.props.meal.tags,
      productTypes: this.parseProductTypes(camelizeKeys(this.props.meal.product_types)),
      images: this.props.meal.images || [],
      imagesToBeDeleted: [],
      imagesToBeUploaded: [],
      validation: {
        ar_name: '',
        en_name: '',
        ar_description: '',
        en_description: '',
        ar_full_description: '',
        en_full_description: '',
        merchant_id: '',
        protein: '',
        carbs: '',
        fats: '',
        calories: '',
        dish: '',
        cost: '',
        market_price: '',
        stock: '',
        color: '',
        label_number: '',
        coverImg: '',
        outsideImg: '',
        images: '',
        premium_price: '',
      },
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
    this.uploadMultiImages = this.uploadMultiImages.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.handleDeleteImages = this.handleDeleteImages.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetToBeModifiedImages = this.resetToBeModifiedImages.bind(this);
  }

  componentDidMount() {
    this.setState({
      showBody: true,
      showLoader: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(this.props.meal, prevProps.meal)) {
      this.setState({
        ar_name: this.props.meal.ar_name,
        en_name: this.props.meal.en_name,
        ar_description: this.props.meal.ar_description,
        en_description: this.props.meal.en_description,
        ar_full_description: this.props.meal.ar_full_description,
        en_full_description: this.props.meal.en_full_description,
        merchant_id: this.props.meal.merchant_id,
        protein: this.props.meal.protein,
        carbs: this.props.meal.carb,
        fats: this.props.meal.fat,
        calories: this.props.meal.calories,
        dish: this.props.meal.dish,
        cost: this.props.meal.cost,
        market_price: this.props.meal.market_price,
        stock: this.props.meal.stock,
        color: this.props.meal.color,
        label_number: this.props.meal.label_number,
        coverImg: this.props.meal.cover_image,
        outsideImg: this.props.meal.photo,
        premium_price: this.props.meal.premium_price,
      });
    }
  }

  parseProductTypes(productTypes) {
    return productTypes.map((productType) => ({
      ...productType,
      value: productType.id || productType.value,
      label: productType.enName,
    }));
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    this.setState({ [inputName]: inputValue });
  }

  handleSelectedTagsChange = (tags) => this.setState({ tags });

  handleSelectedProductTypesChange = (productTypes) => this.setState({ productTypes });

  async uploadMultiImages() {
    this.setState({
      loaderMessage: 'uploading Images...',
    });
    let newArr = [...this.state.images];
    newArr = newArr.filter((img) => !(img instanceof File));
    const urls = [];
    for (const image of this.state.imagesToBeUploaded) {
      const res = await uploadImage(image);
      if (res?.data?.image_url) {
        urls.push(res.data.image_url);
      } else {
        Alert.error('Something went wrong');
      }
    }

    this.setState({ images: [...newArr, ...urls], loaderMessage: '' });
  }

  deleteImage(image) {
    this.setState({
      loaderMessage: 'deleting images...',
    });

    if (!(image instanceof File)) {
      const newImages = [...this.state.images];
      const editedImages = newImages.filter((img) => img !== image);
      const prefixToRemove = 'https://s3.me-south-1.amazonaws.com/cdn.dailymealz.app';
      const trimmedImagePath = image?.replace(prefixToRemove, '');
      this.setState({
        imagesToBeDeleted: [...this.state.imagesToBeDeleted, trimmedImagePath],
        images: editedImages,
      });
    } else {
      const newImages = [...this.state.imagesToBeUploaded];
      const editedImages = newImages.filter((img) => img !== image);
      this.setState({ imagesToBeUploaded: editedImages });
    }
    this.setState({
      loaderMessage: '',
    });
  }
  handleUploadPhoto(e) {
    const inputName = e.target.name;
    const isMultiPicuter = inputName === 'images';
    const files = Array.from(e.target.files);
    const isMaxSizeExceeded = files.some((img) => img.size > MB);
    if (
      isMultiPicuter &&
      files.length + this.state.images.length + this.state.imagesToBeUploaded.length > 4
    ) {
      Alert.error('Maximum number of pictures is 4.');
    } else if (isMultiPicuter && isMaxSizeExceeded) {
      Alert.error('please make sure that every image dose not exceed 1MB');
    } else {
      isMultiPicuter
        ? this.setState({ imagesToBeUploaded: [...this.state.imagesToBeUploaded, ...files] })
        : this.setState({ [inputName]: files[0] });
    }
  }

  async handleDeleteImages() {
    this.setState({
      loaderMessage: 'deleting images...',
    });
    for (const image of this.state.imagesToBeDeleted) {
      const res = await deleteImage(image);

      if (!res) {
        this.setState({ images: [...this.state.images, image] });
        Alert.error('Something went wrong');
      }
    }
    this.setState({
      loaderMessage: '',
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      showLoader: true,
      showBody: false,
    });

    await this.handleDeleteImages();
    await this.uploadMultiImages();

    const data = new FormData();
    data.append('ar_name', this.state.ar_name);
    data.append('en_name', this.state.en_name);
    data.append('ar_description', this.state.ar_description);
    data.append('en_description', this.state.en_description);
    data.append('ar_full_description', this.state.ar_full_description);
    data.append('en_full_description', this.state.en_full_description);
    data.append('merchant_id', this.state.merchant_id);
    if (this.state.premium_price) {
      data.append('premium_price', this.state.premium_price);
    }
    data.append('protein', this.state.protein);
    data.append('carb', this.state.carbs);
    data.append('fat', this.state.fats);
    data.append('calories', this.state.calories);
    data.append('dish', this.state.dish);
    data.append('cost', this.state.cost);
    data.append('market_price', this.state.market_price);
    data.append('color', this.state.color);
    data.append('_method', 'PUT');
    const labelNumber = Number(this.state.label_number);
    data.append('label_number', labelNumber);

    if (this.state.tags?.length > 0) {
      const tagIds = this.state.tags?.map((tag) => tag.id);
      tagIds.forEach((id, index) => data.append(`tags[${index}]`, id));
    } else {
      data.append('tags', null);
    }

    if (this.state.productTypes?.length > 0) {
      const productTypesIds = this.state.productTypes?.map((productType) => productType.value);
      productTypesIds.forEach((id, index) => data.append(`product_types[${index}]`, id));
    } else {
      data.append('product_types', null);
    }

    // check if stock is send and add it to request body
    if (this.state.stock) {
      data.append('stock', this.state.stock);
    }
    if (this.state.coverImg) {
      data.append('coverImg', this.state.coverImg);
    }
    if (this.state.outsideImg) {
      data.append('outsideImg', this.state.outsideImg);
    }
    if (this.state.images.length > 0) {
      this.state.images.forEach((image, index) => data.append(`images[${index}]`, image));
    } else {
      data.append('images', []);
    }

    axios
      .post(`/meals/${this.props.meal.item_id}`, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            showLoader: false,
            showBody: true,
            modal: false,
          });
          swal.fire({
            title: 'Success',
            type: 'success',
            text: 'Meal updated successfully!',
            timer: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((e) => {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false,
        });
        let errorMessages = 'Something went wrong!';
        if (e.response?.data?.error) {
          errorMessages = Object.keys(e.response?.data?.error)
            .map((key) => {
              return `<pre>${key} : ${e.response.data.error[key]}</pre>`;
            })
            .join(' ');
        } else if (e.response.data?.message) {
          errorMessages = e.response.data?.message;
        }

        swal.fire({
          title: 'Error',
          type: 'error',
          html: errorMessages,
          timer: 6000,
        });
      });
  }

  resetToBeModifiedImages() {
    this.setState({
      images: [...this.state.images, ...this.state.imagesToBeDeleted],
      imagesToBeDeleted: [],
      imagesToBeUploaded: [],
      modal: !this.state.modal,
    });
  }

  render() {
    return (
      <div>
        {/* beign modal form */}
        <Button
          id={'meal-edit-button'}
          color={'success'}
          onClick={this.toggle}
          disabled={!this.props.meal.item_id}
          className={`m-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle ${this
            .props.editButtonClassName || ''}`}>
          <i className={'la la-edit m--font-brand'} />
        </Button>
        <Modal
          size={'lg'}
          isOpen={this.state.modal}
          toggle={this.toggle}
          onClosed={() => this.handleSelectedTagsChange(this.props.meal?.tags)}>
          <Form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={this.toggle}>{'Edit Meal'}</ModalHeader>
            <ModalBody>
              <>
                <h4 style={{ textAlign: 'center' }}> {this.state.loaderMessage}</h4>
                <div
                  style={this.state.showLoader ? {} : { display: 'none' }}
                  className={'loader'}
                />
              </>
              <div
                style={this.state.showBody ? {} : { display: 'none' }}
                className={'modal-body-content'}>
                {/* meal form */}
                <MealForm
                  formType={'edit'}
                  merchants={this.props.merchants}
                  allProductTypes={this.props.allProductTypes}
                  values={{
                    ...this.state,
                    images: [...this.state.images, ...this.state.imagesToBeUploaded],
                  }}
                  handleChange={this.handleChange}
                  handleSelectedTagsChange={this.handleSelectedTagsChange}
                  handleSelectedProductTypesChange={this.handleSelectedProductTypesChange}
                  parseProductTypes={this.parseProductTypes}
                  handleUploadPhoto={this.handleUploadPhoto}
                  uploadMultiImages={this.uploadMultiImages}
                  deleteImage={this.deleteImage}
                />
              </div>
            </ModalBody>
            <ModalFooter style={this.state.showBody ? {} : { display: 'none' }}>
              <input
                id={'meal-edit-submit-btn'}
                type={'submit'}
                value={'Submit'}
                color={'primary'}
                className={'btn btn-primary'}
              />
              <Button
                color={'danger'}
                onClick={() => {
                  this.resetToBeModifiedImages();
                }}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        {/* end modal form */}
      </div>
    );
  }
}
