import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, data: [], error: null, total: 0, pageCount: 0 };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.CUSTOMER_ORDERS_REPORT_REQUEST:
      return { ...INITIAL_STATE, loading: true };
    case CONST.CUSTOMER_ORDERS_REPORT_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        data: action.data.data.data,
        total: action.data.data.total,
        pageCount: action.data.data.last_page,
      };
    case CONST.CUSTOMER_ORDERS_REPORT_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    default:
      return state;
  }
};
