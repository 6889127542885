import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, subscription: null, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.SUBSCRIPTION_START_DATE_CHANGE_REQUEST:
      return { ...INITIAL_STATE, loading: true };
    case CONST.SUBSCRIPTION_START_DATE_CHANGE_SUCCESS:
      return { ...INITIAL_STATE, loading: false, data: action.data };
    case CONST.SUBSCRIPTION_START_DATE_CHANGE_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    case CONST.CLOSE_MODAL:
      return { ...INITIAL_STATE, loading: false, error: null, subscription: null };
    default:
      return state;
  }
};
