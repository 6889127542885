import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { axios } from '../../../services/axios';
import DuplicateMenuActionForm from '../components/duplicateAction';

export default class AddMealForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showBody: false,
      showLoader: true,
      merchants: [],
      meals: [],
      merchant_id: '',
      meal_id: '',
      stock: 0,
      date: '',
    };
    this.toggle = this.toggle.bind(this);
    this.listMerchantMeals = this.listMerchantMeals.bind(this);
    this.handleMealChange = this.handleMealChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditCase = this.handleEditCase.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    this.setState({
      showLoader: false,
      showBody: true,
    });
  }

  handleEditCase() {
    const dateItemsSates = this.props.itemsStates;
    if (dateItemsSates.length > 0) {
      dateItemsSates.find((itemState) => {
        if (itemState.divId === this.props.divId) {
          this.setState({
            meal_id: itemState.meal.item_id,
            merchant_id: itemState.meal.merchant_id,
            stock: itemState.meal.stock,
          });
        }
      });
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  listMerchantMeals(event) {
    const merchantId = event.target.value;

    this.setState({
      merchant_id: merchantId,
    });

    axios
      .get(`/merchants/${merchantId}/meals`)
      .then((response) => {
        this.setState({
          meals: response.data.data,
          showBody: true,
          showLoader: false,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handleMealChange(event) {
    this.setState({
      meal_id: event.target.value,
      stock: event.target[event.target.selectedIndex].getAttribute('data-stock'),
      date: this.props.date,
    });
  }

  handleAddItem(event) {
    event.preventDefault();
    this.handleSubmit(true);
  }

  handleSubmit(duplicateActionValue) {
    if (this.state.meal_id > 0) {
      this.setState({
        showLoader: true,
        showBody: false,
      });

      const choosedMeal = this.state.meals.find((item) => {
        return item.item_id === parseInt(this.state.meal_id, 10);
      });

      const data = {
        date: this.state.date,
        item: this.state.meal_id,
        stock: this.state.stock,
        meal: choosedMeal,
        merchant_id: this.state.merchant_id,
        itemIndex: this.props.itemIndex,
        duplicate: duplicateActionValue,
      };
      if (this.props.menu_id !== undefined) {
        data['menu_id'] = this.props.menu_id;
      }
      this.props.handleAddMeal(data);

      this.setState({
        showLoader: false,
        showBody: true,
        modal: false,
      });
    } else {
      this.setState({
        showLoader: false,
        showBody: true,
        modal: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Button
          onClick={this.toggle}
          className={
            'm-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle'
          }>
          {this.props.children}
        </Button>
        <Modal isOpen={this.state.modal}>
          <form>
            <ModalHeader>{'Choose Meal'}</ModalHeader>
            <ModalBody>
              <div style={this.state.showLoader ? {} : { display: 'none' }} className={'loader'} />
              <div
                style={this.state.showBody ? {} : { display: 'none' }}
                className={'modal-body-content'}>
                <div className={'form-group m-form__group'}>
                  <label>{'Merchant'}</label>
                  <select
                    className={'form-control'}
                    value={this.state.merchant_id}
                    onChange={this.listMerchantMeals}>
                    <option key={'default'} value={''}>
                      {'Select Merchant'}
                    </option>
                    {this.props.merchants.map(function(obj, i) {
                      return (
                        <option key={i} value={obj.id}>
                          {obj.restaurant_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={'form-group m-form__group'}>
                  <label>{'Item'}</label>
                  <select
                    className={'form-control'}
                    value={this.state.meal_id}
                    onChange={this.handleMealChange}
                    required>
                    <option key={'default'} value={''}>
                      {'Select Meal'}
                    </option>
                    {this.state.meals &&
                      this.state.meals.map((obj) => (
                        <option key={obj.item_id} data-stock={obj.stock} value={obj.item_id}>
                          {obj.item_id}
                          {' - '}
                          {obj.ar_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className={'form-group m-form__group'}>
                  <label>{'Stock'}</label>
                  <input
                    type={'text'}
                    className={'form-control'}
                    disabled
                    name={'country'}
                    value={this.state.stock}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {this.props.pageRequestForm === 'show' ? (
                <DuplicateMenuActionForm
                  actionType={'add'}
                  date={this.state.date}
                  handleDuplicateAction={this.handleSubmit}
                />
              ) : (
                <input
                  type={'submit'}
                  value={'Submit'}
                  color={'primary'}
                  onClick={this.handleAddItem}
                  className={'btn btn-primary'}
                />
              )}
              <Button color={'danger'} onClick={this.toggle}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}
