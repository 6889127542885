import { axios } from '../axios';

export default async function groupAssignService(clientsIds, driverId, date) {
  const body = {
    clients: clientsIds,
    driver_id: driverId,
    date: date,
  };

  return axios.post('/group-assign', body);
}
