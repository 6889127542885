import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { axios } from '../../../services/axios';
import { ChangeStatusButton } from '../components/ChangeStatusButton';
import EditMerchantZohoButton from '../components/EditMerchantZohoButton';
import { AMAZON_CLOUD_FRONT_URL } from '../../../constants/meals';

export default class ShowMerchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchant: {},
      loading: false,
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    document.title = 'Merchant Details';
    this.getData();
  }

  getData() {
    axios.get(`/merchants/${this.props.match.params.id}`).then((response) => {
      this.setState({
        merchant: response.data,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <div className={'loader'} />;
    } else {
      return (
        <div className={'row'}>
          <div className={'col-md-12'}>
            {/* begin::Portlet*/}
            <div className={'m-portlet m-portlet--tab'}>
              <div className={'m-portlet__head'}>
                <div className={'m-portlet__head-caption'}>
                  <div className={'m-portlet__head-title'}>
                    <span className={'m-portlet__head-icon m--hide'}>
                      <i className={'la la-gear'} />
                    </span>
                    <h3 className={'m-portlet__head-text'}>{'Merchant Details'}</h3>
                  </div>
                </div>
                <div className={'m-portlet__head-tools'}>
                  <ChangeStatusButton
                    merchantStatus={this.state.merchant.status}
                    merchantId={this.state.merchant.id}
                  />
                  {JSON.parse(localStorage.getItem('user')) &&
                    JSON.parse(localStorage.getItem('user')).user_permissions.includes(
                      'EDIT_MERCHANT',
                    ) && (
                      <>
                        <Link to={`${this.state.merchant.id}/edit`}>
                          <button
                            className={
                              'btn btn-lg btn-secondary m-btn m-btn--icon m-btn--icon-only m-btn--pill btn-success'
                            }>
                            <i className={'la la-edit m--font-brand'} />
                          </button>
                        </Link>
                        <EditMerchantZohoButton merchant={this.state.merchant} />
                      </>
                    )}
                </div>
              </div>
              <div className={'m-portlet__body'}>
                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Merchant Name'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.restaurant_name}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Merchant Phone '}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.restaurant_phone}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Merchant Slug'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.restaurant_slug}
                    </label>
                  </div>
                </div>
                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Merchant Status'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>{this.state.merchant.status}</label>
                  </div>
                </div>
                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Has Labeling'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.has_labeling === 1 ? 'YES' : 'NO'}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Is catering supported'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.is_catering_supported === 1 ? 'YES' : 'NO'}
                    </label>
                  </div>
                </div>
                
                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Contact Name'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>{this.state.merchant.contact_name}</label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Contact Phone'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>{this.state.merchant.contact_phone}</label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Secondary Contact Phone'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.secondary_contact_phone}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Contact Email'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>{this.state.merchant.contact_email}</label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Country '}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {(this.state.merchant.country || {}).country_name}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'City'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {(this.state.merchant.merchant_city || {}).en_name}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Street'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>{this.state.merchant.street}</label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Zoho ID'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.zoho_id ?? (
                        <span className={'text-danger'}>{'not Set'}</span>
                      )}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Tax Registration Id'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.tax_registration_id ?? (
                        <span className={'text-danger'}>{'not Set'}</span>
                      )}
                    </label>
                  </div>
                </div>

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Contract'}</label>
                  <div className={'col-9'}>
                    {this.state.merchant.contract ? (
                      <a
                        className={'btn btn-success text-white'}
                        href={this.state.merchant.contract}
                        target={'_blank'}
                        rel={'noopener noreferrer'}>
                        {'Open Contract'}
                      </a>
                    ) : (
                      <label className={'col-form-label'}>
                        <span className={'text-danger'}>{'not Set'}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>{'Merchant capacity'}</label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>
                      {this.state.merchant.capacity ?? (
                        <span className={'text-danger'}>{'not Set'}</span>
                      )}
                    </label>
                  </div>
                </div>

                {this.state.merchant.logo ? (
                  <div className={'m-form__group row'}>
                    <label className={'col-12 col-form-label'}>{'Logo'}</label>
                    <div className={'col-12'}>
                      {
                        <img
                          style={{ width: '550px', height: '250px' }}
                          src={`${AMAZON_CLOUD_FRONT_URL}/merchants/${this.state.merchant.logo}`}
                        />
                      }
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {/* end::Portlet*/}
          </div>
        </div>
      );
    }
  }
}
