import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.VOUCHER_LIST_SUCCESS, data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.VOUCHER_LIST_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const voucherList = ({ params }) => {
  return async (dispatch) => {
    dispatch({ type: CONST.VOUCHER_LIST_REQUEST });

    axios.get('/vouchers', { params }).then((response) => {
      handleResponse(dispatch, response);
    });
    // ********* END CALL API
  };
};
