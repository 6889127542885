import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, data: [], error: null, updatedData: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.MAP_ORDERS_LIST_REQUEST:
      return { ...INITIAL_STATE, loading: true };
    case CONST.MAP_ORDERS_LIST_SUCCESS:
      return { ...INITIAL_STATE, loading: false, data: action.data };
    case CONST.MAP_ORDERS_LIST_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    case CONST.MAP_ORDERS_LIST_UPDATE:
      return {
        ...INITIAL_STATE,
        loading: false,
        data: action.data,
        updatedData: action.updatedData,
      };
    default:
      return state;
  }
};
