import * as CONST from '../../constants';

const INITIAL_STATE = {
  location: null,
  routing: {
    routing: [],
    totalTime: 0,
    totalDistance: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.MAP_ZOOM_TO:
      return { ...state, location: action.location };
    case CONST.MAP_ROUTING:
      return { ...state, routing: action.routing };
    default:
      return state;
  }
};
