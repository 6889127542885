import { axios } from '../../../../services/axios';
import * as CONST from '../../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.ASSIGN_PLAN_MENU_SUCCESS, data: data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.ASSIGN_PLAN_MENU_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const assignMenuToPlan = (planId, zoneId, menuId) => {
  return async (dispatch) => {
    dispatch({ type: CONST.ASSIGN_PLAN_MENU_REQUEST });

    const body = { zone_id: zoneId, menu_id: menuId };

    axios.post(`/plans/${planId}/assign-menu`, body).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
