import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.CUSTOMER_ORDERS_REPORT_SUCCESS, data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.CUSTOMER_ORDERS_REPORT_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const customerOrdersReport = ({ params }) => {
  return async (dispatch) => {
    dispatch({ type: CONST.CUSTOMER_ORDERS_REPORT_REQUEST });

    axios.get('/reports/customer-orders', { params }).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
