import { combineReducers } from 'redux';

import auth from './auth';
import assignersList from './auth/admins';
import cancelReaonsCreate from './cancel-reasons/create';
import cancelReaonsList from './cancel-reasons/list';
import categoryCreate from './category/create';
import categoryDelete from './category/delete';
import categoriesList from './category/list';
import categoryTypeCreate from './category/sub-category/create';
import categoryTypeDelete from './category/sub-category/delete';
import categoryTypesList from './category/sub-category/list';
import { cityList } from './city/list';
import clientAddressList from './client/address/list';
import clientComplaintsList from './client/complaint/list';
import clientIntercomNotificationCreate from './client/intercom-notification/create';
import companyCreate from './company/create';
import companyEdit from './company/edit';
import actionCreate from './complaint-action/create';
import actionDelete from './complaint-action/delete';
import actionsList from './complaint-action/list';
import statusCreate from './complaint-status/create';
import statusDelete from './complaint-status/delete';
import statusList from './complaint-status/list';
import complaintActionAdd from './complaint/action/add';
import complaintCommentCreate from './complaint/comment/create';
import ticketCommentsList from './complaint/comment/list';
import complaintCreate from './complaint/create';
import complaintEdit from './complaint/edit';
import complaintList from './complaint/list';
import complaintShow from './complaint/show';
import assignedComplaintsList from './dashboard/assigned-complaints';
import { driverListAll } from './driver/list';
import foodCategory from './food-categories';
import groups from './group';
import groupAssign from './map/assign/groupAssign.reducer';
import assignOrder from './map/assign/singleAssign.reducer';
import driverOrders from './map/driverOrders.reducer';
import mapDrivers from './map/drivers.reducer';
import mapFilter from './map/filter.reducer';
import map from './map/map.reducer';
import mapOrders from './map/orders.reducer';
import mealList from './meal/list';
import validationModal from './meal/validationModal';
import menuDuplicate from './menu/duplicate';
import menuEdit from './menu/edit';
import menuList from './menu/list';
import merchantList from './merchant/list';
import merchantMealList from './merchant/meal/list';
import activeOrdersList from './order/active-orders';
import { paymentList, paymentListCash, paymentListOnline } from './payment/list';
import pickUpPreferenceList from './pickup-preference/list';
import planCreate from './plan/create';
import planDeliveryTimes from './plan/deliveryTimes';
import planDuplicate from './plan/duplicate';
import planEdit from './plan/edit';
import planList from './plan/list';
import planDateMealsList from './plan/meals/list';
import assignMenuToPlan from './plan/menu/assignMenu';
import listPlanZones from './plan/zones/list';
import planShow from './plan/show';
import polygonList from './polygon/list';
import { productTypes } from './product-types/list';
import intercomNotification from './renewal-list/intercom-notification';
import renewalList from './renewal-list/list';
import customerOrdersReport from './reports/customerOrdersReport';
import mealzDriverPickUpReport from './reports/mealzDriverPickUpReport';
import notavailableMealOrderReport from './reports/notAvailableMealOrderReport';
import paymentCollectionReport from './reports/paymentCollectionReport';
import subCategoriesList from './sub-categories/list';
import subscriptionChangeStartDate from './subscription/changeStartDate';
import subscriptionEdit from './subscription/edit';
import subscriptionEditAddress from './subscription/editAddress';
import subscriptionList from './subscription/list';
import myFatoorahCreate from './subscription/myFatoorah/create';
import subscriptionOrderCreate from './subscription/order/create';
import subscriptionOrderEdit from './subscription/order/edit';
import subscriptionShow from './subscription/show';
import { teams } from './team';
import userList from './user/list';
import voucherCreate from './voucher/create';
import voucherEdit from './voucher/edit';
import voucherList from './voucher/list';
import voucherShow from './voucher/show';
import zoneCreate from './zone/create';
import zoneEdit from './zone/edit';
import zoneList from './zone/list';
import zoneShow from './zone/show';
import zoneDiscountCreate from './zoneDiscount/create';
import zoneDiscountEdit from './zoneDiscount/edit';
import zoneDiscountList from './zoneDiscount/list';
import zoneDiscountShow from './zoneDiscount/show';
import planQuickFilters from './plan-quick-filters/list';


const reducer = combineReducers({
  auth,
  subscriptionList,
  subscriptionShow,
  subscriptionEdit,
  subscriptionEditAddress,
  subscriptionOrderCreate,
  subscriptionOrderEdit,
  cancelReaonsList,
  cancelReaonsCreate,
  subscriptionChangeStartDate,
  merchantList,
  merchantMealList,
  clientAddressList,
  paymentListCash,
  paymentListOnline,
  paymentList,
  userList,
  driverListAll,
  cityList,
  renewalList,
  intercomNotification,
  map,
  mapOrders,
  mapDrivers,
  mapFilter,
  driverOrders,
  assignOrder,
  groupAssign,
  customerOrdersReport,
  planList,
  planShow,
  planCreate,
  planEdit,
  planDuplicate,
  planDeliveryTimes,
  planDateMealsList,
  productTypes,
  assignersList,
  categoriesList,
  categoryTypesList,
  actionsList,
  statusList,
  complaintCreate,
  activeOrdersList,
  assignedComplaintsList,
  clientComplaintsList,
  complaintList,
  subCategoriesList,
  complaintShow,
  complaintCommentCreate,
  complaintEdit,
  complaintActionAdd,
  paymentCollectionReport,
  voucherList,
  voucherCreate,
  voucherEdit,
  voucherShow,
  notavailableMealOrderReport,
  mealzDriverPickUpReport,
  actionCreate,
  actionDelete,
  categoryCreate,
  categoryDelete,
  categoryTypeDelete,
  categoryTypeCreate,
  statusCreate,
  statusDelete,
  zoneList,
  zoneCreate,
  zoneShow,
  zoneEdit,
  zoneDiscountList,
  zoneDiscountCreate,
  zoneDiscountShow,
  zoneDiscountEdit,
  polygonList,
  assignMenuToPlan,
  menuList,
  menuEdit,
  menuDuplicate,
  myFatoorahCreate,
  ticketCommentsList,
  clientIntercomNotificationCreate,
  mealList,
  validationModal,
  pickUpPreferenceList,
  companyCreate,
  companyEdit,
  teams,
  groups,
  foodCategory,
  planQuickFilters,
  listPlanZones
});

export default reducer;
