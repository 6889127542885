import moment from 'moment';
import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { axios } from '../../../services/axios';
import DuplicateMenuActionForm from '../components/duplicateAction';

export default class DeleteMealForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showBody: false,
      showLoader: true,
      menu_id: this.props.menu_id,
      date: this.props.date,
      meal_id: this.props.item_id,
    };
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      showLoader: false,
      showBody: true,
    });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  handleSubmit(duplicateActionValue) {
    // event.preventDefault();

    this.setState({
      showLoader: true,
      showBody: false,
    });

    const data = {
      date: this.state.date,
      meal_id: this.state.meal_id,
      duplicate: duplicateActionValue,
    };

    axios.delete(`/menus/${this.state.menu_id}`, data).then((response) => {
      if (response.status === 200) {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false,
        });
        swal.fire({
          title: 'Success',
          type: 'success',
          text: 'Deleted successfully!',
          timer: 2000,
        });
        // refresh
        setTimeout(() => {
          this.props.handleEditSubmit();
        }, 1000);
      } else {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false,
        });
        swal.fire({ title: 'Error', type: 'error', text: 'something went wrong !' });
      }
    });
  }

  render() {
    return (
      <div>
        <Button
          onClick={this.toggle}
          className={
            'm-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle'
          }>
          {this.props.children}
        </Button>
        <Modal isOpen={this.state.modal}>
          <form>
            <ModalHeader>{'Choose Meal'}</ModalHeader>
            <ModalBody>
              <div style={this.state.showLoader ? {} : { display: 'none' }} className={'loader'} />
              <div
                style={this.state.showBody ? {} : { display: 'none' }}
                className={'modal-body-content'}>
                <p>
                  {'Are you sure You want to delete '}
                  {this.props.name}
                  {' from'} {moment(this.props.date).format('dddd')} {this.props.date}
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              {/* eslint-disable-next-line max-len */}
              {/* <input type="submit" value="Submit" color="primary" className="btn btn-primary" /> */}
              <DuplicateMenuActionForm
                actionType={'delete'}
                date={this.props.date}
                handleDuplicateAction={this.handleSubmit}
              />
              <Button color={'danger'} onClick={this.toggle}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}
