import { axios } from '../../../../services/axios';
import * as CONST from '../../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.MYF_CREATE_SUCCESS, myfatoorah: data.myFatoorahURL });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.MYF_CREATE_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const myFatoorahCreate = (subscription_id) => {
  return async (dispatch) => {
    dispatch({ type: CONST.MYF_CREATE_REQUEST });

    axios.post(`/subscriptions/${subscription_id}/add-myf`).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
