import { camelizeKeys } from 'humps';

import { setCurrentUser, setCurrentUserToken } from '../../actions';
import { logout } from '../../actions/auth/logout';

const INITIAL_STATE = {
  currentUser: null,
  token: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case setCurrentUser().type:
      return {
        ...state,
        currentUser: camelizeKeys(action.payload),
      };
    case setCurrentUserToken().type:
      return {
        ...state,
        token: action.payload,
      };
    case logout().type:
      return {
        ...state,
        token: null,
        currentUser: null,
      };
    default:
      return state;
  }
};
