import Moment from 'moment';

const initState = {
  mapFilter: {
    date: Moment(new Date()).format('YYYY-MM-DD'),
    startTime: '00:00',
    endTime: '23:59',
    status: [],
    merchants: [],
    cities: [],
    zones: [],
    plans: [],
    defaultFilter: {},
    addressType: 'BOTH',
  },
};

export default initState;
