import moment from 'moment';
import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { axios } from '../../../services/axios';
import AddMealForm from '../components/add-meal';
import DeleteMealForm from '../delete/delete';
import DuplicateMenu from '../duplicate/duplicate';
import EditModal from '../edit/edit';
import EditMealForm from '../edit/editMeal';

const ItemStock = ({ item }) => {
  const { available_base, daily_meal_id } = item;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newOriginalStock, setNewOriginalStock] = useState(available_base);
  const handleCloseModal = () => {
    setIsModalOpened(!isModalOpened);
  };
  const handelFormSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .patch(`/daily-meal/${daily_meal_id}`, { available_base_stock: newOriginalStock })
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setIsModalOpened(!isModalOpened);
          setTimeout(() => window.location.reload(), 300);
        }
      });
  };
  return (
    <div id={'menu-item-original-stock'} style={{ display: 'block' }}>
      {' '}
      {'Original Stock : '}
      {available_base}
      <Button
        color={'success'}
        onClick={handleCloseModal}
        title={'edit-item-stock'}
        className={
          'm-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle mx-3'
        }>
        <i className={'la la-edit m--font-brand'} />
      </Button>
      <Modal size={'sm'} isOpen={isModalOpened}>
        <form onSubmit={handelFormSubmit}>
          <ModalHeader>{'Edit Stock'}</ModalHeader>
          <ModalBody>
            <div style={isLoading ? {} : { display: 'none' }} className={'loader'} />
            <div style={isLoading ? { display: 'none' } : {}} className={'row'}>
              <Label className={'col-md-5'}>{'Original Stock'}</Label>
              <Input
                className={'col-md-7'}
                id={'menu-item-original-stock-input'}
                type={'number'}
                name={'available_base_stock'}
                value={newOriginalStock}
                onChange={(e) => setNewOriginalStock(e.target.value)}
                required
              />
              {/*<p style={{ color: 'red' }}>{errors}</p>*/}
            </div>
          </ModalBody>
          <ModalFooter>
            <input
              id={'submit-edit-btn'}
              type={'submit'}
              value={'Submit'}
              color={'primary'}
              className={'btn btn-primary'}
            />
            <Button color={'danger'} onClick={handleCloseModal}>
              {'Cancel'}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default class ShowMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: {},
      dailyMeals: [],
      city_id: '',
      merchants: [],
      loading: true,
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    document.title = 'Menu Details';
    this.getData();
  }

  getData() {
    axios.get(`/menus/${this.props.match.params.id}`).then((response) => {
      console.log(response);
      const cityId = response.data.daily_meals[0].meal.merchant.city_id;
      this.setState({
        loading: false,
        menu: response.data,
        dailyMeals: response.data.datesMeals,
        city_id: cityId,
      });

      axios
        .get(`/merchants?city_id=${cityId}`)
        .then((response) => {
          this.setState({
            merchants: response.data.data,
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }

  handleAddMealToDate(data) {
    const body = {
      date: data.date,
      meal_ids: [data.item],
      duplicate: data.duplicate,
    };

    axios.post(`/menus/${data.menu_id}/add-item`, body).then((response) => {
      if (response.status === 200) {
        swal.fire({
          title: 'Success',
          type: 'success',
          text: 'Added successfully!',
          timer: 2000,
        });
        // refresh
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        swal.fire({ title: 'Error', type: 'error', text: response.data.message });
      }
    });
  }

  handleEditSubmit() {
    window.location.reload();
  }

  render() {
    if (this.state.loading) {
      return <div className={'loader'} />;
    } else {
      return (
        <div className={'row'}>
          <div className={'col-md-12'}>
            {/* begin::Portlet*/}
            <div className={'m-portlet m-portlet--tab'}>
              <div className={'m-portlet__head'}>
                <div className={'m-portlet__head-caption'}>
                  <div className={'m-portlet__head-title'}>
                    <span className={'m-portlet__head-icon m--hide'}>
                      <i className={'la la-gear'} />
                    </span>
                    <h3 className={'m-portlet__head-text'}>{'Menu Details'}</h3>
                  </div>
                </div>
                <div className={'m-portlet__head-tools'}>
                  <EditModal
                    menuId={this.state.menu.id}
                    name={this.state.menu.name}
                    zoneId={this.state.menu.zone_id}
                    cityId={this.state.city_id}
                    onMenuEdited={this.getData}
                  />
                  <DuplicateMenu
                    menuId={this.state.menu.id}
                    startDate={this.state.dailyMeals[0].date}
                    zoneId={this.state.menu.zone_id}
                    cityId={this.state.city_id}
                    onMenuDuplicated={this.getData}
                  />
                </div>
              </div>
              <div className={'m-portlet__body'}>
                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>
                    <h4>{'Name'}</h4>
                  </label>
                  <div className={'col-9'}>
                    <label className={'col-form-label'}>{this.state.menu.name}</label>
                  </div>
                </div>

                {this.state.menu.zones && (
                  <div className={'m-form__group row'}>
                    <label className={'col-3 col-form-label'}>
                      <h4>{'Zone'}</h4>
                    </label>
                    <div className={'col-9'}>
                      <label className={'col-form-label'}>
                        {this.state.menu.zones?.map((zone) => zone?.name)?.join(', ')}
                      </label>
                    </div>
                  </div>
                )}
                {this.state.menu.neglected_holiday && (
                  <div className={'m-form__group row'}>
                    <label className={'col-3 col-form-label'}>
                      <h4>{'Allowed Holidays'}</h4>
                    </label>
                    <div className={'col-9'}>
                      {this.state.menu.neglected_holiday.length > 0 ? (
                        this.state.menu.neglected_holiday.map((holiday) => (
                          <span
                            className={'mx-2 badge badge-secondary p-2'}
                            style={{ borderRadius: 5 }}
                            key={holiday.id}>
                            {holiday.description}
                          </span>
                        ))
                      ) : (
                        <label className={'col-form-label'}>{'No allowed holidays'}</label>
                      )}
                    </div>
                  </div>
                )}

                <div className={'m-form__group row'}>
                  <label className={'col-3 col-form-label'}>
                    <h4>{'Dates'}</h4>
                  </label>

                  <div className={'row'}>
                    {this.state.dailyMeals.map((dailyMealz, index) => {
                      return (
                        <div key={index} className={'container'}>
                          <div className={'container'}>
                            <label style={{ display: 'inline-block' }}>
                              <b>
                                {moment(dailyMealz.date).format('dddd')} {dailyMealz.date}
                              </b>
                            </label>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              <AddMealForm
                                pageRequestForm={'show'}
                                date={dailyMealz.date}
                                merchants={this.state.merchants}
                                handleAddMeal={this.handleAddMealToDate}
                                menu_id={this.state.menu.id}>
                                <i className={'la la-plus-circle m--font-brand'} />
                              </AddMealForm>
                            </span>
                          </div>
                          <div className={'table-responsive'}>
                            <table className={'table table-striped m-table'}>
                              <thead>
                                <tr>
                                  <th>{'Name'}</th>
                                  <th>{'Actions'}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dailyMealz.items.map((item, index) => {
                                  return (
                                    <tr id={`menu-item-${item.meal.item_id}`} key={index}>
                                      <td>
                                        <span style={{ display: 'block' }}>
                                          {' '}
                                          {'Merchant :'}{' '}
                                          <Link to={`../merchants/${item.meal.merchant.id}`}>
                                            {' '}
                                            {item.meal.merchant.id}
                                            {' -'} {item.meal.merchant.restaurant_name}{' '}
                                          </Link>
                                        </span>
                                        <span style={{ display: 'block' }}>
                                          {' '}
                                          {'Item :'}{' '}
                                          <Link to={`../meals/${item.meal.item_id}`}>
                                            {' '}
                                            {item.meal.item_id}
                                            {' - '}
                                            {item.meal.ar_name}{' '}
                                          </Link>
                                        </span>
                                        <ItemStock item={item} />
                                        <span
                                          id={'menu-item-available-stock'}
                                          style={{ display: 'block' }}>
                                          {' '}
                                          {'Available Stock : '}
                                          {item.stock}
                                        </span>
                                      </td>
                                      <td className={'actions'}>
                                        <span>
                                          {JSON.parse(localStorage.getItem('user')) &&
                                            JSON.parse(
                                              localStorage.getItem('user'),
                                            ).user_permissions.includes('EDIT_MENUS') && (
                                              <EditMealForm
                                                merchants={this.state.merchants}
                                                menu_id={this.state.menu.id}
                                                date={dailyMealz.date}
                                                old_item_id={item.meal.item_id}
                                                merchant_id={item.meal.merchant_id}
                                                city_id={item.meal.merchant.city_id}
                                                handleEditSubmit={this.handleEditSubmit}>
                                                <i className={'la la-edit m--font-brand'} />
                                              </EditMealForm>
                                            )}
                                        </span>
                                        <span>
                                          {JSON.parse(localStorage.getItem('user')) &&
                                            JSON.parse(
                                              localStorage.getItem('user'),
                                            ).user_permissions.includes('EDIT_MENUS') && (
                                              <DeleteMealForm
                                                menu_id={this.state.menu.id}
                                                date={dailyMealz.date}
                                                item_id={item.meal.item_id}
                                                item_name={item.meal.en_name}
                                                handleEditSubmit={this.handleEditSubmit}>
                                                <i className={'la la-remove m--font-brand'} />
                                              </DeleteMealForm>
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* end::Portlet*/}
          </div>
        </div>
      );
    }
  }
}
