import { axios } from '../../../../services/axios';
import * as CONST from '../../../constants';
import { LIST_PLAN_ZONES_REQUEST } from '../../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.LIST_PLAN_ZONES_SUCCESS, data: data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.LIST_PLAN_ZONES_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const listPlanZones = (planId) => {
  return async (dispatch) => {
    dispatch({ type: CONST.LIST_PLAN_ZONES_REQUEST });
    axios
      .get(`/plans/${planId}/zones`)
      .then((response) => {
        handleResponse(dispatch, response);
      })
      .catch((error) => {
        onFailed(dispatch, error?.response?.data);
      });
  };
};
