import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, data: [], error: null, total: 0, pageCount: 0 };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.VOUCHER_LIST_REQUEST:
      return { ...INITIAL_STATE, loading: true };
    case CONST.VOUCHER_LIST_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        data: action.data.data,
        total: action.data.total,
        pageCount: action.data.last_page,
      };
    case CONST.VOUCHER_LIST_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    default:
      return state;
  }
};
