import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.ZONE_DISCOUNT_SHOW_SUCCESS, data: data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.ZONE_DISCOUNT_SHOW_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const zoneDiscountShow = (id) => {
  return async (dispatch) => {
    dispatch({ type: CONST.ZONE_DISCOUNT_SHOW_REQUEST });

    axios
      .get(`/polygon-discount/${id}`)
      .then((response) => {
        handleResponse(dispatch, response);
      })
      .catch((response) => {
        onFailed(dispatch, response.response.data);
      });
  };
};
