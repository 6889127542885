import * as CONST from '../../../constants';

const INITIAL_STATE = { loading: true, comment: null, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.COMPLAINT_COMMENT_CREATE_REQUEST:
      return { ...INITIAL_STATE, loading: true, comment: null, error: null };
    case CONST.COMPLAINT_COMMENT_CREATE_SUCCESS:
      return { ...INITIAL_STATE, loading: false, comment: action.data, error: null };
    case CONST.COMPLAINT_COMMENT_CREATE_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error, comment: null };
    default:
      return state;
  }
};
