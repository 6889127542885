import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data, type) => {
  if (type == 'CASH') {
    dispatch({ type: CONST.PAYMENT_LIST_CASH_SUCCESS, data });
  } else if (type == 'ALL') {
    dispatch({ type: CONST.PAYMENT_LIST_SUCCESS, data });
  } else {
    dispatch({ type: CONST.PAYMENT_LIST_ONLINE_SUCCESS, data });
  }
};

const onFailed = (dispatch, error, type) => {
  if (type == 'CASH') {
    dispatch({ type: CONST.PAYMENT_LIST_CASH_ERROR, error });
  } else if (type == 'ALL') {
    dispatch({ type: CONST.PAYMENT_LIST_ERROR, error });
  } else {
    dispatch({ type: CONST.PAYMENT_LIST_ONLINE_ERROR, error });
  }
};

const handleResponse = (dispatch, response, type) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data, type);
  } else {
    onFailed(dispatch, response.data, type);
  }
};

export const paymentListCash = (params = {}) => {
  return async (dispatch) => {
    dispatch({ type: CONST.PAYMENT_LIST_CASH_REQUEST });
    params.type = 'CASH';
    axios.get('/payments', { params }).then((response) => {
      handleResponse(dispatch, response, 'CASH');
    });
  };
};

export const paymentListOnline = (params = {}) => {
  return async (dispatch) => {
    dispatch({ type: CONST.PAYMENT_LIST_ONLINE_REQUEST });

    axios.get('/payments', { params }).then((response) => {
      handleResponse(dispatch, response, 'ONLINE');
    });
  };
};
export const paymentList = (params = {}) => {
  return async (dispatch) => {
    dispatch({ type: CONST.PAYMENT_LIST_REQUEST });

    axios.get('/payments/subId', { params }).then((response) => {
      handleResponse(dispatch, response, 'ALL');
    });
  };
};
