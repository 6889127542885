import { camelizeKeys } from 'humps';
import React from 'react';
import { withRouter } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { duplicateImages, uploadImage } from '../../../api/meals/mealMultiImages';
import { MB } from '../../../constants/filesSizes';
import { axios } from '../../../services/axios';
import Alert from '../../../utils/alert';
import MealForm from './form';

import 'react-day-picker/lib/style.css';
class DuplicateMeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meal: {},
      duplicateModal: false,
      duplicateShowLoader: false,
      loaderMessage: '',
      duplicateShowBody: true,
      previousMeal: this.props.meal,
      ar_name: this.props.meal.ar_name,
      en_name: this.props.meal.en_name,
      ar_description: this.props.meal.ar_description,
      en_description: this.props.meal.en_description,
      ar_full_description: this.props.meal.ar_full_description,
      en_full_description: this.props.meal.en_full_description,
      merchant_id: this.props.meal.merchant_id,
      protein: this.props.meal.protein,
      carbs: this.props.meal.carb,
      fats: this.props.meal.fat,
      calories: this.props.meal.calories,
      market_price: this.props.meal.market_price,
      dish: this.props.meal.dish,
      cost: this.props.meal.cost,
      stock: this.props.meal.stock,
      color: this.props.meal.color,
      label_number: this.props.meal.label_number,
      coverImg: this.props.meal.cover_image,
      outsideImg: this.props.meal.photo,
      images: this.props.meal.images || [],
      imagesToBeUploaded: [],
      tags: this.props.meal.tags || [],
      productTypes: this.parseProductTypes(camelizeKeys(this.props.meal.product_types)),
      validation: {
        ar_name: '',
        en_name: '',
        ar_description: '',
        en_description: '',
        ar_full_description: '',
        en_full_description: '',
        merchant_id: '',
        protein: '',
        carbs: '',
        fats: '',
        calories: '',
        dish: '',
        cost: '',
        stock: '',
        color: '',
        label_number: '',
        coverImg: '',
        outsideImg: '',
        market_price: '',
      },
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
    this.uploadMultiImages = this.uploadMultiImages.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.resetToBeModifiedImages = this.resetToBeModifiedImages.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetToBeModifiedImages = this.resetToBeModifiedImages.bind(this);
  }

  toggle() {
    this.setState({
      duplicateModal: !this.state.duplicateModal,
    });
  }

  handleChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    this.setState({ [inputName]: inputValue });
  }

  parseProductTypes(productTypes) {
    return productTypes.map((productType) => ({
      ...productType,
      value: productType.id || productType.value,
      label: productType.enName,
    }));
  }

  handleSelectedProductTypesChange = (productTypes) => this.setState({ productTypes });

  duplicateImages = async () => {
    if (this.state.images.length === 0) return;

    this.setState({ loaderMessage: 'Please wait...' });

    const generatedImagesUrls = await duplicateImages(this.state.images);

    if (generatedImagesUrls?.length > 0) {
      this.setState({ images: generatedImagesUrls, loaderMessage: '' });
    }
  };

  async uploadMultiImages() {
    this.setState({
      loaderMessage: 'uploading Images...',
    });
    let newArr = [...this.state.images];
    newArr = newArr.filter((img) => !(img instanceof File));
    const urls = [];
    for (const image of this.state.imagesToBeUploaded) {
      const res = await uploadImage(image);
      if (res?.data?.image_url) {
        urls.push(res.data.image_url);
      } else {
        Alert.error('Something went wrong');
      }
    }

    this.setState({ images: [...newArr, ...urls], loaderMessage: '' });
  }

  deleteImage(image) {
    this.setState({ loaderMessage: 'deleting images...' });

    if (!(image instanceof File)) {
      const editedImages = this.state.images?.filter((img) => img !== image);
      this.setState({ images: editedImages });
    } else {
      const editedImages = this.state.imagesToBeUploaded?.filter((img) => img !== image);
      this.setState({ imagesToBeUploaded: editedImages });
    }
  }

  handleUploadPhoto(e) {
    const inputName = e.target.name;
    const isMultiPicuter = inputName === 'images';
    const files = Array.from(e.target.files);
    const isMaxSizeExceeded = files.some((img) => img.size > MB);
    if (isMultiPicuter && files.length + this.state.images.length > 4) {
      Alert.error('Maximum number of pictures is 4.');
    } else if (isMultiPicuter && isMaxSizeExceeded) {
      Alert.error('please make sure that every image dose not exceed 1MB');
    } else {
      isMultiPicuter
        ? this.setState({ imagesToBeUploaded: [...this.state.imagesToBeUploaded, ...files] })
        : this.setState({ [inputName]: files[0] });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({
      duplicateShowLoader: true,
      duplicateShowBody: false,
    });

    await this.duplicateImages();
    await this.uploadMultiImages();

    const data = new FormData();
    data.append('ar_name', this.state.ar_name);
    data.append('en_name', this.state.en_name);
    data.append('ar_description', this.state.ar_description);
    data.append('en_description', this.state.en_description);
    data.append('ar_full_description', this.state.ar_full_description);
    data.append('en_full_description', this.state.en_full_description);
    data.append('merchant_id', this.state.merchant_id);
    data.append('protein', Number(this.state.protein));
    data.append('carb', Number(this.state.carbs));
    data.append('market_price', this.state.market_price);
    data.append('fat', Number(this.state.fats));
    data.append('calories', Number(this.state.calories));
    data.append('dish', this.state.dish);
    data.append('cost', this.state.cost);
    data.append('color', this.state.color);
    if (this.state.label_number) {
      data.append('label_number', Number(this.state.label_number));
    }
    data.append('coverImg', this.state.coverImg);
    data.append('outsideImg', this.state.outsideImg);

    // check if stock is send and add it to request body
    if (this.state.stock) {
      data.append('stock', Number(this.state.stock));
    }

    if (this.state.images) {
      this.state.images.forEach((image, index) => data.append(`images[${index}]`, image));
    }

    if (this.state.tags?.length > 0) {
      const tagIds = this.state.tags?.map((tag) => tag.id);
      tagIds.forEach((id, index) => data.append(`tags[${index}]`, id));
    }

    if (this.state.productTypes?.length > 0) {
      const productTypesIds = this.state.productTypes?.map((productType) => productType.value);
      productTypesIds.forEach((id, index) => data.append(`product_types[${index}]`, id));
    }

    axios.post('/meals', data).then((response) => {
      if (response.status >= 400) {
        this.setState({
          duplicateShowLoader: false,
          duplicateShowBody: true,
          validation: {
            ar_name: response.data.error.ar_name ? response.data.error.ar_name : '',
            en_name: response.data.error.en_name ? response.data.error.en_name : '',
            ar_description: response.data.error.ar_description
              ? response.data.error.ar_description
              : '',
            en_description: response.data.error.en_description
              ? response.data.error.en_description
              : '',
            ar_full_description: response.data.error.ar_full_description
              ? response.data.error.ar_full_description
              : '',
            en_full_description: response.data.error.en_full_description
              ? response.data.error.en_full_description
              : '',
            merchant_id: response.data.error.merchant_id ? response.data.error.merchant_id : '',
            market_price: response.data.error.market_price ? response.data.error.market_price : '',
            protein: response.data.error.protein ? response.data.error.protein : '',
            carbs: response.data.error.carb ? response.data.error.carb : '',
            fats: response.data.error.fat ? response.data.error.fat : '',
            calories: response.data.error.calories ? response.data.error.calories : '',
            dish: response.data.error.dish ? response.data.error.dish : '',
            cost: response.data.error.cost ? response.data.error.cost : '',
            stock: response.data.error.stock ? response.data.error.stock : '',
            color: response.data.error.color ? response.data.error.color : '',
            label_number: response.data.error.label_number ? response.data.error.label_number : '',
            coverImg: response.data.error.cover_img ? response.data.error.cover_img : '',
            outsideImg: response.data.error.outside_img ? response.data.error.outside_img : '',
          },
        });
        swal.fire({ title: 'Error', type: 'error', text: 'something went wrong !' });
      } else {
        swal.fire({
          title: 'Success',
          type: 'success',
          text: 'Meal Duplicated successfully!',
          timer: 1000,
        });
        this.props.handleDuplicated(response.data);
        this.setState({
          duplicateShowLoader: false,
          duplicateShowBody: true,
          duplicateModal: false,
        });
        this.props.history.push(`${response.data.meal.item_id}`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }

  resetToBeModifiedImages() {
    this.setState({
      images: this.props.meal.images || [],
      imagesToBeUploaded: [],
      duplicateModal: !this.state.duplicateModal,
    });
  }

  handleSelectedTagsChange = (tags) => this.setState({ tags });

  render() {
    return (
      <div>
        <Button
          color={'success'}
          onClick={this.toggle}
          className={'btn m-btn--pill btn-success m-btn m-btn--custom'}>
          {'Duplicate Meal'}
        </Button>
        <Modal
          size={'lg'}
          isOpen={this.state.duplicateModal}
          toggle={this.toggle}
          onClosed={() => this.handleSelectedTagsChange(this.props.meal?.tags)}>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={this.toggle}>{'Duplicate Meal'}</ModalHeader>

            <ModalBody>
              <>
                <h4 style={{ textAlign: 'center' }}> {this.state.loaderMessage}</h4>
                <div
                  style={this.state.duplicateShowLoader ? {} : { display: 'none' }}
                  className={'loader'}
                />
              </>
              <div
                style={this.state.duplicateShowBody ? {} : { display: 'none' }}
                className={'modal-body-content'}>
                <MealForm
                  formType={'duplicate'}
                  merchants={this.props.merchants}
                  allProductTypes={this.props.allProductTypes}
                  values={{
                    ...this.state,
                    images: [...this.state.images, ...this.state.imagesToBeUploaded],
                  }}
                  handleChange={this.handleChange}
                  handleUploadPhoto={this.handleUploadPhoto}
                  handleSelectedProductTypesChange={this.handleSelectedProductTypesChange}
                  parseProductTypes={this.parseProductTypes}
                  uploadMultiImages={this.uploadMultiImages}
                  deleteImage={this.deleteImage}
                  handleSelectedTagsChange={this.handleSelectedTagsChange}
                />
              </div>
            </ModalBody>
            <ModalFooter style={this.state.duplicateShowBody ? {} : { display: 'none' }}>
              <input
                type={'submit'}
                value={'Submit'}
                color={'primary'}
                className={'btn btn-primary'}
              />
              <Button
                color={'danger'}
                onClick={() => {
                  this.resetToBeModifiedImages();
                }}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default withRouter(DuplicateMeal);
