import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Creatable } from 'react-select';
import { FormText } from 'reactstrap';

import { createTag } from '../api/tags/tags.create';
import { fetchTags } from '../api/tags/tags.read';

const generateTagsOptionList = (tags) => {
  return tags.map((tag) => ({ value: tag.id, label: tag.name, ...tag }));
};

export const CreatableTagsMultiSelect = memo(
  ({ inputPlaceholder, hintText, tagType, handleSelectedTagsChange, defaultTags }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState([]);
    const [isFetchingTags, setIsFetchingTags] = useState(true);
    const didMount = useRef(false);

    // Fetch tag list and generate tag options
    useEffect(() => {
      fetchTags(tagType)
        .then((res) => {
          const tags = res.data.tags;
          const tagsOptions = generateTagsOptionList(tags);

          setOptions(tagsOptions);
        })
        .finally(() => setIsFetchingTags(false));
    }, [tagType]);

    // Set default tags if existing
    useEffect(() => {
      if (didMount.current === false) {
        const tagsOptions = generateTagsOptionList(defaultTags);

        setValue(tagsOptions);
        didMount.current = true;
      }
    }, [defaultTags]);

    const handleCreateTagOptionAndSelectIt = (inputValue) => {
      const tagName = inputValue;
      setIsLoading(true);

      createTag({ name: tagName, type: tagType }).then((res) => {
        const createdTag = res.data.tag;
        const newTagOption = { label: tagName, value: createdTag.id, ...createdTag };
        const prevValue = value;
        const newValue = [...prevValue, newTagOption];

        setValue(newValue);
        handleSelectedTagsChange(newValue);
        setIsLoading(false);
      });
    };

    const handleChange = (newValue) => {
      setValue(newValue);
      handleSelectedTagsChange(newValue);
    };

    return (
      <>
        <Creatable
          isMulti
          isClearable
          isDisabled={isLoading || isFetchingTags}
          isLoading={isLoading || isFetchingTags}
          onChange={handleChange}
          onCreateOption={handleCreateTagOptionAndSelectIt}
          options={options}
          value={value}
          placeholder={inputPlaceholder}
        />
        <FormText color={'muted'}>{hintText}</FormText>
      </>
    );
  },
);

CreatableTagsMultiSelect.displayName = 'CreatableTagsMultiSelect';

CreatableTagsMultiSelect.propTypes = {
  inputPlaceholder: PropTypes.string,
  hintText: PropTypes.string,
  tagType: PropTypes.string.isRequired,
  handleSelectedTagsChange: PropTypes.func,
  defaultTags: PropTypes.array,
};

CreatableTagsMultiSelect.defaultProps = {
  inputPlaceholder: 'Select an existing tag or create new one',
  hintText: 'You can select multiple tags or create new ones.',
  handleSelectedTagsChange: () => null,
  defaultTags: [],
};
