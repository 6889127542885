import { resetClientIds } from '../../../actions';
import { MAP_ASSIGN_ORDERS } from '../../../constants';

const INITIAL_STATE = { clientsIds: [], ordersIds: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAP_ASSIGN_ORDERS:
      return {
        ...state,
        clientsIds: action.clientsIds,
        ordersIds: action.ordersIds,
      };
    case resetClientIds().type:
      return {
        ...state,
        clientsIds: [],
        ordersIds: [],
      };
    default:
      return state;
  }
};
