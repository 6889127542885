import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, data: {}, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.SUBSCRIPTIONS_SHOW_REQUEST:
      return { ...INITIAL_STATE, loading: true };
    case CONST.SUBSCRIPTIONS_SHOW_SUCCESS:
      return { ...INITIAL_STATE, loading: false, data: action.data };
    case CONST.SUBSCRIPTIONS_SHOW_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    default:
      return state;
  }
};
