import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.VOUCHER_CREATE_SUCCESS, voucher: data.voucher });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.VOUCHER_CREATE_ERROR, error });
};

export const voucherCreate = (data) => {
  return async (dispatch) => {
    dispatch({ type: CONST.VOUCHER_CREATE_REQUEST });

    axios
      .post('/vouchers', data)
      .then((response) => {
        onSuccess(dispatch, response.data);
      })
      .catch((error) => {
        onFailed(dispatch, error);
      });
  };
};
