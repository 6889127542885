import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import React, { useState } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import swal from 'sweetalert2';

import { axios } from '../../../services/axios';

const EditMerchantZohoButton = ({ merchant }) => {
  const [modal, setModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { dirty },
    errors,
    setError,
  } = useForm({
    defaultValues: {
      zoho_id: merchant?.zoho_id,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleModal = () => setModal(!modal);

  const onCancel = () => {
    toggleModal();
  };

  const onClickSave = (data) => {
    console.log(data);
    data.username = merchant?.username;
    setIsSubmitting(true);
    axios
      .put(`/merchants/${merchant?.id}`, data)
      .then(() => {
        setModal(false);
        swal
          .fire({
            title: 'Success',
            type: 'success',
            text: 'Merchant Zoho configuration has been updated successfully!',
            timer: 2000,
          })
          .then(() => {
            window.location.reload();
          });
      })
      .catch((e) => {
        setError([
          {
            name: 'zoho_id',
            type: 'validate',
            message: e.response.data?.errors?.zoho_id
              ? e.response.data.errors.zoho_id
              : e.response.data?.message,
          },
        ]);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <button
        onClick={toggleModal}
        className={
          'btn btn-lg btn-secondary m-btn m-btn--icon m-btn--icon-only m-btn--pill btn-success'
        }>
        <AccountBalanceIcon color={'secondary'} />
      </button>

      {modal ? (
        <Modal size={'lg'} isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{'Edit Zoho Configuration'}</ModalHeader>
          <ModalBody>
            <div className={'modal-body-content'}>
              <div>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <FormGroup>
                      <Label for={'backdrop'}>{'Zoho Id'}</Label>
                      <Input
                        type={'number'}
                        innerRef={register({ required: 'Zoho Id is required' })}
                        maxLength={20}
                        name={'zoho_id'}
                        autoComplete='off'
                        defaultValue={merchant?.zoho_id}
                      />
                      <p className={'invalid-feedback'}>
                        <ErrorMessage errors={errors} name={'zoho_id'} />
                      </p>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              id={'meal-edit-submit-btn'}
              disabled={isSubmitting || !dirty}
              color={'primary'}
              onClick={handleSubmit(onClickSave)}>
              {'Submit'}
            </Button>
            <Button color={'danger'} onClick={onCancel}>
              {'Cancel'}
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </div>
  );
};

export default EditMerchantZohoButton;
