import React, { useState } from 'react';
import { Button } from 'reactstrap';

import EditMealAttributesModal from './EditMealAttributesModal';

const EditMealAttributesButton = ({ meal }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  return (
    <div>
      <Button
        color={'success'}
        outline
        id={'meal-edit-attributes-button'}
        onClick={toggleModal}
        className={'btn m-btn--pill m-btn m-btn--custom'}>
        {'Edit Attributes'}
      </Button>

      {modal && <EditMealAttributesModal meal={meal} modal={modal} toggleModal={toggleModal} />}
    </div>
  );
};

export default EditMealAttributesButton;
