import moment from 'moment';
import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class DuplicateMenuActionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicateActionModal: false,
      duplicateActionModalShowBody: false,
      duplicateActionModalShowLoader: true,
    };
    this.duplicateToggle = this.duplicateToggle.bind(this);
    this.handleDuplicateSubmit = this.handleDuplicateSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      duplicateActionModalShowLoader: false,
      duplicateActionModalShowBody: true,
    });
  }

  duplicateToggle() {
    this.setState({ duplicateActionModal: !this.state.duplicateActionModal });
  }

  handleDuplicateSubmit(event) {
    event.preventDefault();

    const value = event.target.getAttribute('data-btn_value');

    this.props.handleDuplicateAction(value);

    this.setState({
      duplicateActionModalShowLoader: false,
      duplicateActionModalShowBody: false,
      duplicateActionModal: false,
    });
  }

  render() {
    return (
      <div>
        <Button onClick={this.duplicateToggle} color={'primary'} className={'btn btn-primary'}>
          {'OK'}
        </Button>
        <Modal isOpen={this.state.duplicateActionModal}>
          <form onSubmit={this.handleDuplicateSubmit}>
            <ModalHeader>
              {'Duplicate '}
              {this.props.actionType}
              {' Action'}
            </ModalHeader>
            <ModalBody>
              <div
                style={this.state.duplicateActionModalShowLoader ? {} : { display: 'none' }}
                className={'loader'}
              />
              <div
                style={this.state.duplicateActionModalShowBody ? {} : { display: 'none' }}
                className={'modal-body-content'}>
                <p>
                  {'Are you want to implement '}
                  {this.props.actionType}
                  {' action from all'} {moment(this.props.date).format('dddd')} {'days'}
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color={'primary'}
                className={'btn btn-primary'}
                data-btn_value={1}
                onClick={this.handleDuplicateSubmit}>
                {'Yes'}
              </Button>
              <Button
                color={'danger'}
                className={'btn btn-danger'}
                data-btn_value={0}
                onClick={this.handleDuplicateSubmit}>
                {'No'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}
