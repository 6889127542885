import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, data: [], error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.PLAN_QUICK_FILTERS_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };
    case CONST.PLAN_QUICK_FILTERS_LIST_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    case CONST.PLAN_QUICK_FILTERS_CREATE_REQUEST:
      return {
        ...state,
        data: [...state.data, action.data],
      };
    case CONST.PLAN_QUICK_FILTERS_UPDATE_REQUEST:
      const updatedData = state.data.map((filter) =>
        filter.id === action.data.id ? action.data : filter,
      );
      return {
        ...state,
        data: updatedData,
      };

    default:
      return state;
  }
};
