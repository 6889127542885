import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import WebsocketWorker from 'workerize-loader?inline!../../../websocket.worker.js'; // eslint-disable-line import/no-webpack-loader-syntax

import { getDrivers } from '../../../services/driver/getDrivers';
import * as CONST from '../../constants';

const websocketWorker = new WebsocketWorker();
let drivers = [];

const onSuccess = (dispatch, data) => {
  drivers = data;
  dispatch({ type: CONST.MAP_DRIVERS_LIST_SUCCESS, data: data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.MAP_DRIVERS_LIST_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const driversList = (params) => {
  return async (dispatch) => {
    dispatch(initiateSocket)
    dispatch({ type: CONST.MAP_DRIVERS_LIST_REQUEST });

    getDrivers(params).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};

export const updateDrivers = (orders, driver, status) => {
  return async (dispatch) => {
    let priority = 0;
    const updatedOrders = [];

    // ***** START UPDATED ASSIGNED TASKS IN LOCAL STATE
    orders.map(function(order) {
      if (orders.find((tempOrder) => tempOrder.id === order.id)) {
        // START UPDATE TASK OBJECT
        order.status = status;
        order.priority = priority;
        if (driver && driver.driver_id) {
          order.driver = {
            id: driver.driver_id,
            name: driver.first_name,
            phone: driver.phone,
          };
        } else {
          order.driver = null;
        }
        // END UPDATE TASK OBJECT

        updatedOrders.push(order);

        // toastr.options = { "positionClass" : "toast-bottom-right" }
        // toastr.info("task "+order.id+" has updated");
        priority++;
      }
      return order;
    });
    // ***** END UPDATED ASSIGNED TASKS IN LOCAL STATE

    dispatch({ type: CONST.MAP_DRIVERS_LIST_UPDATE, data: drivers, updatedData: updatedOrders });
  };
};

export const setSelectedDrivers = (selectedDrivers) => {
  return async (dispatch) => {
    dispatch({ type: CONST.MAP_DRIVERS_SELECTED, selectedDrivers });
  };
};

export const setSelectedDriversOrders = (selectedOrders) => {
  return async (dispatch) => {
    dispatch({ type: CONST.MAP_DRIVERS_ORDERS_SELECTED, selectedOrders });
  };
};

export const updateDriverStatus = (data) => {
  return async (dispatch) => {
    let updatedDriver = null;
    drivers = drivers.map((driver) => {
      if (parseInt(driver.driver_id) === parseInt(data.driverId)) {
        driver.online = data.online;
        updatedDriver = Object.assign({}, driver);
      }
      return driver;
    });

    dispatch({ type: CONST.MAP_DRIVERS_LIST_UPDATE, data: drivers, updatedData: updatedDriver });
  };
};

export const updateDriverLocation = (data, drivers) => {
  return async (dispatch, getState) => {
    // if(getState().mapDrivers.driversIds.indexOf(data.driverId) > -1)
    // {
    let updatedDriver = null;
    drivers = drivers.map((driver) => {
      if (Number(driver.id) === Number(data.driverId)) {
        driver.location_lat = data.location.lat;
        driver.location_lng = data.location.lng;
        driver.last_online = data.lastOnline;
        driver.online = true;
        updatedDriver = Object.assign({}, driver);
      }
      return driver;
    });
    dispatch({ type: CONST.MAP_DRIVERS_LIST_UPDATE, data: drivers, updatedData: updatedDriver });
    // }
  };
};

export const initiateSocket =  () => (dispatch, getState) => {
  const { auth: { token, currentUser } } = getState();
  websocketWorker.initiate(token, currentUser.adminId);

  // CONNECT SOCKET THROUGH WEB WORKER
  websocketWorker.postMessage({ event: 'connect' });

  // RECEIVE EVENTS/MESSAGES FROM WEB WORKER
  websocketWorker.onmessage = (e) => {
    const data = e.data.data;
    const event = e.data.event;
    // console.log('event: ', event, data?.driverId)

    if (event === 'connected') console.log('CONNECTED SUCCESSFULY');

    if (event === 'disconnected') console.log('DISCONNECTED');

    // UPDATE DRIVER LOCATION
    if (event === 'updateDriverLocation') {
      let out = [];
      if (isArray(drivers)) {
        out = drivers;
      } else if (isObject(drivers)) {
        out = drivers.data;
      }
      if (!isEmpty(out)) {
        dispatch(updateDriverLocation(data, out));
      }
    }

    // UPDATE DRIVER STATUS
    if (event === 'updateDriverStatus') dispatch(updateDriverStatus(data));

    // UPDATE DRIVER STATUS
    // if(event === 'driverReceivedOrders')
    // {
    //     this.state.merchants.map(merchant => {
    //         if (merchant.id == data["merchant_id"]) {
    //             toastr.options.timeOut = 30000;
    //             toastr.options = { positionClass: "toast-bottom-right" };
    //             toastr.success(
    //                 "",
    //                 ` Driver with driver ID: ${
    //                     data["driver_id"]
    //                 } has received the mealz from Restaurant: ${
    //                 merchant["restaurant_name"]
    //                 } with ID: ${data["merchant_id"]} `
    //             );
    //         }
    //     });
    // }
  };
};
