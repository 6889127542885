import { camelizeKeys, decamelizeKeys } from 'humps';

import { axios } from '../../services/axios';
import Alert from '../../utils/alert';

export const listAllAttributesOptions = () => {
  return axios
    .get('/meal-attributes')
    .then((res) => camelizeKeys(res?.data?.data))
    .catch(() => Alert.error('Something went wrong'));
};

export const fetchMealAttributes = (mealId) => {
  return axios
    .get(`/meals/${mealId}/attributes`)
    .then((res) => camelizeKeys(res?.data?.data))
    .catch(() => Alert.error('Something went wrong'));
};

export const submitAttributes = (mealId, mealAttributes) => {
  return axios
    .post(`/meals/${mealId}/attributes`, decamelizeKeys(mealAttributes))
    .then((res) => res?.data)
    .catch(() => Alert.error('Something went wrong'));
};
