import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, data: [], pagination: {}, error: null };

export const driverListAll = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.DRIVER_LIST_ALL_REQUEST:
      return { ...state, loading: true, data: [], error: null };
    case CONST.DRIVER_LIST_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.data,
        pagination: action.data.pagination,
      };
    case CONST.DRIVER_LIST_ALL_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
