import moment from 'moment';
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { connect } from 'react-redux';
import Select from 'react-select';
import swal from 'sweetalert2';

import { axios } from '../../../services/axios';
import { cityList, zoneList } from '../../../store/actions';
import AddDateForm from '../components/add-date';

import 'react-day-picker/lib/style.css';
import { AMAZON_CLOUD_FRONT_URL } from '../../../constants/meals';

class CreateMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuDays: 0,
      menuName: '',
      startDate: '',
      endDate: '',
      holidays: [],
      ignored_holidays: [],
      offDays: [],
      dates: [],
      cities: [],
      merchants: [],
      data: [],
      datesDivs: [],
      city_id: 0,
      zone_id: 0,
      date_columns_width: 3,
      admin_has_city: false,
      validation: {
        zone: '',
      },
      allow_fridays: false,
      allow_saturdays: false,
      noOfWeekDays: 5,
    };
    this.listOffDays = this.listOffDays.bind(this);
    this.handleChangeDay = this.handleChangeDay.bind(this);
    this.handleMenuNameChange = this.handleMenuNameChange.bind(this);
    this.handleAddMealToDate = this.handleAddMealToDate.bind(this);
    this.handleCreateMenu = this.handleCreateMenu.bind(this);
    this.handelDuplicateWeek = this.handelDuplicateWeek.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeZone = this.handleChangeZone.bind(this);
    this.listCityMerchants = this.listCityMerchants.bind(this);
    this.drawDatesInDOM = this.drawDatesInDOM.bind(this);
    this.handelCheckBoxChange = this.handelCheckBoxChange.bind(this);
    this.handelWhenHolidaySelectedToNeglect = this.handelWhenHolidaySelectedToNeglect.bind(this);
  }

  componentDidMount() {
    document.title = 'Create Menu';
    /**
     * check if the logged admin has permission to view specific city or not
     * if it has will not display filter by city option
     */
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    if (loggedUser.city_id !== null) {
      this.setState(() => ({
        city_id: loggedUser.city_id,
        admin_has_city: true,
        date_columns_width: 6,
      }));

      // list merchant depend on admin's city
      this.listCityMerchants(loggedUser.city_id);
    } else {
      this.props.cityList({});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.menuDays !== this.state.menuDays ||
      prevState.allow_fridays !== this.state.allow_fridays ||
      prevState.allow_saturdays !== this.state.allow_saturdays ||
      prevState.noOfWeekDays !== this.state.noOfWeekDays ||
      prevState.zone_id !== this.state.zone_id ||
      prevState.cityId !== this.state.cityId ||
      prevState.offDays !== this.state.offDays
    ) {
      this.listOffDays({ city: this.state.city_id });
      if (this.state.zone_id > 0 && this.state.menuDays > 0) {
        this.drawDatesInDOM(this.state.startDate);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cities) {
      this.setState({
        cities: nextProps.cities,
      });
    }
  }

  handelCheckBoxChange(event) {
    const name = event.target.name;
    const checked = event.target.checked;
    const currentNoOfWeekDays = Number(this.state.noOfWeekDays);
    this.setState({
      [name]: checked,
      noOfWeekDays: checked ? currentNoOfWeekDays + 1 : currentNoOfWeekDays - 1,
    });
  }

  handleChangeCity(event) {
    const cityId = event.target.value;
    this.listCityMerchants(cityId);
    this.props.zoneList({ city_id: cityId, type: 'all' });
    this.setState({ city_id: cityId }, () => {
      this.listOffDays({ city: cityId });
    });
  }

  handleChangeZone(event) {
    const zoneId = event.target.value;
    this.setState({
      zone_id: zoneId,
      validation: {
        zone: '',
      },
    });
  }

  listCityMerchants(cityId) {
    axios
      .get(`/merchants?city_id=${cityId}`)
      .then((response) => {
        this.setState({
          merchants: response.data.data,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  listOffDays(params) {
    axios
      .get('/off-days', { params })
      .then((response) => {
        const range = [];
        response.data.data.forEach((holiday) => {
          const current = new Date(holiday.start_date);
          while (current <= new Date(holiday.end_date)) {
            range.push(new Date(current));
            current.setDate(current.getDate() + 1);
          }
        });
        this.setState({
          holidays: response.data.data,
          offDays: range,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handelWhenHolidaySelectedToNeglect(newOption) {
    const range = [];
    this.state.holidays.forEach((holiday) => {
      if (
        newOption.length === 0 ||
        newOption.some((option) => option.label !== holiday.description)
      ) {
        const current = new Date(holiday.start_date);
        while (current <= new Date(holiday.end_date)) {
          range.push(new Date(current));
          current.setDate(current.getDate() + 1);
        }
      }
    });
    this.setState({
      ignored_holidays: newOption.map((option) => option.value),
      offDays: range,
    });
  }

  handleChangeDay(selectedDay, modifiers, dayPickerInput) {
    let choosedDate = selectedDay === undefined ? '' : selectedDay.toISOString().substring(0, 10);
    choosedDate = new Date(choosedDate);
    this.setState(
      () => {
        return { [dayPickerInput.props.name]: choosedDate };
      },
      () => {
        if (this.state.startDate && this.state.endDate) {
          const startDate = moment(this.state.startDate);
          const endDate = moment(this.state.endDate);
          const menuDays = endDate.diff(startDate, 'days') + 1;
          this.setState({
            menuDays,
          });
        } else if (!this.state.zone_id) {
          this.setState({
            validation: {
              zone: 'please choose zone',
            },
          });
        }
      },
    );
  }

  drawDatesInDOM(startDate) {
    // get available dates for three months after start dates
    const availableDates = [];
    let choosedDate = startDate;
    const allowFridays = this.state.allow_fridays;
    const allowSaturdays = this.state.allow_saturdays;

    while (availableDates.length < this.state.menuDays) {
      let isOff = false;
      this.state.offDays.forEach((date) => {
        if (moment(date).format('YYYY-MM-DD') === moment(choosedDate).format('YYYY-MM-DD')) {
          isOff = true;
          return;
        }
      });
      const isFriday = moment(choosedDate).day() !== 5;
      const isSaturdays = moment(choosedDate).day() !== 6;
      if (!isOff && (allowFridays || isFriday) && (allowSaturdays || isSaturdays)) {
        availableDates.push(moment(choosedDate).format('YYYY-MM-DD'));
      }
      choosedDate = moment(choosedDate).add(1, 'days');
    }
    this.setState({
      dates: availableDates,
      data: [],
    });
  }

  handleMenuNameChange(e) {
    this.setState({
      menuName: e.target.value,
    });
  }

  handleAddMealToDate(itemData) {
    // check date is exist
    const oldDate = this.state.data.slice();
    let dateFound = false;
    let updateDateItemFalg = false;
    let itemExistInDate = false;
    for (let i = 0; i < oldDate.length; i++) {
      if (Date.parse(oldDate[i].date) === Date.parse(itemData.date)) {
        dateFound = true;
        // check item exist in item array
        itemExistInDate = this.checkItemExistInItemsArray(itemData.item, oldDate[i].items);
        // check if index exist and need to be update
        updateDateItemFalg = this.checkIndexExistInItemsArray(itemData.itemIndex, oldDate[i].items);
        // update item if index exist and item not exist
        if (!itemExistInDate && updateDateItemFalg !== -1) {
          const currentItemItems = oldDate[i].items;
          currentItemItems[itemData.itemIndex] = itemData.item;
        } else if (!itemExistInDate && updateDateItemFalg === -1) {
          // push item if not exist
          oldDate[i].items.push(itemData.item);
        }
        break;
      }
    }
    if (!dateFound) {
      const newDateDate = {
        date: itemData.date,
        items: [itemData.item],
      };
      // add new date to data
      this.setState({
        data: this.state.data.concat(newDateDate),
      });
    } else {
      // append new item to exist date
      this.setState({
        data: oldDate,
      });
    }
    // append image to div if item not exist in date
    if (!itemExistInDate) {
      const divId = `${itemData.date}#${itemData.itemIndex}`;
      if (updateDateItemFalg !== -1) {
        this.appendMealImage(divId, itemData.meal, 'update');
      } else {
        this.appendMealImage(divId, itemData.meal, 'create');
      }
      swal.fire({
        title: 'Success',
        type: 'success',
        text: 'Meal added to date successfully!',
        timer: 2500,
      });
    } else {
      swal.fire({
        title: 'Information',
        type: 'info',
        text: 'This Meal already exist in thet date!',
        timer: 3500,
      });
    }
  }

  checkItemExistInItemsArray(itemId, itemsArray) {
    let itemExist = false;
    // check if item already exist items array
    itemsArray.find((item) => {
      if (item === itemId) {
        itemExist = true;
        return true;
      } else {
        itemExist = false;
      }
    });

    return itemExist;
  }

  checkIndexExistInItemsArray(itemIndex, itemsArray) {
    let indexExist = -1;
    // check if item already exist items array
    itemsArray.find((item, index) => {
      if (index === itemIndex) {
        indexExist = index;
        return true;
      }
    });

    return indexExist;
  }

  appendMealImage(divId, meal, type) {
    const mealDiv = document.getElementById(divId);
    const firstDiv = mealDiv.getElementsByTagName('div')[0];
    // append meal image
    const imgSrc = `${AMAZON_CLOUD_FRONT_URL}/meals/${meal.cover_image}`;
    firstDiv.innerHTML = `<img class='m--img-rounded m--marginless' title='${meal.ar_name}' width='75' height='75' src='${imgSrc}'/>`;

    if (type !== 'update') {
      const secondDiv = mealDiv.getElementsByTagName('div')[2];
      const iTag = secondDiv.querySelector('.la-plus-circle');
      // remove plus class and add edit class on i tag
      iTag.classList.remove('la-plus-circle');
      iTag.classList.add('la-edit');
    }
  }

  handelDuplicateWeek(event) {
    if (event.target.value !== 'all') {
      const currentWeekFirstIndex = event.target[event.target.selectedIndex].getAttribute(
        'data-currentweek',
      );
      const choosedWeekFirstInput = event.target.value;
      // get dates for week will be copied to be able to read data from data.items
      const datesWillCopied = this.getWeekDates(choosedWeekFirstInput);
      // get dates for week need to fill its days
      const datesWillFilled = this.getWeekDates(currentWeekFirstIndex);
      //check if week will copied already contain data && copy data
      let weekDataExist = true;
      const copiedData = [];
      const copiedImages = [];
      // check if data not empty and check if copied week contain same number of days or greater than week will be filled.
      if (this.state.data.length > 0 && datesWillCopied.length >= datesWillFilled.length) {
        datesWillCopied.forEach((date, index) => {
          this.state.data.find((dataItem) => {
            if (copiedData.length < datesWillFilled.length) {
              // copy data
              if (Date.parse(dataItem.date) === Date.parse(date)) {
                weekDataExist = true;
                const newDateData = {
                  date: datesWillFilled[index],
                  items: dataItem.items,
                };
                copiedData.push(newDateData);
                // save images to copy it
                dataItem.items.forEach((item, itemIndex) => {
                  copiedImages.push([
                    `${date}#${itemIndex}`,
                    `${datesWillFilled[index]}#${itemIndex}`,
                  ]);
                });
                return true;
              } else {
                weekDataExist = false;
              }
            }
          });
        });
      } else {
        weekDataExist = false;
      }

      // append new week to data array if all week days has data
      if (weekDataExist) {
        // check if week has filled dates and removed it
        const stateData = this.state.data.filter((obj) => {
          let unremovedData = true;
          datesWillFilled.find((date) => {
            if (Date.parse(obj.date) === Date.parse(date)) {
              unremovedData = false;
              return true;
            } else {
              unremovedData = true;
            }
          });
          if (unremovedData) {
            return true;
          } else {
          }
        });

        // remove data then append it
        this.setState(
          {
            data: stateData,
          },
          () => {
            // copy data from week to week
            this.setState({
              data: this.state.data.concat(copiedData),
            });
            // copy images
            copiedImages.forEach((image) => {
              this.duplicateImage(image[0], image[1]);
            });
            swal.fire({
              title: 'Success',
              type: 'success',
              text: 'Data copied successfully!',
              timer: 2000,
            });
          },
        );
      } else {
        swal.fire({
          title: 'Error',
          type: 'error',
          text: 'You must choose meals for every date for selected week',
          timer: 3000,
        });
      }
    }
  }

  getWeekDates(weekStartIndex) {
    const checkLastWeek = this.state.menuDays % this.state.noOfWeekDays;
    const lastWeekNumber =
      checkLastWeek === 0
        ? parseInt(this.state.menuDays / this.state.noOfWeekDays, 10)
        : parseInt(this.state.menuDays / this.state.noOfWeekDays, 10) + 1;
    const lastWeekFirstIndex = (lastWeekNumber - 1) * this.state.noOfWeekDays;
    let weekDaysNumbers = this.state.noOfWeekDays;
    const weekDays = [];

    // check if menu number od days is not divided by 5 so tha last week has less than 5 days
    if (checkLastWeek !== 0 && Number(lastWeekFirstIndex) === Number(weekStartIndex)) {
      weekDaysNumbers = checkLastWeek;
    }

    for (let index = 0; index < weekDaysNumbers; index++) {
      weekDays.push(this.state.dates[parseInt(weekStartIndex, 10) + index]);
    }

    return weekDays;
  }

  duplicateImage(divWillCopiedId, divWillFilledId) {
    // get div will be copied and get the div contain image inside it
    const divWillCopied = document.getElementById(divWillCopiedId);
    const divContainImage = divWillCopied.getElementsByTagName('div')[0];
    // get the div will filled with the image
    const divWillFilled = document.getElementById(divWillFilledId);
    const firstDiv = divWillFilled.getElementsByTagName('div')[0];
    // copy the image
    firstDiv.innerHTML = divContainImage.innerHTML;
    // remove + button from the second div
    const secondDiv = divWillFilled.getElementsByTagName('div')[2];
    const iTag = secondDiv.querySelector('.la-plus-circle');
    // iTag.remove();
    // remove + class and add edit class on i tag
    iTag.classList.remove('la-plus-circle');
    iTag.classList.add('la-edit');
  }

  handleCreateMenu(event) {
    event.preventDefault();

    // check all dates has its items at least one item
    let checkAllDatesExist = true;
    for (let i = 0; i < this.state.dates.length; i++) {
      this.state.data.find((dateItem) => {
        if (Date.parse(this.state.dates[i]) === Date.parse(dateItem.date)) {
          checkAllDatesExist = true;
          return true;
        } else {
          checkAllDatesExist = false;
        }
      });
    }

    if (this.state.menuName === '') {
      swal.fire({ title: 'Error', type: 'error', text: 'Please enter menu name', timer: 2000 });
    } else if (this.state.data.length === 0 || !checkAllDatesExist) {
      swal.fire({
        title: 'Error',
        type: 'error',
        text: 'You must choose meals for every date',
        timer: 2000,
      });
    } else {
      const body = {
        name: this.state.menuName,
        zone_id: this.state.zone_id,
        data: this.state.data,
        allow_fridays: this.state.allow_fridays,
        allow_saturdays: this.state.allow_saturdays,
        ...(this.state.ignored_holidays.length > 0 && {
          ignored_holidays: this.state.ignored_holidays,
        }),
      };

      axios
        .post('/menus', body)
        .then((response) => {
          if (response.status === 201) {
            swal.fire({
              title: 'Success',
              type: 'success',
              text: 'Menu created successfully!',
              timer: 2000,
            });
            setTimeout(() => {
              this.props.history.push('/admin/menus');
            }, 1000);
          } else {
            swal.fire({ title: 'Error', type: 'error', text: 'something went wrong !' });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }

  weekNumber(num, firstIndex) {
    const lastWeekNumber =
      this.state.menuDays % this.state.noOfWeekDays === 0
        ? parseInt(this.state.menuDays / this.state.noOfWeekDays)
        : parseInt(this.state.menuDays / this.state.noOfWeekDays) + 1;
    // draw options
    const options = [];
    for (let index = 0; index < lastWeekNumber; index++) {
      options.push(
        <option key={index} value={index * 5} data-currentweek={firstIndex}>
          {'Week #'}
          {index + 1}
        </option>,
      );
    }
    return (
      <div className={'row'}>
        <div className={'col-md-4'}>
          <h2>
            {'Week #'}
            {num}
          </h2>
        </div>
        <div className={'col-md-3'}>
          <select className={'form-control'} onChange={this.handelDuplicateWeek}>
            <option key={'all'} value={'all'}>
              {'Duplicate Week'}
            </option>
            {options.map((option) => option)}
          </select>
        </div>
      </div>
    );
  }

  tabDates() {
    let firstIndex = 0;
    let prevWeeKNumber = 0;
    return this.state.dates.map((date, index) => {
      const currentWeekNumber = Math.ceil((index + 1) / this.state.noOfWeekDays);
      let weekNum = '';
      if (prevWeeKNumber !== currentWeekNumber) {
        weekNum = this.weekNumber(currentWeekNumber, firstIndex);
        firstIndex += this.state.noOfWeekDays;
        prevWeeKNumber = currentWeekNumber;
      }
      return (
        <div key={date}>
          {weekNum}
          <AddDateForm
            key={date}
            date={date}
            merchants={this.state.merchants}
            handleAddMealToDate={this.handleAddMealToDate}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div className={'row'}>
          <div className={'col-md-12'}>
            <div className={'m-portlet m-portlet--tab'}>
              <div className={'m-portlet__head h-100'}>
                <div className={'m-portlet__head-caption w-100'}>
                  <div className={'m-portlet__head-title w-100'}>
                    <span className={'m-portlet__head-icon m--hide'}>
                      <i className={'la la-gear'} />
                    </span>
                    <div className={'row w-100 mt-3'}>
                      {!this.state.admin_has_city && (
                        <div className={'col-md-4'}>
                          <select className={'form-control'} onChange={this.handleChangeCity}>
                            <option key={'all'} value={0}>
                              {'Select City'}
                            </option>
                            {this.state.cities.map((city) => {
                              if (city.active) {
                                return (
                                  <option key={city.en_name} value={city.id}>
                                    {city.en_name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </div>
                      )}
                      <div className={`col-md-${this.state.date_columns_width}`}>
                        <div>
                          <select className={'form-control'} onChange={this.handleChangeZone}>
                            <option key={'all'} value={0}>
                              {'Select Zone'}
                            </option>
                            {this.props.zones.map((zone) => {
                              if (zone.is_active) {
                                return (
                                  <option key={zone.id} value={zone.id}>
                                    {zone.name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <p style={{ color: 'red' }}>
                            {this.state.validation.zone != '' ? this.state.validation.zone : ' '}
                          </p>
                        </div>
                        <div>
                          <Select
                            isSearchable
                            isMulti
                            placeholder={'Holiday to neglect'}
                            options={this.state.holidays.map((holiday) => ({
                              label: holiday.description,
                              value: holiday.id,
                            }))}
                            onChange={this.handelWhenHolidaySelectedToNeglect}
                          />
                        </div>
                      </div>
                      <div className={`col-md-${this.state.date_columns_width}`}>
                        <div className={'input-group date p-1'}>
                          <DayPickerInput
                            name={'startDate'}
                            dayPickerProps={{ todayButton: 'Today' }}
                            onDayChange={this.handleChangeDay}
                            placeholder={'Start Date'}
                          />
                          <div className={'input-group-append'}>
                            <span className={'input-group-text'}>
                              <i className={'la la-calendar glyphicon-th'} />
                            </span>
                          </div>
                        </div>
                        <div className={'input-group date p-1'}>
                          <DayPickerInput
                            name={'endDate'}
                            dayPickerProps={{ todayButton: 'Today' }}
                            onDayChange={this.handleChangeDay}
                            placeholder={'End Date'}
                          />
                          <div className={'input-group-append'}>
                            <span className={'input-group-text'}>
                              <i className={'la la-calendar glyphicon-th'} />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={'col-md-2'}>
                        <div className={'form-check form-check-inline'}>
                          <input
                            name={'allow_fridays'}
                            className={'form-check-input'}
                            type={'checkbox'}
                            checked={this.state.allow_fridays}
                            onChange={this.handelCheckBoxChange}
                          />
                          <label className={'form-check-label'} htmlFor={'inlineCheckbox1'}>
                            {'Allow Fridays'}
                          </label>
                        </div>
                        <div className={'form-check form-check-inline'}>
                          <input
                            name={'allow_saturdays'}
                            className={'form-check-input'}
                            type={'checkbox'}
                            checked={this.state.allow_saturdays}
                            onChange={this.handelCheckBoxChange}
                          />
                          <label className={'form-check-label'} htmlFor={'inlineCheckbox1'}>
                            {'Allow Saturdays'}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <form className={'m-form m-form--fit m-form--label-align-right'}>
                <div className={'m-portlet__body'}>
                  <div className={'form-group m-form__group'}>
                    <label>{'Menu Name'}</label>
                    <input
                      required
                      type={'text'}
                      name={'menu_name'}
                      className={'form-control m-input m-input--air m-input--pill'}
                      placeholder={'menu name'}
                      onChange={this.handleMenuNameChange}
                    />
                  </div>

                  {this.state.dates && (
                    <div className={'form-group m-form__group'}>{this.tabDates()}</div>
                  )}
                </div>
                <div className={'m-portlet__foot m-portlet__foot--fit'}>
                  <div className={'m-form__actions'}>
                    <button className={'btn btn-primary'} onClick={this.handleCreateMenu}>
                      {'Submit'}
                    </button>
                  </div>
                </div>
              </form>
              {/*  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cities: state.cityList.data,
    zones: state.zoneList.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zoneList(params) {
      dispatch(zoneList(params));
    },
    cityList({}) {
      dispatch(cityList({}));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMenu);
