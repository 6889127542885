import Swal from 'sweetalert2';
import { axios } from '../../../../services/axios';
import * as CONST from '../../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.SUBSCRIPTION_ORDER_EDIT_SUCCESS, order: data.order });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.SUBSCRIPTION_ORDER_EDIT_ERROR, error });
  Swal.fire({
    title: 'Error',
    type: 'error',
    text: error?.message,
    timer: 3500,
  });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const subscriptionOrderEdit = ({
  subscriptionId,
  orderId,
  mealId,
  merchantId,
  deliveryTime,
  deliveryDate,
  status,
  addressId,
}) => {
  return async (dispatch) => {
    dispatch({ type: CONST.SUBSCRIPTION_ORDER_EDIT_REQUEST });
    const body = {
      merchant_id: merchantId,
      meal_id: mealId,
      delivery_time: deliveryTime,
      delivery_date: deliveryDate,
      status: status,
      address_id: addressId,
    };

    axios
      .put(`/subscriptions/${subscriptionId}/orders/${orderId}`, body)
      .then((response) => {
        handleResponse(dispatch, response);
      })
      .catch((error) => {
        onFailed(dispatch, error?.response?.data);
      });
  };
};
