import * as CONST from '../../constants';

const INITIAL_STATE = {
  loading: true,
  data: [],
  error: null,
  removedOrders: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.MAP_DRIVER_ORDERS_LIST_REQUEST:
      return { ...state, loading: true };
    case CONST.MAP_DRIVER_ORDERS_LIST_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case CONST.MAP_DRIVER_ORDERS_LIST_ERROR:
      return { ...state, loading: false, error: action.error };
    case CONST.MAP_DRIVER_ORDERS_LIST_UPDATE:
      return { ...state, loading: false, data: action.data };
    default:
      return state;
  }
};
