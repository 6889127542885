import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.SUBSCRIPTIONS_INDEX_SUCCESS, data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.SUBSCRIPTIONS_INDEX_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const subscriptionList = ({ params }) => {
  return async (dispatch) => {
    dispatch({ type: CONST.SUBSCRIPTIONS_INDEX_REQUEST });

    axios.get('/subscriptions', { params }).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
