import { useEffect, useState } from 'react';

import { fetchMealAttributes, listAllAttributesOptions } from '../../../api/meals/mealAttributes';

export const useFetchMealAttributes = (mealId) => {
  const [attributesOptions, setAttributesOptions] = useState({
    cuisine: [],
    dishType: [],
    diet: [],
    allergies: [],
    mainIngredient: [],
  });
  const [mealAttributes, setMealAttributes] = useState();
  const [isLoadingAttributes, setIsLoadingAttributes] = useState(false);
  const [isLoadingAttributesOptions, setIsLoadingAttributesOptions] = useState(false);

  useEffect(() => {
    if (mealId && !isLoadingAttributes) {
      setIsLoadingAttributes(true);
      fetchMealAttributes(mealId)
        .then((attributes) => {
          setMealAttributes(attributes);
        })
        .finally(() => {
          setIsLoadingAttributes(false);
        });
    }
  }, [mealId]);

  useEffect(() => {
    if (!isLoadingAttributesOptions) {
      setIsLoadingAttributesOptions(true);
      listAllAttributesOptions()
        .then((attributesOptions) => {
          setAttributesOptions(serializeOptions(attributesOptions));
        })
        .finally(() => {
          setIsLoadingAttributesOptions(false);
        });
    }
  }, []);

  const serializeOptions = (options) => {
    const formattedOptions = {};
    Object.keys(options).forEach((key) => {
      formattedOptions[key] = options[key].map((option) => ({
        value: option.id,
        label: option.label,
      }));
    });
    return formattedOptions;
  };

  return {
    mealAttributes,
    attributesOptions,
    isLoading: isLoadingAttributes || isLoadingAttributesOptions,
  };
};
