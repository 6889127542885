import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';

import { MERCHANT_STATUS } from '../../../constants/merchant';
import { axios } from '../../../services/axios';

export const ChangeStatusButton = ({ merchantStatus, merchantId }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [affectedMenus, setAffectedMenus] = useState([]);
  const toggleModal = () => {
    setModal(!modal);
  };

  const changeMerchantStatusValidation = async () => {
    setLoading(true);
    if (merchantStatus === MERCHANT_STATUS.ACTIVE) {
      return axios
        .get(`/merchants/${merchantId}/validate-affected-menus`)
        .then((response) => {
          const affectedMenu = response.data.data;
          if (affectedMenu.length === 0) {
            return changeStatus({
              merchant_id: merchantId,
              status: MERCHANT_STATUS.INACTIVE,
            });
          } else {
            setAffectedMenus(affectedMenu);
            toggleModal();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      return changeStatus({
        merchant_id: merchantId,
        status: MERCHANT_STATUS.ACTIVE,
      });
    }
  };
  const onSuccess = (newStatus) => {
    return Swal.fire({
      title: `Merchant ${
        newStatus === MERCHANT_STATUS.INACTIVE ? 'Deactivated' : 'Activated'
      } successufully`,
      type: 'success',
      text: 'Merchant status changed successufully!',
      timer: 3000,
    }).then(() => window.location.reload());
  };
  const changeStatus = (body) => {
    return axios
      .put('/merchants/change-merchants-status', body)
      .then(() => {
        return onSuccess(body.status);
      })
      .catch((e) => {
        let errorMessages = 'Something went wrong!';
        if (e.response?.data?.errors) {
          errorMessages = Object.keys(e.response?.data?.errors)
            .map((key) => {
              return `<pre>${key} : ${e.response.data?.errors?.[key]}</pre>`;
            })
            .join(' ');
        } else if (e.response?.data?.message) {
          errorMessages = e.response.data?.message;
        }
        return Swal.fire({
          title: "Merchant Status can't be changed",
          type: 'error',
          html: errorMessages,
          timer: 5000,
        });
      });
  };
  return (
    <div>
      <Button
        color={merchantStatus === MERCHANT_STATUS.ACTIVE ? 'danger' : 'success'}
        className={'btn m-btn--pill m-btn m-btn--custom'}
        onClick={changeMerchantStatusValidation}
        disabled={loading}>
        {merchantStatus === MERCHANT_STATUS.ACTIVE ? 'Deactivate' : 'Activate'}
      </Button>
      <Modal toggle={toggleModal} isOpen={modal} size={'lg'}>
        <ModalHeader>
          <strong style={{ color: 'red' }}>{'Warning! Merchant has active menu(s)'} </strong>
        </ModalHeader>
        <ModalBody>
          <label className={'col-9 col-form-label'}>
            Please be aware that deactivating this merchant will affect
            <strong style={{ color: 'red' }}>{` (${affectedMenus?.length || 0}) `}</strong>
            Menus.
          </label>

          <div className={'col-12'} style={{ maxHeight: '220px', overflowY: 'scroll' }}>
            <table className={'table table-striped m-table'}>
              <thead>
                <tr>
                  <td style={{ width: '20%', fontWeight: 'bold' }}>{'Menu Id'}</td>
                  <td style={{ fontWeight: 'bold' }}>
                    {'Exclusive Dates (No Alternatives for this merchants on these days)'}
                  </td>
                </tr>
              </thead>
              <tbody>
                {affectedMenus.map((menu, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <a
                          target={'_blank'}
                          style={{ textDecoration: 'underline' }}
                          href={`${process.env.REACT_APP_NEW_DASHBOARD_URL}/food/menus/${menu?.menu_id}`}>
                          {menu?.menu_id}
                        </a>
                      </td>
                      <td>{menu.exclusive_dates.join(', ')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color={'danger'} className={'btn btn-primary'} onClick={toggleModal}>
            {'Cancel'}
          </Button>
          <Button
            color={'success'}
            className={'btn btn-primary'}
            onClick={() =>
              changeStatus({
                merchant_id: merchantId,
                status: MERCHANT_STATUS.INACTIVE,
              })
            }>
            {'Submit'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
