import { DatePicker } from '@material-ui/pickers';
import { camelizeKeys } from 'humps';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { withApollo } from 'react-apollo';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { axios } from '../../../services/axios';
import { getCities } from '../../../services/getCities';
import Alert from '../../../utils/alert';

const CreateHoliday = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [description, setDescription] = useState('');
  const [arDescription, setArDescription] = useState('');
  const [selectedCities, setSelectedCities] = useState('');
  const [cities, setCities] = useState([]);
  // const cities = useQuery(getCitiesQuery);

  const CitiesSelect = useRef(null);

  useEffect(() => {
    if (isEmpty(cities)) {
      getCities().then((cities) => {
        setCities(cities);
      });
    }
  });

  const handleCityChange = (data) => {
    setSelectedCities(data);
  };

  const toggle = () => {
    setShowModal(!showModal);
  };
  const handleDateFrom = (date) => {
    setDateFrom(moment(date).format('YYYY-MM-DD'));
  };
  const handleDateTo = (date) => {
    setDateTo(moment(date).format('YYYY-MM-DD'));
  };
  const changeDescription = (e) => {
    setDescription(e.target.value);
  };

  const changeArDescription = (e) => {
    setArDescription(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setShowLoader(true);

    const body = {
      start_date: dateFrom,
      end_date: dateTo,
      description,
      description_ar: arDescription,
      cities: selectedCities.map((city) => city.value),
    };

    axios.post('off-days', body).then((response) => {
      if (!response) {
        setShowLoader(false);
        setShowModal(false);
        Alert.error('Something went wrong!', 1000);
      } else if (response.status === 201) {
        setShowLoader(false);
        setShowModal(false);
        setDateFrom(null);
        setDateTo(null);
        setDescription(null);
        setArDescription(null);

        props.onCreate();
        Alert.success('Holiday Created successfully!', 1000);
      } else if (response.status === 400) {
        setShowLoader(false);
        Alert.error(response.data.error.start_date, 2000);
      }
    });
  };

  const options = camelizeKeys(cities).map((city) => {
    return { value: city.id, label: city.enName };
  });

  return (
    <div>
      <Button
        className={
          'm-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--icon m-btn--icon-only m-btn--pill m-dropdown__toggle'
        }
        onClick={toggle}>
        {props.children}
      </Button>

      <Modal isOpen={showModal}>
        <form onSubmit={handleSubmit}>
          <ModalHeader>{'Create Holiday'}</ModalHeader>
          <ModalBody>
            {showLoader ? (
              <div className={'loader'} />
            ) : (
              <div className={'modal-body-content'}>
                <div className={'group-control'}>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      <div className={'input-group date'}>
                        <DatePicker
                          label={'Date from'}
                          format={'YYYY-MM-DD'}
                          value={dateFrom}
                          onChange={handleDateFrom}
                          className={'picker col-md-12'}
                        />
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className={'input-group date'}>
                        <DatePicker
                          label={'Date To'}
                          format={'YYYY-MM-DD'}
                          value={dateTo}
                          onChange={handleDateTo}
                          className={'picker col-md-12'}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <label>{'Description: '}</label>
                  <input
                    type={'text'}
                    className={'form-control'}
                    value={description}
                    placeholder={'Description'}
                    onChange={changeDescription}
                    required
                  />
                  <br />
                  <br />
                  <label>{'Arabic Description: '}</label>
                  <input
                    type={'text'}
                    className={'form-control'}
                    value={arDescription}
                    placeholder={'Arabic Description'}
                    onChange={changeArDescription}
                    required
                  />
                  <br />
                  {isEmpty(cities) ? null : (
                    <Select
                      ref={CitiesSelect}
                      closeMenuOnSelect={false}
                      isMulti
                      placeholder={'Select City....'}
                      options={options}
                      onChange={handleCityChange}
                    />
                  )}
                </div>
              </div>
            )}
          </ModalBody>
          {!showLoader && (
            <ModalFooter>
              <input
                type={'submit'}
                value={'Submit'}
                color={'primary'}
                className={'btn btn-primary'}
              />
              <Button color={'danger'} onClick={toggle}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default withApollo(CreateHoliday);
