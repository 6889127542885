const DEV_MODE = process.env.NODE_ENV === 'development';
const responseMessageStyle = {
  url:
    'font-size: 10px; font-weight: bold; font-family: monospace; background-color: #4cae4f; color: #ffffff; padding: 4px 9px; border-radius: 50px 0 0 50px; margin: 15px 0;',
  text:
    'font-size: 10px; font-weight: bold; font-family: monospace; background-color: #d1ebd2; color: #4cae4f; padding: 4px 9px; border-radius: 0 50px 50px 0; margin: 15px 0;',
  error:
    'font-size: 10px; font-weight: bold; font-family: monospace; background-color: #ec0e0e; color: #ffffff; padding: 4px 9px; border-radius: 50px 0 0 50px; margin: 15px 0;',
  errorMessage:
    'font-weight: bold; color: #ec0e0e; border-bottom: 2px solid #ec0e0e; margin-left: 10px',
  errorText:
    'font-size: 10px; font-weight: bold; font-family: monospace; background-color: #f5d9d9; color: #ec0e0e; padding: 4px 9px; border-radius: 0 50px 50px 0; margin: 15px 0;',
  requestURL:
    'font-size: 10px; font-weight: bold; font-family: monospace; background-color: #0066cc; color: #ffffff; padding: 4px 9px; border-radius: 50px 0 0 50px; margin: 15px 0;',
  requestText:
    'font-size: 10px; font-weight: bold; font-family: monospace; background-color: hsl(210 100% 89% / 1); color: #0066cc; padding: 4px 9px; border-radius: 0 50px 50px 0; margin: 15px 0;',
};

class AxiosLogger {
  requestSuccess(config) {
    if (DEV_MODE) {
      // eslint-disable-next-line no-console
      console.log(
        `%c${config?.url}%c${config?.method.toUpperCase()} Request:`,
        responseMessageStyle.requestURL,
        responseMessageStyle.requestText,
        config,
      );
    }
  }
  requestFail(error) {
    if (DEV_MODE) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
  responseSuccess(response) {
    if (DEV_MODE) {
      // eslint-disable-next-line no-console
      console.log(
        `%c${response?.config?.url}%cResponse of ${response?.config?.method.toUpperCase()}:`,
        responseMessageStyle.url,
        responseMessageStyle.text,
        response?.data !== null ? response?.data : response,
      );
    }
  }
  responseFail(error) {
    if (DEV_MODE) {
      // eslint-disable-next-line no-console
      console.log(
        `%c${error?.response?.config?.url}%cResponse Error: ${error?.response?.status}%c${error}`,
        responseMessageStyle.error,
        responseMessageStyle.errorText,
        responseMessageStyle.errorMessage,
      );
    }
  }
}

export const axiosLogger = new AxiosLogger();
