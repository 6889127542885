import { createAction } from 'redux-actions';

import { MAP_ASSIGN_ORDERS, RESET_CLIENT_IDS } from '../../../constants';

export const groupAssign = (clientsIds, ordersIds) => {
  return async (dispatch, getState) => {
    let clientsIdsArr = [];
    let ordersIdsArr = [];

    if (clientsIds.length === 0) {
      clientsIdsArr = [];
      ordersIdsArr = [];
    } else {
      clientsIdsArr = [...new Set(getState().groupAssign.clientsIds.concat(clientsIds))];
      ordersIdsArr = [...new Set(getState().groupAssign.ordersIds.concat(ordersIds))];
    }

    dispatch({ type: MAP_ASSIGN_ORDERS, clientsIds: clientsIdsArr, ordersIds: ordersIdsArr });
  };
};

export const resetClientIds = createAction(RESET_CLIENT_IDS);
