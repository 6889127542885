import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { axios } from '../../../services/axios';

export const SearchMeal = ({ mealId, selectedMeal, onChangeSelectedMeal }) => {
  const [merchants, setMerchants] = useState([]);
  const [meals, setMeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);

    axios
      .get('/merchants')
      .then((response) => {
        const merchantsList = response?.data?.data?.map((merchant) => {
          return { value: merchant.id, label: merchant.restaurant_name };
        });
        setMerchants(merchantsList);
      })
      .finally(() => setIsLoading(false));
  }, []);
  const onChange = (selectedMerchant) => {
    setIsLoading(true);
    onChangeSelectedMeal(null);
    return axios
      .get(`/merchants/${selectedMerchant.value}/meals`)
      .then((response) => {
        const mealList = response?.data?.data
          ?.filter((meal) => meal?.item_id !== mealId)
          ?.map((meal) => {
            return { value: meal.item_id, label: meal?.en_name || meal?.ar_name };
          });
        setMeals(mealList);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className={'row'}>
      <div className={'col-md-6'}>
        <Select
          id={'merchantsSelect'}
          onChange={onChange}
          options={merchants}
          label={'Merchant'}
          placeholder={'Select merchant'}
          isLoading={isLoading}
        />
      </div>
      <div className={'col-md-6'}>
        <Select
          id={'mealsSelect'}
          onChange={onChangeSelectedMeal}
          options={meals}
          value={selectedMeal}
          label={'Meals'}
          placeholder={'Select meal'}
          isDisabled={merchants === null}
          isLoading={merchants && isLoading}
        />
      </div>
    </div>
  );
};
