import humps from 'humps';

import { axios } from '../../../services/axios';
import { USER_LIST_ERROR, USER_LIST_REQUEST, USER_LIST_SUCCESS } from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: USER_LIST_SUCCESS, data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: USER_LIST_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, humps.camelizeKeys(response.data));
  } else {
    onFailed(dispatch, humps.camelizeKeys(response.data));
  }
};

export const userList = ({ params }) => {
  return async (dispatch) => {
    dispatch({ type: USER_LIST_REQUEST });

    axios.get('/users', { params }).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
