import * as CONST from '../../constants';

const INITIAL_STATE = {
  ordersCount: 0,
  onConfirm: null,
  isDependentOrdersModalOpen: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.MEALS_VALIDATE_AFFECTED_ORDERS_REQUEST:
      return { ...state, ordersCount: action.payload.ordersCount };
    case CONST.MEALS_VALIDATE_AFFECTED_ORDERS_SET_MODAL_PAYLOAD:
      return {
        ...state,
        isDependentOrdersModalOpen: true,
        onConfirm: action.payload.onConfirm,
      };
    case CONST.MEALS_VALIDATE_AFFECTED_ORDERS_MODAL_TOGGLE:
      return {
        ...state,
        isDependentOrdersModalOpen: action.payload.isDependentOrdersModalOpen,
      };
    default:
      return state;
  }
};
