import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, action: null, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.ACTION_CREATE_REQUEST:
      return { ...INITIAL_STATE, loading: true, action: null, error: null };
    case CONST.ACTION_CREATE_SUCCESS:
      return { ...INITIAL_STATE, loading: false, action: action.action, error: null };
    case CONST.ACTION_CREATE_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error, action: null };
    case CONST.CLOSE_MODAL:
      return { ...INITIAL_STATE, loading: false, error: null, action: null };
    default:
      return state;
  }
};
