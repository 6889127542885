import { resetFilterData, setDefaultFilterData, updateFilterData } from '../../actions';
import initState from '../../initState';

export default (state = initState.mapFilter, action) => {
  switch (action.type) {
    case updateFilterData().type:
      return {
        ...state,
        ...action.payload,
      };
    case setDefaultFilterData().type:
      return {
        ...state,
        defaultData: state,
      };
    case resetFilterData().type:
      return initState.mapFilter;
    default:
      return state;
  }
};
