import { axios } from '../../../../services/axios';
import * as CONST from '../../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.COMPLAINT_COMMENT_CREATE_SUCCESS, data: data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.COMPLAINT_COMMENT_CREATE_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const complaintCommentCreate = (data) => {
  return async (dispatch) => {
    dispatch({ type: CONST.COMPLAINT_COMMENT_CREATE_REQUEST });

    const body = new FormData();

    body.append('comment', data.comment);
    data.images.forEach((image) => {
      body.append('images[]', image);
    });

    const ticketId = data.ticketId;

    axios.post(`/tickets/${ticketId}/comment`, body).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
