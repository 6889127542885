import * as CONST from '../../../constants';

const INITIAL_STATE = { loading: true, comments: [], error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.TICKET_COMMETS_LIST_REQUEST:
      return { ...INITIAL_STATE, loading: true, comments: [], error: null };
    case CONST.TICKET_COMMETS_LIST_SUCCESS:
      return { ...INITIAL_STATE, loading: false, comments: action.data.data };
    case CONST.TICKET_COMMETS_LIST_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    default:
      return state;
  }
};
