import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { uploadImage } from '../../../api/meals/mealMultiImages';
import { MB } from '../../../constants/filesSizes';
import { axios } from '../../../services/axios';
import Alert from '../../../utils/alert';
import MealForm from '../components/form';

export default class CreateMeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showLoader: true,
      loaderMessage: '',
      showBody: false,
      ar_name: '',
      en_name: '',
      ar_description: '',
      en_description: '',
      ar_full_description: '',
      en_full_description: '',
      merchant_id: '',
      premium_price: '',
      protein: '',
      carbs: '',
      fats: '',
      calories: '',
      dish: '',
      cost: '',
      market_price: '',
      stock: '',
      color: '',
      label_number: '',
      coverImg: '',
      outsideImg: '',
      tags: [],
      productTypes: [],
      images: [],
      validation: {
        ar_name: '',
        en_name: '',
        ar_description: '',
        en_description: '',
        ar_full_description: '',
        en_full_description: '',
        merchant_id: '',
        premium_price: '',
        protein: '',
        carbs: '',
        fats: '',
        calories: '',
        dish: '',
        cost: '',
        stock: '',
        color: '',
        label_number: '',
        coverImg: '',
        outsideImg: '',
      },
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.uploadMultiImages = this.uploadMultiImages.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  componentDidMount() {
    this.setState({
      showBody: true,
      showLoader: false,
    });
  }
  parseProductTypes(productTypes) {
    return productTypes.map((productType) => ({
      ...productType,
      value: productType.id || productType.value,
      label: productType.enName,
    }));
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    this.setState({ [inputName]: inputValue });
  }

  handleSelectedTagsChange = (tags) => this.setState({ tags });

  handleSelectedProductTypesChange = (productTypes) => this.setState({ productTypes });

  async uploadMultiImages() {
    this.setState({
      loaderMessage: 'uploading images ...',
    });
    const uploadedImages = [];
    for (const image of this.state.images) {
      const res = await uploadImage(image);
      if (res?.data?.image_url) {
        uploadedImages.push(res.data.image_url);
      } else {
        Alert.error('Something went wrong');
      }
    }
    this.setState({ images: uploadedImages, loaderMessage: '' });
  }

  deleteImage(image) {
    const newImages = [...this.state.images];
    const editedImages = newImages.filter((img) => img !== image);
    this.setState({ images: editedImages });
  }

  handleUploadPhoto(e) {
    const inputName = e.target.name;
    const isMultiPicuter = inputName === 'images';
    const files = Array.from(e.target.files);
    const isMaxSizeExceeded = files.some((img) => img.size > MB);
    if (isMultiPicuter && files.length + this.state.images.length > 4) {
      Alert.error('Maximum number of pictures is 4.');
      return;
    } else if (isMultiPicuter && isMaxSizeExceeded) {
      Alert.error('please make sure that every image dose not exceed 1MB');
    } else {
      this.setState({ [inputName]: isMultiPicuter ? [...this.state.images, ...files] : files[0] });
    }
  }
  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      showLoader: true,
      showBody: false,
    });
    await this.uploadMultiImages();

    const data = new FormData();
    data.append('ar_name', this.state.ar_name);
    data.append('en_name', this.state.en_name);
    data.append('ar_description', this.state.ar_description);
    data.append('en_description', this.state.en_description);
    data.append('ar_full_description', this.state.ar_full_description);
    data.append('en_full_description', this.state.en_full_description);
    data.append('merchant_id', this.state.merchant_id);
    if (this.state.premium_price) {
      data.append('premium_price', this.state.premium_price);
    }
    data.append('protein', Number(this.state.protein));
    data.append('carb', Number(this.state.carbs));
    data.append('fat', Number(this.state.fats));
    data.append('calories', Number(this.state.calories));
    data.append('dish', this.state.dish);
    data.append('cost', this.state.cost);
    data.append('market_price', this.state.market_price);
    data.append('color', this.state.color);
    if (this.state.label_number) {
      data.append('label_number', Number(this.state.label_number));
    }
    data.append('coverImg', this.state.coverImg);
    data.append('outsideImg', this.state.outsideImg);

    if (this.state.images) {
      this.state.images.forEach((image, index) => data.append(`images[${index}]`, image));
    }
    if (this.state.tags?.length > 0) {
      const tagIds = this.state.tags?.map((tag) => tag.value);
      tagIds.forEach((id, index) => data.append(`tags[${index}]`, id));
    }

    if (this.state.productTypes?.length > 0) {
      const productTypesIds = this.state.productTypes?.map((productType) => productType.value);
      productTypesIds.forEach((id, index) => data.append(`product_types[${index}]`, id));
    }

    // check if stock is send and add it to request body
    if (this.state.stock) {
      data.append('stock', Number(this.state.stock));
    }
    axios
      .post('/meals', data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            showLoader: false,
            showBody: true,
            modal: false,
          });
          swal.fire({
            title: 'Success',
            type: 'success',
            text: 'Meal created successfully!',
            timer: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((e) => {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false,
          images: [],
          outsideImg: '',
          coverImg: '',
        });
        let errorMessages = 'Something went wrong!';
        if (e.response.data?.error) {
          errorMessages = Object.keys(e.response?.data?.error)
            .map((key) => {
              return `<pre>${key} : ${e.response.data?.error[key]}</pre>`;
            })
            .join(' ');
        } else if (e.response.data?.message) {
          errorMessages = e.response.data?.message;
        }

        swal.fire({
          title: 'Error',
          type: 'error',
          html: errorMessages,
          timer: 6000,
        });
      });
  }

  render() {
    return (
      <div>
        {/* beign modal form */}
        <Button
          id={'add-new-meal-btn'}
          color={'success'}
          onClick={this.toggle}
          className={
            'm-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle'
          }>
          <i className={'la la-plus-circle m--font-brand'} />
        </Button>
        <Modal
          size={'lg'}
          isOpen={this.state.modal}
          toggle={this.toggle}
          onClosed={() => this.handleSelectedTagsChange([])}>
          <Form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={this.toggle}>{'Create Meal'}</ModalHeader>
            <ModalBody>
              <>
                <h4 style={{ textAlign: 'center' }}> {this.state.loaderMessage}</h4>
                <div
                  style={this.state.showLoader ? {} : { display: 'none' }}
                  className={'loader'}
                />
              </>
              <div
                style={this.state.showBody ? {} : { display: 'none' }}
                className={'modal-body-content'}>
                {/* meal form */}
                <MealForm
                  formType={'create'}
                  merchants={this.props.merchants}
                  allProductTypes={this.props.allProductTypes}
                  values={this.state}
                  handleChange={this.handleChange}
                  handleSelectedTagsChange={this.handleSelectedTagsChange}
                  handleSelectedProductTypesChange={this.handleSelectedProductTypesChange}
                  parseProductTypes={this.parseProductTypes}
                  handleUploadPhoto={this.handleUploadPhoto}
                  uploadMultiImages={this.uploadMultiImages}
                  deleteImage={this.deleteImage}
                />
              </div>
            </ModalBody>
            <ModalFooter style={this.state.showBody ? {} : { display: 'none' }}>
              <input
                id={'meal-submit-btn'}
                type={'submit'}
                value={'Submit'}
                color={'primary'}
                className={'btn btn-primary'}
              />
              <Button color={'danger'} onClick={this.toggle}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        {/* end modal form */}
      </div>
    );
  }
}
