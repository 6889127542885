import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.CATEGORIES_LIST_SUCCESS, data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.CATEGORIES_LIST_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const categoriesList = () => {
  return async (dispatch) => {
    dispatch({ type: CONST.CATEGORIES_LIST_REQUEST });

    axios
      .get('/categories')
      .then((response) => {
        handleResponse(dispatch, response);
      })
      .catch((error) => console.error(error));
  };
};
