import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, voucher: null, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.VOUCHER_CREATE_REQUEST:
      return { ...INITIAL_STATE, loading: true, voucher: null, error: null };
    case CONST.VOUCHER_CREATE_SUCCESS:
      return { ...INITIAL_STATE, loading: false, voucher: action.voucher, error: null };
    case CONST.VOUCHER_CREATE_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error, voucher: null };
    case CONST.CLOSE_MODAL:
      return { ...INITIAL_STATE, loading: false, error: null, voucher: null };
    default:
      return state;
  }
};
