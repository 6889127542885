import { Grid, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React from 'react';
import Select from 'react-select';
import { FormGroup, FormText, Input, Label } from 'reactstrap';

import { CreatableTagsMultiSelect } from '../../../components/CreatableTagsMultiSelect';
import { TAG_TYPES } from '../../../constants/tag-types';
import { AMAZON_CLOUD_FRONT_URL } from '../../../constants/meals';

export default class MealForm extends React.Component {
  render() {
    const requiredinput = this.props.formType === 'create' ? 'required' : '';
    return (
      <div>
        <div className={'row'}>
          <div className={'col-md-6'}>
            <FormGroup>
              <Label for={'backdrop'}>{'Arabic Title'}</Label>{' '}
              <Input
                id={'meal-input-ar-name'}
                type={'text'}
                name={'ar_name'}
                value={this.props.values.ar_name}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.ar_name !== ''
                  ? this.props.values.validation.ar_name
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'backdrop'}>{'Arabic Description'}</Label>{' '}
              <Input
                id={'meal-input-ar-description'}
                type={'textarea'}
                name={'ar_description'}
                value={this.props.values.ar_description}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.ar_description !== ''
                  ? this.props.values.validation.ar_description
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'backdrop'}>{'Arabic Full Description'}</Label>{' '}
              <Input
                id={'meal-input-ar-full-description'}
                type={'textarea'}
                name={'ar_full_description'}
                value={this.props.values.ar_full_description}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.ar_full_description !== ''
                  ? this.props.values.validation.ar_full_description
                  : ' '}
              </p>
            </FormGroup>
          </div>

          <div className={'col-md-6'}>
            <FormGroup>
              <Label for={'backdrop'}>{'English Title'}</Label>{' '}
              <Input
                id={'meal-input-en-name'}
                type={'text'}
                name={'en_name'}
                value={this.props.values.en_name}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.en_name !== ''
                  ? this.props.values.validation.en_name
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'backdrop'}>{'English Description'}</Label>{' '}
              <Input
                id={'meal-input-en-description'}
                type={'textarea'}
                name={'en_description'}
                value={this.props.values.en_description}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.en_description !== ''
                  ? this.props.values.validation.en_description
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'backdrop'}>{'English Full Description'}</Label>{' '}
              <Input
                id={'meal-input-en-full-description'}
                type={'textarea'}
                name={'en_full_description'}
                value={this.props.values.en_full_description}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.en_full_description !== ''
                  ? this.props.values.validation.en_full_description
                  : ' '}
              </p>
            </FormGroup>
          </div>
        </div>

        <div className={'row'}>
          <div className={'col-md-6'}>
            <FormGroup>
              <Label>{'Merchants'}</Label>
              <Input
                id={'meal-input-merchant-id'}
                type={'select'}
                name={'merchant_id'}
                value={this.props.values.merchant_id}
                onChange={this.props.handleChange}
                required>
                <option key={'all'} value={''}>
                  {'All merchants'}
                </option>
                {this.props.merchants.map((merchant) => (
                  <option key={merchant.id} value={merchant.id}>
                    {merchant.restaurant_name}
                  </option>
                ))}
              </Input>
              <p style={{ color: 'red' }}>
                {this.props.values.validation.merchant_id !== ''
                  ? this.props.values.validation.merchant_id
                  : ' '}
              </p>
            </FormGroup>
          </div>
          <div className={'col-md-6'}>
            <FormGroup>
              <Label>{'Tags'}</Label>
              <CreatableTagsMultiSelect
                tagType={TAG_TYPES.MEAL}
                handleSelectedTagsChange={(tags) => this.props.handleSelectedTagsChange(tags)}
                defaultTags={this.props.values.tags}
              />
            </FormGroup>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-md-6'}>
            <FormGroup>
              <Label>{'Product Types'}</Label>
              <Select
                ref={(C) => (this.Select = C)}
                defaultValue={this.props.parseProductTypes(this.props.values.productTypes)}
                isMulti
                closeMenuOnSelect={false}
                options={this.props.parseProductTypes(this.props.allProductTypes)}
                onChange={this.props.handleSelectedProductTypesChange}
              />
              <input
                tabIndex={-1}
                autoComplete={'off'}
                style={{ marginLeft: '50px', opacity: 0, height: 0 }}
                value={this.props.values.productTypes}
                required={true}
              />
            </FormGroup>
          </div>
          <div className={'col-md-6'}>
            <FormGroup>
              <Label>{'Premium Price'}</Label>
              <Input
                id={'meal-input-premium-price'}
                type={'text'}
                name={'premium_price'}
                value={this.props.values.premium_price}
                onChange={this.props.handleChange}
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.premium_price !== ''
                  ? this.props.values.validation.premium_price
                  : ' '}
              </p>
            </FormGroup>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-md-6'}>
            <FormGroup>
              <Label>{'Stock'}</Label>
              <Input
                id={'meal-input-stock'}
                type={'text'}
                name={'stock'}
                value={this.props.values.stock}
                onChange={this.props.handleChange}
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.stock !== ''
                  ? this.props.values.validation.stock
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label>{'Cost'}</Label>
              <Input
                id={'meal-input-cost'}
                type={'text'}
                name={'cost'}
                value={this.props.values.cost}
                onChange={this.props.handleChange}
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.cost !== '' ? this.props.values.validation.cost : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label>{'Market Price'}</Label>
              <Input
                id={'meal-input-market-price'}
                type={'text'}
                name={'market_price'}
                value={this.props.values.market_price}
                onChange={this.props.handleChange}
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.market_price !== ''
                  ? this.props.values.validation.market_price
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'backdrop'}>{'Protein'}</Label>{' '}
              <Input
                id={'meal-input-protein'}
                type={'text'}
                name={'protein'}
                value={this.props.values.protein}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.protein !== ''
                  ? this.props.values.validation.protein
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'backdrop'}>{'Carbs'}</Label>{' '}
              <Input
                id={'meal-input-carbs'}
                type={'text'}
                name={'carbs'}
                value={this.props.values.carbs}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.carbs !== ''
                  ? this.props.values.validation.carbs
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'exampleFile'}>{'Cover Image'}</Label>
              <Input
                id={'meal-input-cover-image'}
                type={'file'}
                name={'coverImg'}
                onChange={this.props.handleUploadPhoto}
                required={requiredinput}
              />
              <FormText color={'muted'}>{'Please upload cover image'}</FormText>
              <p style={{ color: 'red' }}>
                {this.props.values.validation.coverImg !== ''
                  ? this.props.values.validation.coverImg
                  : ' '}
              </p>
            </FormGroup>

            {this.props.values.coverImg ? (
              <FormGroup>
                <img
                  className={'m--img-rounded m--marginless'}
                  style={{ width: '150px', height: '100px' }}
                  src={`${AMAZON_CLOUD_FRONT_URL}/meals/${this.props.values.coverImg}`}
                />
              </FormGroup>
            ) : (
              ''
            )}
          </div>

          <div className={'col-md-6'}>
            <FormGroup className={'d-flex flex-row w-100'}>
              <div className={'flex-grow-1'}>
                <Label>{'Color'}</Label>
                <Input
                  id={'meal-input-color'}
                  type={'color'}
                  name={'color'}
                  value={this.props.values.color}
                  onChange={this.props.handleChange}
                  required
                />
                <p style={{ color: 'red' }}>
                  {this.props.values.validation.color !== ''
                    ? this.props.values.validation.color
                    : ' '}
                </p>
              </div>
              <div className={'flex-grow-1 ml-3'}>
                <Label>{'Meal Number'}</Label>
                <Input
                  id={'meal-input-number'}
                  type={'text'}
                  name={'label_number'}
                  placeholder={'Enter meal number'}
                  value={
                    Number(this.props.values.label_number) ? this.props.values.label_number : ''
                  }
                  onChange={this.props.handleChange}
                />
                <p style={{ color: 'red' }}>
                  {this.props.values.validation.label_number !== ''
                    ? this.props.values.validation.label_number
                    : ' '}
                </p>
              </div>
            </FormGroup>

            <FormGroup>
              <Label>{'Dish'}</Label>
              <Input
                id={'meal-input-dish'}
                type={'text'}
                name={'dish'}
                value={this.props.values.dish}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.dish !== '' ? this.props.values.validation.dish : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'backdrop'}>{'Fats'}</Label>{' '}
              <Input
                id={'meal-input-fats'}
                type={'text'}
                name={'fats'}
                value={this.props.values.fats}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.fats !== '' ? this.props.values.validation.fats : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'backdrop'}>{'Calories'}</Label>{' '}
              <Input
                id={'meal-input-calories'}
                type={'text'}
                name={'calories'}
                value={this.props.values.calories}
                onChange={this.props.handleChange}
                required
              />
              <p style={{ color: 'red' }}>
                {this.props.values.validation.calories !== ''
                  ? this.props.values.validation.calories
                  : ' '}
              </p>
            </FormGroup>

            <FormGroup>
              <Label for={'exampleFile'}>{'Outside Image'}</Label>
              <Input
                id={'meal-input-outside-image'}
                type={'file'}
                name={'outsideImg'}
                onChange={this.props.handleUploadPhoto}
                required={requiredinput}
              />
              <FormText color={'muted'}>{'Please upload outside image'}</FormText>
              <p style={{ color: 'red' }}>
                {this.props.values.validation.outsideImg !== ''
                  ? this.props.values.validation.outsideImg
                  : ' '}
              </p>
            </FormGroup>

            {this.props.values.outsideImg ? (
              <FormGroup>
                <img
                  className={'m--img-rounded m--marginless'}
                  style={{ width: '150px', height: '100px' }}
                  src={`${AMAZON_CLOUD_FRONT_URL}/meals/${this.props.values.outsideImg}`}
                />
              </FormGroup>
            ) : (
              ''
            )}
            <FormGroup>
              <Label for={'exampleFile'}>{'Images'}</Label>
              <Input
                type={'file'}
                name={'images'}
                onChange={this.props.handleUploadPhoto}
                accept={'image/jpg, image/jpeg ,image/png'}
                multiple
                style={{ color: 'transparent' }}
              />
              <FormText color={'muted'}>{'Please upload images'}</FormText>
            </FormGroup>

            {this.props.values.images.length ? (
              <Grid container style={{ marginTop: 5 }}>
                {this.props.values.images.map((image, index) => {
                  return (
                    <div
                      key={index}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                      <IconButton
                        onClick={() => this.props.deleteImage(image)}
                        style={{ marginRight: -10 }}>
                        <HighlightOffIcon fontSize={'small'} />
                      </IconButton>
                      <img
                        alt={image.name}
                        className={'m--img-rounded m--marginless'}
                        style={{ width: '100px', height: '70px' }}
                        src={image instanceof File ? URL.createObjectURL(image) : image}
                      />
                    </div>
                  );
                })}
              </Grid>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}
