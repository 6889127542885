import axiosLib from 'axios';
import { StatusCodes } from 'http-status-codes';
import { camelizeKeys } from 'humps';
import jwtDecode from 'jwt-decode';
import swal from 'sweetalert2';

import { store } from '../../store';
import { setCurrentUser, setCurrentUserToken } from '../../store/actions';
import { handleLogout } from '../../store/actions/auth/logout';
import { axiosLogger } from './axiosLogger';

const getBaseUrl = (config) => {
  let BASE_URL;
  const URLS = ['/login', '/revokeToken'];
  if (URLS.includes(config?.url)) {
    BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/dashboard/v3`;
  } else {
    BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/dashboard/admin/v1`;
  }

  return BASE_URL;
};
const singletonEnforcer = Symbol();

class Axios {
  static axiosInstance;

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot initialize Axios client single instance');
    }

    this.axiosClient = axiosLib.create({
      baseURL: getBaseUrl(),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      timeout: 30000,
    });

    this.setRequestInterceptor();
    this.setResponseInterceptor();
  }

  static get instance() {
    if (!this.axiosInstance) {
      this.axiosInstance = new Axios(singletonEnforcer);
    }

    return this.axiosInstance;
  }

  client() {
    return this.axiosClient;
  }

  setRequestInterceptor() {
    this.axiosClient.interceptors.request.use(
      (config) => {
        config.baseURL = getBaseUrl(config);
        axiosLogger.requestSuccess(config);
        return config;
      },
      (error) => {
        axiosLogger.requestFail(error);
        return Promise.reject(error);
      },
    );
  }

  setResponseInterceptor() {
    this.axiosClient.interceptors.response.use(
      (response) => {
        axiosLogger.responseSuccess(response);
        return response;
      },
      async (error) => {
        axiosLogger.responseFail(error);
        const originalRequest = error.config;
        const responseStatus = error?.response?.status;

        if (responseStatus === StatusCodes.UNAUTHORIZED && !originalRequest._retry) {
          originalRequest._retry = true;
          this.refreshToken().then((token) => {
            if (token) {
              delete originalRequest.headers;
              this.setHeaderToken(token);
              return this.axiosClient(originalRequest);
            }
          });
        }

        if (error.response?.status === 500) {
          swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            timer: 3000,
          });
        }

        return Promise.reject(error);
      },
    );
  }

  refreshToken() {
    return this.post('/auth/refresh')
      .then((response) => {
        if (response.status === 200) {
          const token = response.data?.token;
          const user = jwtDecode(token);
          user.token = token;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          store.dispatch(setCurrentUser(camelizeKeys(user)));
          store.dispatch(setCurrentUserToken(token));
          return token;
        }
      })
      .catch(function(error) {
        if (error.response.status === 401) {
          store.dispatch(handleLogout());
        }
        return 0;
      });
  }

  setHeaderToken(userToken) {
    // eslint-disable-next-line max-len
    const jwt = /^([A-Za-z0-9\-_~+]+[=]{0,2})\.([A-Za-z0-9\-_~+]+[=]{0,2})(?:\.([A-Za-z0-9\-_~+]+[=]{0,2}))?$/;

    if (jwt.test(userToken)) {
      this.axiosClient.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    }
  }

  resetHeaderToken() {
    delete this.axiosClient.defaults.headers.common.Authorization;
  }

  get(url, config = {}) {
    return this.axiosClient.get(url, config);
  }

  post(url, data, config = {}) {
    return this.axiosClient.post(url, data, config);
  }

  update(url, data, config = {}) {
    return this.axiosClient.put(url, data, config);
  }

  put(url, data, config = {}) {
    return this.axiosClient.put(url, data, config);
  }

  patch(url, data, config = {}) {
    return this.axiosClient.patch(url, data, config);
  }

  delete(url, config = {}) {
    return this.axiosClient.delete(url, config);
  }
}

export const axios = Axios.instance;
