import moment from 'moment';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { cityList, menuDuplicate, zoneList } from '../../../store/actions';

import 'react-day-picker/lib/style.css';

class DuplicateMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicateModal: false,
      duplicateShowLoader: true,
      duplicateShowBody: false,
      name: '',
      start_date: moment(new Date(this.props.startDate)).format('YYYY-MM-DD'),
      city_id: this.props.cityId,
      zone_id: this.props.zoneId,
      menu_id: this.props.menuId,
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      duplicateShowLoader: false,
      duplicateShowBody: true,
    });
    // list cities
    this.props.cityList();
    // list zones
    const zoneListParams = {
      city_id: this.props.cityId,
      is_active: 1,
      type: 'all',
    };
    this.props.zoneList(zoneListParams);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.duplicatedMenu && nextProps.duplicatedMenu.menu) {
      this.props.onMenuDuplicated();
      this.props.closeModal();
      this.setState({
        duplicateShowLoader: false,
        duplicateShowBody: true,
      });
      this.toggle();
      swal.fire({
        title: 'Success',
        type: 'success',
        text: 'Menu duplicated successfully!',
        timer: 3000,
      });
    }
  }

  toggle() {
    this.setState({
      duplicateModal: !this.state.duplicateModal,
    });
  }

  handleChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    this.setState({ [inputName]: inputValue });
  }

  handleCityChange(event) {
    const city_id = event.target.value;
    this.setState({ city_id });
    const zoneListParams = {
      city_id,
      is_active: 1,
      type: 'all',
    };
    this.props.zoneList(zoneListParams);
  }

  handleDateChange(date) {
    const dateValue = date === undefined ? '' : moment(date).format('Y-MM-DD');
    this.setState({ start_date: dateValue });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      duplicateShowLoader: true,
      duplicateShowBody: false,
    });

    const data = {
      name: this.state.name,
      start_date: this.state.start_date,
      zone_id: this.state.zone_id,
    };

    this.props.menuDuplicate(this.state.menu_id, data);
  }

  render() {
    return (
      <div>
        <Button
          color={'success'}
          onClick={this.toggle}
          className={'btn m-btn--pill btn-success m-btn m-btn--custom'}>
          {'Duplicate Menu'}
        </Button>
        <Modal size={'md'} isOpen={this.state.duplicateModal}>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader>{'Duplicate Menu'}</ModalHeader>
            <ModalBody>
              <div
                style={this.state.duplicateShowLoader ? {} : { display: 'none' }}
                className={'loader'}
              />
              <div
                style={this.state.duplicateShowBody ? {} : { display: 'none' }}
                className={'modal-body-content'}>
                <div className={'form-group m-form__group'}>
                  <label>{'Name'}</label>
                  <input
                    className={'form-control m-input'}
                    type={'text'}
                    name={'name'}
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div className={'form-group m-form__group'}>
                  <label>{'Cities'}</label>
                  <select
                    className={'form-control m-input'}
                    name={'city_id'}
                    value={this.state.city_id}
                    onChange={this.handleCityChange}
                    disabled>
                    <option key={'all'} value={''}>
                      {'Cities'}
                    </option>
                    {this.props.cities.map((city) => {
                      if (city.active) {
                        return (
                          <option key={city.id} value={city.id}>
                            {city.enName}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>

                <div className={'form-group m-form__group'}>
                  <label>{'Zones'}</label>
                  <select
                    className={'form-control m-input'}
                    name={'zone_id'}
                    value={this.state.zone_id}
                    onChange={this.handleChange}>
                    <option key={'all'} value={0}>
                      {'Choose Zone'}
                    </option>
                    {this.props.zones.map(function(zone, i) {
                      return (
                        <option key={i} value={zone.id}>
                          {zone.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={'form-group m-form__group'}>
                  <label>{'Start Date'}</label>
                  <div className={'input-group date'}>
                    <DayPickerInput
                      dayPickerProps={{ todayButton: 'Today' }}
                      onDayChange={this.handleDateChange}
                      placeholder={this.state.start_date}
                    />
                    <div className={'input-group-append'}>
                      <span className={'input-group-text'}>
                        <i className={'la la-calendar glyphicon-th'} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter style={this.state.duplicateShowBody ? {} : { display: 'none' }}>
              <input
                type={'submit'}
                value={'Submit'}
                color={'primary'}
                className={'btn btn-primary'}
              />
              <Button color={'danger'} onClick={this.toggle}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cities: state.cityList.data,
    zones: state.zoneList.data,
    duplicatedMenu: state.menuDuplicate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cityList() {
      dispatch(cityList());
    },
    zoneList(params) {
      dispatch(zoneList(params));
    },
    menuDuplicate(menuId, data) {
      dispatch(menuDuplicate(menuId, data));
    },
    closeModal() {
      dispatch({ type: 'CLOSE_MODAL' });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateMenu);
