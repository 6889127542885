import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data, foodCategories) => {
  dispatch({
    type: CONST.FOOD_CATEGORY_CREATE_SUCCESS,
    foodCategory: data.foodCategory,
    foodCategories: foodCategories,
  });
};

const onFailed = (dispatch, error, foodCategories) => {
  dispatch({ type: CONST.FOOD_CATEGORY_CREATE_ERROR, error, foodCategories: foodCategories });
};

const handleResponse = (dispatch, response, foodCategories) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data, foodCategories);
  } else {
    onFailed(dispatch, response.data, foodCategories);
  }
};

export const foodCategoryCreate = (data, foodCategories) => {
  return async (dispatch) => {
    dispatch({ type: CONST.FOOD_CATEGORY_CREATE_REQUEST });

    axios.post('/food-categories', data).then((response) => {
      handleResponse(dispatch, response, foodCategories);
    });
  };
};
