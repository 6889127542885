import moment from 'moment';
import React, { Component } from 'react';
import YearPicker from 'react-year-picker';

import { axios } from '../../services/axios';
import CreateHoliday from './create/index';
import Delete from './delete/delete';

export default class Holidays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
      year: moment(new Date()).format('YYYY'),
    };
    this.changeYear = this.changeYear.bind(this);
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  changeYear(year) {
    this.setState({ year }, () => {
      this.getData();
    });
  }
  getData() {
    const params = { year: this.state.year };

    axios.get('off-days', { params }).then((response) => {
      console.log(response.data);
      this.setState({
        days: response.data.data,
      });
    });
  }

  render() {
    const self = this;
    return (
      <div>
        <div className={'m-portlet m-portlet--full-height  m-portlet--unair'}>
          <div className={'m-portlet__head'}>
            <div className={'col-md-3'}>
              <h3>{'Holidays List'}</h3>
            </div>
            <div className={'col-md-4'}>
              <YearPicker onChange={this.changeYear} />
            </div>
            <div className={'m-portlet__head-tools'}>
              {JSON.parse(localStorage.getItem('user')) &&
                JSON.parse(localStorage.getItem('user')).user_permissions.includes(
                  'CREATE_HOLIDAYS',
                ) && (
                  <CreateHoliday onCreate={this.getData}>
                    <i className={'la la-plus-circle m--font-brand'} />
                  </CreateHoliday>
                )}
            </div>
          </div>
          <div className={'m-portlet__body'}>
            <div className={'tab-content'}>
              {/* begin::Widget */}
              <div className={'m-widget11'}>
                <div className={'table-responsive'}>
                  {/* begin::Table */}
                  <table className={'table table-striped m-table'}>
                    {/* begin::Thead */}
                    <thead>
                      <tr>
                        <td>{'From Day'}</td>
                        <td>{'To Day'}</td>
                        <td>{'Description'}</td>
                        <td>{'Arabic Description'}</td>
                        <td>{'Actions'}</td>
                      </tr>
                    </thead>
                    {/* end::Thead */}

                    {/* begin::Tbody */}
                    <tbody>
                      {this.state.days.map(function(day, i) {
                        return (
                          <tr key={i}>
                            <td>{day.start_date}</td>
                            <td>{day.end_date}</td>
                            <td>{day.description}</td>
                            <td>{day.description_ar}</td>
                            <td>
                              {JSON.parse(localStorage.getItem('user')) &&
                                JSON.parse(localStorage.getItem('user')).user_permissions.includes(
                                  'DELETE_HOLIDAYS',
                                ) && (
                                  <Delete
                                    id={day.id}
                                    name={day.description}
                                    onDelete={self.getData}>
                                    <i className={'la la-remove m--font-brand'} />
                                  </Delete>
                                )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {/* end::Tbody */}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
