import { gql } from 'apollo-boost';
import { camelizeKeys } from 'humps';

import { axios } from './axios';

export const getCities = () => {
  return axios.get('/cities').then((res) => camelizeKeys(res.data.data));
};

export const getCitiesQuery = gql`
  query Cities($input: FetchAllCitiesInput) {
    cities(input: $input) {
      data {
        id
        en_name
      }
    }
  }
`;
