import { createAction } from 'redux-actions';

import { axios } from '../../../services/axios';
import { HANDLE_LOGOUT } from '../../constants';

export const handleLogout = () => {
  return (dispatch) => {
    axios.post('/auth/logout').finally(() => {
      window.location.href = '/admin/login';
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      dispatch(logout());
    });
  };
};

export const logout = createAction(HANDLE_LOGOUT);
