import React from 'react';

import { ZohoConfigurationSubForm } from './ZohoConfigurationSubForm';
import { AMAZON_CLOUD_FRONT_URL } from '../../../constants/meals';
import { FormGroup, Input, Label } from 'reactstrap';

export default class MerchantForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isCreateForm = this.props.formType === 'create';

    return (
      <form
        className={'m-form m-form--fit m-form--label-align-right'}
        onSubmit={this.props.handleSubmit}>
        <div className={'m-portlet__body'}>
          <div className={'form-group m-form__group'}>
            <label>{'Merchant Name'}</label>
            <input
              type={'text'}
              name={'restaurant_name'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.restaurant_name}
              placeholder={'Mercahnt Name'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.restaurant_name != ''
                ? this.props.values.validation.restaurant_name
                : ' '}
            </p>
          </div>

          <div className={'form-group m-form__group'}>
            <label>{'Merchant Phone'}</label>
            <input
              type={'text'}
              name={'restaurant_phone'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.restaurant_phone}
              placeholder={'Merchnat Phone'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.restaurant_phone != ''
                ? this.props.values.validation.restaurant_phone
                : ''}
            </p>
          </div>

          <div className={'form-group m-form__group'}>
            <label>{'Contact Name'}</label>
            <input
              type={'text'}
              name={'contact_name'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.contact_name}
              placeholder={'Contact Name'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.contact_name != ''
                ? this.props.values.validation.contact_name
                : ' '}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Contact Phone'}</label>
            <input
              type={'text'}
              name={'contact_phone'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.contact_phone}
              placeholder={'Contact Phone'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.contact_phone != ''
                ? this.props.values.validation.contact_phone
                : ''}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Secondary Contact Phone'}</label>
            <input
              type={'text'}
              name={'secondary_contact_phone'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.secondary_contact_phone}
              placeholder={'Secondary Contact Phone'}
              onChange={this.props.handleChange}
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.secondary_contact_phone != ''
                ? this.props.values.validation.secondary_contact_phone
                : ''}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Contact Email'}</label>
            <input
              type={'email'}
              name={'contact_email'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.contact_email}
              placeholder={'Email'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.contact_email != ''
                ? this.props.values.validation.contact_email
                : ' '}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Country'}</label>
            <select
              name={'country_id'}
              value={this.props.values.country_id}
              className={'form-control m-input m-input--air m-input--pill'}
              onChange={this.props.handleChangeCountry}
              required>
              <option value={''}>{'Select Country'}</option>
              {this.props.values.countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name_en}
                </option>
              ))}
            </select>
            <p style={{ color: 'red' }}>
              {this.props.values.validation.country_id != ''
                ? this.props.values.validation.country_id
                : ' '}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Cities'}</label>
            <select
              name={'city_id'}
              value={this.props.values.city_id}
              className={'form-control m-input m-input--air m-input--pill'}
              onChange={this.props.handleChange}>
              <option value={''}>{'Select City'}</option>
              {this.props.values.cities.length > 0 &&
                this.props.values.cities.map((city) => {
                  if (city.active) {
                    return (
                      <option key={city.en_name} value={city.id}>
                        {city.en_name}
                      </option>
                    );
                  }
                })}
            </select>
            <p style={{ color: 'red' }}>
              {this.props.values.validation.city_id != ''
                ? this.props.values.validation.city_id
                : ' '}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Street'}</label>
            <input
              type={'text'}
              name={'street'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.street}
              placeholder={'Street'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.street != '' ? this.props.values.validation.street : ''}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Longitude'}</label>
            <input
              type={'text'}
              name={'long'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.long}
              placeholder={'Longitude'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.long != '' ? this.props.values.validation.long : ''}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Latitude'}</label>
            <input
              type={'text'}
              name={'lat'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.lat}
              placeholder={'Latitude'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.lat != '' ? this.props.values.validation.lat : ''}
            </p>
          </div>

          {/* // add logo here */}
          <div className={'form-group m-form__group'}>
            <FormGroup check>
              <Label check>
                <Input
                  type={'checkbox'}
                  name={'has_labeling'}
                  checked={this.props.values.has_labeling ? 'checked' : ''}
                  onChange={this.props.handleChange}
                />{' '}
                {'Has Labeling'}
              </Label>
            </FormGroup>
          </div>
          <div className={'form-group m-form__group'}>
            <FormGroup check>
              <Label check>
                <Input
                  type={'checkbox'}
                  name={'is_catering_supported'}
                  checked={this.props.values.is_catering_supported ? 'checked' : ''}
                  onChange={this.props.handleChange}
                />
                {'Is catering supported?'}
              </Label>
            </FormGroup>
          </div>

          <div className={'form-group m-form__group'}>
            <label>{'Merchant Logo'}</label>
            <input
              type={'file'}
              name={'merchantLogo'}
              className={'form-control m-input m-input--air m-input--pill'}
              defaultValue={this.props.values.merchantLogo}
              onChange={(e) => this.props.handleUploadFile(e, 'merchantLogo')}
            />
          </div>
          {this.props.values.merchantLogo ? (
            <div className={'form-group m-form__group'}>
              <img
                className={'m--img-rounded m--marginless'}
                style={{ width: '150px', height: '100px' }}
                src={`${AMAZON_CLOUD_FRONT_URL}/merchants/${this.props.values.merchantLogo}`}
              />
            </div>
          ) : (
            ''
          )}
          <div className={'form-group m-form__group'}>
            <label>{'Merchant Contract'}</label>
            <input
              required={isCreateForm}
              type={'file'}
              name={'merchantContract'}
              accept={'.doc,.docx,.pdf'}
              className={'form-control m-input m-input--air m-input--pill'}
              onChange={(e) => this.props.handleUploadFile(e, 'contract')}
            />
          </div>
          <div className={'form-group m-form__group'}>
            <label>{'Merchant capacity'}</label>
            <input
              type={'number'}
              name={'capacity'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.capacity}
              placeholder={'Enter merchant capacity (Number)'}
              onChange={this.props.handleChange}
              required
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.capacity != ''
                ? this.props.values.validation.capacity
                : ''}
            </p>
          </div>
          <div className={'form-group m-form__group'}>
            {this.props.values.contract && !isCreateForm ? (
              <a
                className={'btn btn-success text-white'}
                href={this.props.values.contract}
                target={'_blank'}
                rel={'noopener noreferrer'}>
                {'Open Contract'}
              </a>
            ) : null}
          </div>
          {this.props.values.contract ? <div className={'form-group m-form__group'} /> : ''}
          <p style={{ color: 'red' }}>
            {this.props.values.validation.contract !== ''
              ? this.props.values.validation.contract
              : ''}
          </p>

          <div className={'form-group m-form__group'}>
            <label>{'User Name'}</label>
            <input
              type={'text'}
              name={'username'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.username}
              placeholder={'user name'}
              onChange={this.props.handleChange}
              required
              autoComplete={'off'}
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.username != ''
                ? this.props.values.validation.username
                : ''}
            </p>
          </div>

          <div className={'form-group m-form__group'}>
            <label>{'Password'}</label>
            <input
              type={'password'}
              name={'password'}
              className={'form-control m-input m-input--air m-input--pill'}
              value={this.props.values.password}
              onChange={this.props.handleChange}
              required={isCreateForm}
              autoComplete={'new-password'}
            />
            <p style={{ color: 'red' }}>
              {this.props.values.validation.password != ''
                ? this.props.values.validation.password
                : ''}
            </p>
          </div>
          {isCreateForm ? (
            <ZohoConfigurationSubForm
              formValues={this.props.values}
              handleChange={this.props.handleChange}
            />
          ) : null}
        </div>
        <div className={'m-portlet__foot m-portlet__foot--fit'}>
          <div className={'m-form__actions'}>
            <button className={'btn btn-primary'}>{'Submit'}</button>
          </div>
        </div>
      </form>
    );
  }
}
