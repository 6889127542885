export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SUBSCRIPTIONS_INDEX_REQUEST = 'SUBSCRIPTIONS_INDEX_REQUEST';
export const SUBSCRIPTIONS_INDEX_SUCCESS = 'SUBSCRIPTIONS_INDEX_SUCCESS';
export const SUBSCRIPTIONS_INDEX_ERROR = 'SUBSCRIPTIONS_INDEX_ERROR';

export const SUBSCRIPTIONS_SHOW_REQUEST = 'SUBSCRIPTIONS_SHOW_REQUEST';
export const SUBSCRIPTIONS_SHOW_SUCCESS = 'SUBSCRIPTIONS_SHOW_SUCCESS';
export const SUBSCRIPTIONS_SHOW_ERROR = 'SUBSCRIPTIONS_SHOW_ERROR';

export const SUBSCRIPTION_EDIT_REQUEST = 'SUBSCRIPTION_EDIT_REQUEST';
export const SUBSCRIPTION_EDIT_SUCCESS = 'SUBSCRIPTION_EDIT_SUCCESS';
export const SUBSCRIPTION_EDIT_ERROR = 'SUBSCRIPTION_EDIT_ERROR';

export const SUBSCRIPTION_EDIT_ADDRESS_REQUEST = 'SUBSCRIPTION_EDIT_ADDRESS_REQUEST';
export const SUBSCRIPTION_EDIT_ADDRESS_SUCCESS = 'SUBSCRIPTION_EDIT_ADDRESS_SUCCESS';
export const SUBSCRIPTION_EDIT_ADDRESS_ERROR = 'SUBSCRIPTION_EDIT_ADDRESS_ERROR';

export const SUBSCRIPTION_ORDER_EDIT_REQUEST = 'SUBSCRIPTION_ORDER_EDIT_REQUEST';
export const SUBSCRIPTION_ORDER_EDIT_SUCCESS = 'SUBSCRIPTION_ORDER_EDIT_SUCCESS';
export const SUBSCRIPTION_ORDER_EDIT_ERROR = 'SUBSCRIPTION_ORDER_EDIT_ERROR';

export const SUBSCRIPTION_ORDER_CREATE_REQUEST = 'SUBSCRIPTION_ORDER_CREATE_REQUEST';
export const SUBSCRIPTION_ORDER_CREATE_SUCCESS = 'SUBSCRIPTION_ORDER_CREATE_SUCCESS';
export const SUBSCRIPTION_ORDER_CREATE_ERROR = 'SUBSCRIPTION_ORDER_CREATE_ERROR';

export const SUBSCRIPTION_CANCEL_REASON_LIST_REQUEST = 'SUBSCRIPTION_CANCEL_REASON_LIST_REQUEST';
export const SUBSCRIPTION_CANCEL_REASON_LIST_SUCCESS = 'SUBSCRIPTION_CANCEL_REASON_LIST_SUCCESS';
export const SUBSCRIPTION_CANCEL_REASON_LIST_ERROR = 'SUBSCRIPTION_CANCEL_REASON_LIST_ERROR';

export const SUBSCRIPTION_CANCEL_REASON_CREATE_REQUEST =
  'SUBSCRIPTION_CANCEL_REASON_CREATE_REQUEST';
export const SUBSCRIPTION_CANCEL_REASON_CREATE_SUCCESS =
  'SUBSCRIPTION_CANCEL_REASON_CREATE_SUCCESS';
export const SUBSCRIPTION_CANCEL_REASON_CREATE_ERROR = 'SUBSCRIPTION_CANCEL_REASON_CREATE_ERROR';

export const SUBSCRIPTION_START_DATE_CHANGE_REQUEST = 'SUBSCRIPTION_START_DATE_CHANGE_REQUEST';
export const SUBSCRIPTION_START_DATE_CHANGE_SUCCESS = 'SUBSCRIPTION_START_DATE_CHANGE_SUCCESS';
export const SUBSCRIPTION_START_DATE_CHANGE_ERROR = 'SUBSCRIPTION_START_DATE_CHANGE_ERROR';

export const MERCHANT_LIST_REQUEST = 'MERCHANT_LIST_REQUEST';
export const MERCHANT_LIST_SUCCESS = 'MERCHANT_LIST_SUCCESS';
export const MERCHANT_LIST_ERROR = 'MERCHANT_LIST_ERROR';

export const MERCHANT_MEAL_LIST_REQUEST = 'MERCHANT_MEAL_LIST_REQUEST';
export const MERCHANT_MEAL_LIST_SUCCESS = 'MERCHANT_MEAL_LIST_SUCCESS';
export const MERCHANT_MEAL_LIST_ERROR = 'MERCHANT_MEAL_LIST_ERROR';

export const CLIENT_ADDRESS_LIST_REQUEST = 'CLIENT_ADDRESS_LIST_REQUEST';
export const CLIENT_ADDRESS_LIST_SUCCESS = 'CLIENT_ADDRESS_LIST_SUCCESS';
export const CLIENT_ADDRESS_LIST_ERROR = 'CLIENT_ADDRESS_LIST_ERROR';

export const PAYMENT_LIST_CASH_REQUEST = 'PAYMENT_LIST_CASH_REQUEST';
export const PAYMENT_LIST_CASH_SUCCESS = 'PAYMENT_LIST_CASH_SUCCESS';
export const PAYMENT_LIST_CASH_ERROR = 'PAYMENT_LIST_CASH_ERROR';

export const PAYMENT_LIST_ONLINE_REQUEST = 'PAYMENT_LIST_ONLINE_REQUEST';
export const PAYMENT_LIST_ONLINE_SUCCESS = 'PAYMENT_LIST_ONLINE_SUCCESS';
export const PAYMENT_LIST_ONLINE_ERROR = 'PAYMENT_LIST_ONLINE_ERROR';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_TOKEN = 'SET_CURRENT_USER_TOKEN';
export const HANDLE_LOGOUT = 'HANDLE_LOGOUT';

export const DRIVER_LIST_ALL_REQUEST = 'DRIVER_LIST_ALL_REQUEST';
export const DRIVER_LIST_ALL_SUCCESS = 'DRIVER_LIST_ALL_SUCCESS';
export const DRIVER_LIST_ALL_ERROR = 'DRIVER_LIST_ALL_ERROR';

export const CITY_LIST_REQUEST = 'CITY_LIST_REQUEST';
export const CITY_LIST_SUCCESS = 'CITY_LIST_SUCCESS';
export const CITY_LIST_ERROR = 'CITY_LIST_ERROR';

export const MAP_ORDERS_LIST_REQUEST = 'MAP_ORDERS_LIST_REQUEST';
export const MAP_ORDERS_LIST_SUCCESS = 'MAP_ORDERS_LIST_SUCCESS';
export const MAP_ORDERS_LIST_ERROR = 'MAP_ORDERS_LIST_ERROR';
export const MAP_ORDERS_LIST_UPDATE = 'MAP_ORDERS_LIST_UPDATE';

export const MAP_DRIVERS_LIST_REQUEST = 'MAP_DRIVERS_LIST_REQUEST';
export const MAP_DRIVERS_LIST_SUCCESS = 'MAP_DRIVERS_LIST_SUCCESS';
export const MAP_DRIVERS_LIST_ERROR = 'MAP_DRIVERS_LIST_ERROR';
export const MAP_DRIVERS_LIST_UPDATE = 'MAP_DRIVERS_LIST_UPDATE';
export const MAP_DRIVERS_SELECTED = 'MAP_DRIVERS_SELECTED';
export const MAP_DRIVERS_ORDERS_SELECTED = 'MAP_DRIVERS_ORDERS_SELECTED';

export const MAP_ZOOM_TO = 'MAP_ZOOM_TO';
export const MAP_ROUTING = 'MAP_ROUTING';
export const SET_FILTER_DATE = 'SET_FILTER_DATE';
export const RESET_FILTER_DATA = 'RESET_FILTER_DATA';
export const UPDATE_FILTER_DATA = 'UPDATE_FILTER_DATA';
export const SET_DEFAULT_FILTER_DATA = 'SET_DEFAULT_FILTER_DATA';

export const MAP_ASSIGN_ORDER = 'MAP_ASSIGN_ORDER';
export const MAP_ASSIGN_ORDERS = 'MAP_ASSIGN_ORDERS';
export const RESET_CLIENT_IDS = 'RESET_CLIENT_IDS';
export const MAP_SWITCH_ORDERS = 'MAP_SWITCH_ORDERS';

export const MAP_DRIVER_ORDERS_LIST_REQUEST = 'MAP_DRIVER_ORDERS_LIST_REQUEST';
export const MAP_DRIVER_ORDERS_LIST_SUCCESS = 'MAP_DRIVER_ORDERS_LIST_SUCCESS';
export const MAP_DRIVER_ORDERS_LIST_ERROR = 'MAP_DRIVER_ORDERS_LIST_ERROR';
export const MAP_DRIVER_ORDERS_LIST_UPDATE = 'MAP_DRIVER_ORDERS_LIST_UPDATE';

export const PAYMENT_LIST_REQUEST = 'PAYMENT_LIST_REQUEST';
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS';
export const PAYMENT_LIST_ERROR = 'PAYMENT_LIST_ERROR';

export const RENEWAL_LIST_REQUEST = 'RENEWAL_LIST_REQUEST';
export const RENEWAL_LIST_SUCCESS = 'RENEWAL_LIST_SUCCESS';
export const RENEWAL_LIST_ERROR = 'RENEWAL_LIST_ERROR';

export const RENEWAL_INTERCOM_NOTIFICATION_REQUEST = 'RENEWAL_INTERCOM_NOTIFICATION_REQUEST';
export const RENEWAL_INTERCOM_NOTIFICATION_SUCCESS = 'RENEWAL_INTERCOM_NOTIFICATION_SUCCESS';
export const RENEWAL_INTERCOM_NOTIFICATION_ERROR = 'RENEWAL_INTERCOM_NOTIFICATION_ERROR';

export const CUSTOMER_ORDERS_REPORT_REQUEST = 'CUSTOMER_ORDERS_REPORT_REQUEST';
export const CUSTOMER_ORDERS_REPORT_SUCCESS = 'CUSTOMER_ORDERS_REPORT_SUCCESS';
export const CUSTOMER_ORDERS_REPORT_ERROR = 'CUSTOMER_ORDERS_REPORT_ERROR';

export const PLAN_LIST_REQUEST = 'PLAN_LIST_REQUEST';
export const PLAN_LIST_SUCCESS = 'PLAN_LIST_SUCCESS';
export const PLAN_LIST_ERROR = 'PLAN_LIST_ERROR';

export const PLAN_SHOW_REQUEST = 'PLAN_SHOW_REQUEST';
export const PLAN_SHOW_SUCCESS = 'PLAN_SHOW_SUCCESS';
export const PLAN_SHOW_ERROR = 'PLAN_SHOW_ERROR';

export const PLAN_CREATE_REQUEST = 'PLAN_CREATE_REQUEST';
export const PLAN_CREATE_SUCCESS = 'PLAN_CREATE_SUCCESS';
export const PLAN_CREATE_ERROR = 'PLAN_CREATE_ERROR';

export const PLAN_EDIT_REQUEST = 'PLAN_EDIT_REQUEST';
export const PLAN_EDIT_SUCCESS = 'PLAN_EDIT_SUCCESS';
export const PLAN_EDIT_ERROR = 'PLAN_EDIT_ERROR';

export const PLAN_DUPLICATE_REQUEST = 'PLAN_DUPLICATE_REQUEST';
export const PLAN_DUPLICATE_SUCCESS = 'PLAN_DUPLICATE_SUCCESS';
export const PLAN_DUPLICATE_ERROR = 'PLAN_DUPLICATE_ERROR';

export const PLAN_DELIVERY_TIMES_REQUEST = 'PLAN_DELIVERY_TIMES_REQUEST';
export const PLAN_DELIVERY_TIMES_SUCCESS = 'PLAN_DELIVERY_TIMES_SUCCESS';
export const PLAN_DELIVERY_TIMES_ERROR = 'PLAN_DELIVERY_TIMES_ERROR';

export const ACTIVE_ORDERS_LIST_REQUEST = 'ACTIVE_ORDERS_LIST_REQUEST';
export const ACTIVE_ORDERS_LIST_SUCCESS = 'ACTIVE_ORDERS_LIST_SUCCESS';
export const ACTIVE_ORDERS_LIST_ERROR = 'ACTIVE_ORDERS_LIST_ERROR';

export const CATEGORIES_LIST_REQUEST = 'CATEGORIES_LIST_REQUEST';
export const CATEGORIES_LIST_SUCCESS = 'CATEGORIES_LIST_SUCCESS';
export const CATEGORIES_LIST_ERROR = 'CATEGORIES_LIST_ERROR';

export const SUB_CATEGORIES_LIST_REQUEST = 'SUB_CATEGORIES_LIST_REQUEST';
export const SUB_CATEGORIES_LIST_SUCCESS = 'SUB_CATEGORIES_LIST_SUCCESS';
export const SUB_CATEGORIES_LIST_ERROR = 'SUB_CATEGORIES_LIST_ERROR';

export const ACTIONS_LIST_REQUEST = 'ACTIONS_LIST_REQUEST';
export const ACTIONS_LIST_SUCCESS = 'ACTIONS_LIST_SUCCESS';
export const ACTIONS_LIST_ERROR = 'ACTIONS_LIST_ERROR';

export const STATUS_LIST_REQUEST = 'STATUS_LIST_REQUEST';
export const STATUS_LIST_SUCCESS = 'STATUS_LIST_SUCCESS';
export const STATUS_LIST_ERROR = 'STATUS_LIST_ERROR';

export const ASSIGNERS_LIST_REQUEST = 'ASSIGNERS_LIST_REQUEST';
export const ASSIGNERS_LIST_SUCCESS = 'ASSIGNERS_LIST_SUCCESS';
export const ASSIGNERS_LIST_ERROR = 'ASSIGNERS_LIST_ERROR';

export const COMPLAINT_CREATE_REQUEST = 'COMPLAINT_CREATE_REQUEST';
export const COMPLAINT_CREATE_SUCCESS = 'COMPLAINT_CREATE_SUCCESS';
export const COMPLAINT_CREATE_ERROR = 'COMPLAINT_CREATE_ERROR';

export const ADMIN_ASSIGNED_COMPLAINTS_LIST_REQUEST = 'ADMIN_ASSIGNED_COMPLAINTS_LIST_REQUEST';
export const ADMIN_ASSIGNED_COMPLAINTS_LIST_SUCCESS = 'ADMIN_ASSIGNED_COMPLAINTS_LIST_SUCCESS';
export const ADMIN_ASSIGNED_COMPLAINTS_LIST_ERROR = 'ADMIN_ASSIGNED_COMPLAINTS_LIST_ERROR';

export const CLIENT_COMPLAINTS_LIST_REQUEST = 'CLIENT_COMPLAINTS_LIST_REQUEST';
export const CLIENT_COMPLAINTS_LIST_SUCCESS = 'CLIENT_COMPLAINTS_LIST_SUCCESS';
export const CLIENT_COMPLAINTS_LIST_ERROR = 'CLIENT_COMPLAINTS_LIST_ERROR';

export const COMPLAINTS_LIST_REQUEST = 'COMPLAINTS_LIST_REQUEST';
export const COMPLAINTS_LIST_SUCCESS = 'COMPLAINTS_LIST_SUCCESS';
export const COMPLAINTS_LIST_ERROR = 'COMPLAINTS_LIST_ERROR';

export const CATEGORIES_TYPES_LIST_REQUEST = 'CATEGORIES_TYPES_LIST_REQUEST';
export const CATEGORIES_TYPES_LIST_SUCCESS = 'CATEGORIES_TYPES_LIST_SUCCESS';
export const CATEGORIES_TYPES_LIST_ERROR = 'CATEGORIES_TYPES_LIST_ERROR';

export const COMPLAINT_SHOW_REQUEST = 'COMPLAINT_SHOW_REQUEST';
export const COMPLAINT_SHOW_SUCCESS = 'COMPLAINT_SHOW_SUCCESS';
export const COMPLAINT_SHOW_ERROR = 'COMPLAINT_SHOW_ERROR';

export const COMPLAINT_COMMENT_CREATE_REQUEST = 'COMPLAINT_COMMENT_CREATE_REQUEST';
export const COMPLAINT_COMMENT_CREATE_SUCCESS = 'COMPLAINT_COMMENT_CREATE_SUCCESS';
export const COMPLAINT_COMMENT_CREATE_ERROR = 'COMPLAINT_COMMENT_CREATE_ERROR';

export const COMPLAINT_EDIT_REQUEST = 'COMPLAINT_EDIT_REQUEST';
export const COMPLAINT_EDIT_SUCCESS = 'COMPLAINT_EDIT_SUCCESS';
export const COMPLAINT_EDIT_ERROR = 'COMPLAINT_EDIT_ERROR';

export const COMPLAINT_ACTION_CREATE_REQUEST = 'COMPLAINT_ACTION_CREATE_REQUEST';
export const COMPLAINT_ACTION_CREATE_SUCCESS = 'COMPLAINT_ACTION_CREATE_SUCCESS';
export const COMPLAINT_ACTION_CREATE_ERROR = 'COMPLAINT_ACTION_CREATE_ERROR';

export const PAYMENT_COLLECTION_REPORT_REQUEST = 'PAYMENT_COLLECTION_REPORT_REQUEST';
export const PAYMENT_COLLECTION_REPORT_SUCCESS = 'PAYMENT_COLLECTION_REPORT_SUCCESS';
export const PAYMENT_COLLECTION_REPORT_ERROR = 'PAYMENT_COLLECTION_REPORT_ERROR';

export const PLAN_DATE_MEALS_LIST_REQUEST = 'PLAN_DATE_MEALS_LIST_REQUEST';
export const PLAN_DATE_MEALS_LIST_SUCCESS = 'PLAN_DATE_MEALS_LIST_SUCCESS';
export const PLAN_DATE_MEALS_LIST_ERROR = 'PLAN_DATE_MEALS_LIST_ERROR';

export const VOUCHER_LIST_REQUEST = 'VOUCHER_LIST_REQUEST';
export const VOUCHER_LIST_SUCCESS = 'VOUCHER_LIST_SUCCESS';
export const VOUCHER_LIST_ERROR = 'VOUCHER_LIST_ERROR';

export const VOUCHER_CREATE_REQUEST = 'VOUCHER_CREATE_REQUEST';
export const VOUCHER_CREATE_SUCCESS = 'VOUCHER_CREATE_SUCCESS';
export const VOUCHER_CREATE_ERROR = 'VOUCHER_CREATE_ERROR';

export const VOUCHER_EDIT_REQUEST = 'VOUCHER_EDIT_REQUEST';
export const VOUCHER_EDIT_SUCCESS = 'VOUCHER_EDIT_SUCCESS';
export const VOUCHER_EDIT_ERROR = 'VOUCHER_EDIT_ERROR';

export const VOUCHER_SHOW_REQUEST = 'VOUCHER_SHOW_REQUEST';
export const VOUCHER_SHOW_SUCCESS = 'VOUCHER_SHOW_SUCCESS';
export const VOUCHER_SHOW_ERROR = 'VOUCHER_SHOW_ERROR';

export const NOT_AVAILABLE_MEAL_ORDER_REPORT_REQUEST = 'NOT_AVAILABLE_MEAL_ORDER_REPORT_REQUEST';
export const NOT_AVAILABLE_MEAL_ORDER_REPORT_SUCCESS = 'NOT_AVAILABLE_MEAL_ORDER_REPORT_SUCCESS';
export const NOT_AVAILABLE_MEAL_ORDER_REPORT_ERROR = 'NOT_AVAILABLE_MEAL_ORDER_REPORT_ERROR';

export const MEALZ_DRIVER_PICK_UP_REPORT_REQUEST = 'MEALZ_DRIVER_PICK_UP_REPORT_REQUEST';
export const MEALZ_DRIVER_PICK_UP_REPORT_SUCCESS = 'MEALZ_DRIVER_PICK_UP_REPORT_SUCCESS';
export const MEALZ_DRIVER_PICK_UP_REPORT_ERROR = 'MEALZ_DRIVER_PICK_UP_REPORT_ERROR';

export const ACTION_CREATE_REQUEST = 'ACTION_CREATE_REQUEST';
export const ACTION_CREATE_SUCCESS = 'ACTION_CREATE_SUCCESS';
export const ACTION_CREATE_ERROR = 'ACTION_CREATE_ERROR';

export const ACTION_DELETE_REQUEST = 'ACTION_DELETE_REQUEST';
export const ACTION_DELETE_SUCCESS = 'ACTION_DELETE_SUCCESS';
export const ACTION_DELETE_ERROR = 'ACTION_DELETE_ERROR';

export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST';
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS';
export const CATEGORY_CREATE_ERROR = 'CATEGORY_CREATE_ERROR';

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_ERROR = 'CATEGORY_DELETE_ERROR';

export const CATEGORY_TYPE_DELETE_REQUEST = 'CATEGORY_TYPE_DELETE_REQUEST';
export const CATEGORY_TYPE_DELETE_SUCCESS = 'CATEGORY_TYPE_DELETE_SUCCESS';
export const CATEGORY_TYPE_DELETE_ERROR = 'CATEGORY_TYPE_DELETE_ERROR';

export const CATEGORY_TYPE_CREATE_REQUEST = 'CATEGORY_TYPE_CREATE_REQUEST';
export const CATEGORY_TYPE_CREATE_SUCCESS = 'CATEGORY_TYPE_CREATE_SUCCESS';
export const CATEGORY_TYPE_CREATE_ERROR = 'CATEGORY_TYPE_CREATE_ERROR';

export const STATUS_CREATE_REQUEST = 'STATUS_CREATE_REQUEST';
export const STATUS_CREATE_SUCCESS = 'STATUS_CREATE_SUCCESS';
export const STATUS_CREATE_ERROR = 'STATUS_CREATE_ERROR';

export const STATUS_DELETE_REQUEST = 'STATUS_DELETE_REQUEST';
export const STATUS_DELETE_SUCCESS = 'STATUS_DELETE_SUCCESS';
export const STATUS_DELETE_ERROR = 'STATUS_DELETE_ERROR';

export const ZONE_LIST_REQUEST = 'ZONE_LIST_REQUEST';
export const ZONE_LIST_SUCCESS = 'ZONE_LIST_SUCCESS';
export const ZONE_LIST_ERROR = 'ZONE_LIST_ERROR';

export const ZONE_CREATE_REQUEST = 'ZONE_CREATE_REQUEST';
export const ZONE_CREATE_SUCCESS = 'ZONE_CREATE_SUCCESS';
export const ZONE_CREATE_ERROR = 'ZONE_CREATE_ERROR';

export const ZONE_SHOW_REQUEST = 'ZONE_SHOW_REQUEST';
export const ZONE_SHOW_SUCCESS = 'ZONE_SHOW_SUCCESS';
export const ZONE_SHOW_ERROR = 'ZONE_SHOW_ERROR';

export const ZONE_EDIT_REQUEST = 'ZONE_EDIT_REQUEST';
export const ZONE_EDIT_SUCCESS = 'ZONE_EDIT_SUCCESS';
export const ZONE_EDIT_ERROR = 'ZONE_EDIT_ERROR';

export const ZONE_DISCOUNT_LIST_REQUEST = 'ZONE_DISCOUNT_LIST_REQUEST';
export const ZONE_DISCOUNT_LIST_SUCCESS = 'ZONE_DISCOUNT_LIST_SUCCESS';
export const ZONE_DISCOUNT_LIST_ERROR = 'ZONE_DISCOUNT_LIST_ERROR';

export const ZONE_DISCOUNT_CREATE_REQUEST = 'ZONE_DISCOUNT_CREATE_REQUEST';
export const ZONE_DISCOUNT_CREATE_SUCCESS = 'ZONE_DISCOUNT_CREATE_SUCCESS';
export const ZONE_DISCOUNT_CREATE_ERROR = 'ZONE_DISCOUNT_CREATE_ERROR';

export const ZONE_DISCOUNT_SHOW_REQUEST = 'ZONE_DISCOUNT_SHOW_REQUEST';
export const ZONE_DISCOUNT_SHOW_SUCCESS = 'ZONE_DISCOUNT_SHOW_SUCCESS';
export const ZONE_DISCOUNT_SHOW_ERROR = 'ZONE_DISCOUNT_SHOW_ERROR';

export const ZONE_DISCOUNT_EDIT_REQUEST = 'ZONE_DISCOUNT_EDIT_REQUEST';
export const ZONE_DISCOUNT_EDIT_SUCCESS = 'ZONE_DISCOUNT_EDIT_SUCCESS';
export const ZONE_DISCOUNT_EDIT_ERROR = 'ZONE_DISCOUNT_EDIT_ERROR';

export const POLYGONS_LIST_REQUEST = 'POLYGONS_LIST_REQUEST';
export const POLYGONS_LIST_SUCCESS = 'POLYGONS_LIST_SUCCESS';
export const POLYGONS_LIST_ERROR = 'POLYGONS_LIST_ERROR';

export const ASSIGN_PLAN_MENU_REQUEST = 'ASSIGN_PLAN_MENU_REQUEST';
export const ASSIGN_PLAN_MENU_SUCCESS = 'ASSIGN_PLAN_MENU_SUCCESS';
export const ASSIGN_PLAN_MENU_ERROR = 'ASSIGN_PLAN_MENU_ERROR';

export const LIST_PLAN_ZONES_REQUEST = 'LIST_PLAN_ZONES_REQUEST';
export const LIST_PLAN_ZONES_SUCCESS = 'LIST_PLAN_ZONES_SUCCESS';
export const LIST_PLAN_ZONES_ERROR = 'LIST_PLAN_ZONES_ERROR';

export const MENU_LIST_REQUEST = 'MENU_LIST_REQUEST';
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS';
export const MENU_LIST_ERROR = 'MENU_LIST_ERROR';

export const MENU_EDIT_REQUEST = 'MENU_EDIT_REQUEST';
export const MENU_EDIT_SUCCESS = 'MENU_EDIT_SUCCESS';
export const MENU_EDIT_ERROR = 'MENU_EDIT_ERROR';

export const MENU_DUPLICATE_REQUEST = 'MENU_DUPLICATE_REQUEST';
export const MENU_DUPLICATE_SUCCESS = 'MENU_DUPLICATE_SUCCESS';
export const MENU_DUPLICATE_ERROR = 'MENU_DUPLICATE_ERROR';

export const MYF_CREATE_REQUEST = 'MYF_CREATE_REQUEST';
export const MYF_CREATE_SUCCESS = 'MYF_CREATE_SUCCESS';
export const MYF_CREATE_ERROR = 'MYF_CREATE_ERROR';

export const TICKET_COMMETS_LIST_REQUEST = 'TICKET_COMMETS_LIST_REQUEST';
export const TICKET_COMMETS_LIST_SUCCESS = 'TICKET_COMMETS_LIST_SUCCESS';
export const TICKET_COMMETS_LIST_ERROR = 'TICKET_COMMETS_LIST_ERROR';

export const CLIENT_INTERCOM_NOTIFICATION_CREATE_REQUEST =
  'CLIENT_INTERCOM_NOTIFICATION_CREATE_REQUEST';
export const CLIENT_INTERCOM_NOTIFICATION_CREATE_SUCCESS =
  'CLIENT_INTERCOM_NOTIFICATION_CREATE_SUCCESS';
export const CLIENT_INTERCOM_NOTIFICATION_CREATE_ERROR =
  'CLIENT_INTERCOM_NOTIFICATION_CREATE_ERROR';

export const FOOD_CATEGORY_LIST_REQUEST = 'FOOD_CATEGORY_LIST_REQUEST';
export const FOOD_CATEGORY_LIST_SUCCESS = 'FOOD_CATEGORY_LIST_SUCCESS';
export const FOOD_CATEGORY_LIST_ERROR = 'FOOD_CATEGORY_LIST_ERROR';

export const FOOD_CATEGORY_CREATE_REQUEST = 'FOOD_CATEGORY_CREATE_REQUEST';
export const FOOD_CATEGORY_CREATE_SUCCESS = 'FOOD_CATEGORY_CREATE_SUCCESS';
export const FOOD_CATEGORY_CREATE_ERROR = 'FOOD_CATEGORY_CREATE_ERROR';

export const FOOD_CATEGORY_SHOW_REQUEST = 'FOOD_CATEGORY_SHOW_REQUEST';
export const FOOD_CATEGORY_SHOW_SUCCESS = 'FOOD_CATEGORY_SHOW_SUCCESS';
export const FOOD_CATEGORY_SHOW_ERROR = 'FOOD_CATEGORY_SHOW_ERROR';

export const FOOD_CATEGORY_EDIT_REQUEST = 'FOOD_CATEGORY_EDIT_REQUEST';
export const FOOD_CATEGORY_EDIT_SUCCESS = 'FOOD_CATEGORY_EDIT_SUCCESS';
export const FOOD_CATEGORY_EDIT_ERROR = 'FOOD_CATEGORY_EDIT_ERROR';
export const FOOD_CATEGORY_LIST_UPDATE = 'FOOD_CATEGORY_LIST_UPDATE';

export const PRODCUCT_TYPES_LIST = 'PRODCUCT_TYPES_LIST';

export const MEALS_LIST_REQUEST = 'MEALS_LIST_REQUEST';
export const MEALS_LIST_SUCCESS = 'MEALS_LIST_SUCCESS';
export const MEALS_LIST_ERROR = 'MEALS_LIST_ERROR';

export const MEALS_VALIDATE_AFFECTED_ORDERS_REQUEST = 'MEALS_VALIDATE_AFFECTED_ORDERS_REQUEST';
export const MEALS_VALIDATE_AFFECTED_ORDERS_MODAL_TOGGLE =
  'MEALS_VALIDATE_AFFECTED_ORDERS_MODAL_TOGGLE';
export const MEALS_VALIDATE_AFFECTED_ORDERS_SET_MODAL_PAYLOAD =
  'MEALS_VALIDATE_AFFECTED_ORDERS_SET_MODAL_PAYLOAD';

export const PICK_UP_PREFERENCE_LIST_REQUEST = 'PICK_UP_PREFERENCE_LIST_REQUEST';
export const PICK_UP_PREFERENCE_LIST_SUCCESS = 'PICK_UP_PREFERENCE_LIST_SUCCESS';
export const PICK_UP_PREFERENCE_LIST_ERROR = 'PICK_UP_PREFERENCE_LIST_ERROR';

export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST';
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS';
export const COMPANY_CREATE_ERROR = 'COMPANY_CREATE_ERROR';

export const COMPANY_EDIT_REQUEST = 'COMPANY_EDIT_REQUEST';
export const COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS';
export const COMPANY_EDIT_ERROR = 'COMPANY_EDIT_ERROR';

export const TEAM_CREATE_REQUEST = 'TEAM_CREATE_REQUEST';
export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS';
export const TEAM_CREATE_ERROR = 'TEAM_CREATE_ERROR';

export const TEAM_EDIT_SUCCESS = 'TEAM_EDIT_SUCCESS';
export const TEAM_EDIT_ERROR = 'TEAM_EDIT_ERROR';
export const TEAM_EDIT_REQUEST = 'TEAM_EDIT_REQUEST';

export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST';
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS';
export const TEAM_LIST_ERROR = 'TEAM_LIST_ERROR';

export const GROUPS_LIST_SUCCESS = 'GROUPS_LIST_SUCCESS';
export const GROUPS_LIST_ERROR = 'GROUPS_LIST_ERROR';
export const GROUPS_LIST_REQUEST = 'GROUPS_LIST_REQUEST';

export const GROUP_CREATE_SUCCESS = 'GROUPS_CREATE_SUCCESS';
export const GROUP_CREATE_ERROR = 'GROUPS_CREATE_ERROR';
export const GROUP_CREATE_REQUEST = 'GROUPS_CREATE_REQUEST';

export const GROUP_EDIT_SUCCESS = 'GROUPS_EDIT_SUCCESS';
export const GROUP_EDIT_ERROR = 'GROUPS_EDIT_ERROR';
export const GROUP_EDIT_REQUEST = 'GROUPS_EDIT_REQUEST';

export const PLAN_QUICK_FILTERS_LOADING = 'PLAN_QUICK_FILTERS_LOADING';
export const PLAN_QUICK_FILTERS_LIST_REQUEST = 'PLAN_QUICK_FILTERS_LIST_REQUEST';
export const PLAN_QUICK_FILTERS_CREATE_REQUEST = 'PLAN_QUICK_FILTERS_CREATE_REQUEST';
export const PLAN_QUICK_FILTERS_UPDATE_REQUEST = 'PLAN_QUICK_FILTERS_UPDATE_REQUEST';
