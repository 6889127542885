import { camelizeKeys } from 'humps';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Form, FormGroup } from 'reactstrap';

import { axios } from '../../../services/axios';
import { mealList } from '../../../store/actions';
import CreateMeal from '../create/create';

class ListMeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      total: 0,
      pageCount: 0,
      meals: [],
      merchants: [],
      allProductTypes: [],
      merchant_id: 0,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.listAllMerchants = this.listAllMerchants.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.title = 'Meals';
    this.props.mealList();
    this.listAllMerchants();
    this.listProductTypes();
  }

  parseProductTypes(productTypes) {
    return productTypes.map((productType) => ({
      ...productType,
      value: productType.id || productType.value,
      label: productType.enName,
    }));
  }

  listProductTypes = () => {
    axios
      .get('/product-types')
      .then((res) => camelizeKeys(res.data))
      .then((productTypes) => {
        this.setState({
          allProductTypes: this.parseProductTypes(productTypes),
        });
      });
  };

  listAllMerchants() {
    axios
      .get('/merchants')
      .then((response) => {
        this.setState({
          merchants: response.data.data,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handlePageChange(data) {
    const page = data.selected + 1;
    this.setState({ page: page }, () => {
      const params = this.getParams();
      this.props.mealList(params);
    });
  }

  handleChange({ value }) {
    this.setState({ merchant_id: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const params = this.getParams();
    this.props.mealList(params);
  }

  tabRow() {
    if (this.props.meals instanceof Array) {
      return this.props.meals.map(function(meal, i) {
        return (
          <tr key={i} id={`meal-${meal.en_name}`}>
            <td>{meal.item_id}</td>
            <td>{meal.sku}</td>
            <td>{meal.en_name}</td>
            <td>{(meal.merchant || {}).restaurant_name}</td>
            <td>{meal?.rating_info?.ratings_average}</td>
            <td>{meal?.cost}</td>
            <td>{meal?.status}</td>
            <td>
              <span>
                <Link to={`meals/${meal.item_id}`}>
                  <button
                    className={
                      'btn btn-lg btn-secondary m-btn m-btn--icon m-btn--icon-only m-btn--pill btn-success'
                    }>
                    <i className={'la la-eye m--font-brand'} />
                  </button>
                </Link>
              </span>
            </td>
          </tr>
        );
      });
    }
  }

  getParams() {
    const params = {};
    if (this.state.page != 1) {
      params.page = this.state.page;
    }
    if (this.state.merchant_id != 0) {
      params.merchant_id = this.state.merchant_id;
    }
    return params;
  }

  render() {
    const self = this;
    return (
      <div>
        <div className={'m-portlet m-portlet--full-height  m-portlet--unair'}>
          <div className={'m-portlet__head'} style={{ height: 'auto' }}>
            <form onSubmit={this.handleSubmit} className={'w-50'} style={{ maxWidth: '750px' }}>
              <div className={'row'}>
                <div className={'col-md-8'}>
                  <Select
                    name={'merchant_id'}
                    isSearchable
                    placeholder={'Choose Merchants'}
                    options={this.state?.merchants?.reduce(
                      (accumulator, merchant) => [
                        ...accumulator,
                        {
                          label: `${merchant.id} - ${merchant.restaurant_name}`,
                          value: merchant.id,
                        },
                      ],
                      [{ label: 'All Merchants', value: 0 }],
                    )}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={'col-md-4'}>
                  <button className={'btn-success btn'} style={{ width: '100%' }}>
                    {'Search'}
                  </button>
                </div>
              </div>
            </form>

            <div className={'m-portlet__head-tools'}>
              {JSON.parse(localStorage.getItem('user')) &&
                JSON.parse(localStorage.getItem('user')).user_permissions.includes(
                  'CREATE_MEALS',
                ) && (
                  <CreateMeal
                    merchants={self.state.merchants}
                    allProductTypes={this.state.allProductTypes}>
                    <i className={'la la-plus-circle m--font-brand'} />
                  </CreateMeal>
                )}
            </div>
          </div>
          <div className={'m-portlet__body'}>
            <div className={'tab-content'}>
              {/* begin::Widget */}
              <div className={'m-widget11'}>
                <div className={'table-responsive'}>
                  {/* begin::Table */}
                  <table className={'table table-striped m-table'}>
                    {/* begin::Thead */}
                    <thead>
                      <tr>
                        <th>{'#'}</th>
                        <th>{'SKU'}</th>
                        <th>{'Name'}</th>
                        <th>{'Merchant'}</th>
                        <th>{'Ratings Average'}</th>
                        <th>{'Cost'}</th>
                        <th>{'Status'}</th>
                        <th>{'Actions'}</th>
                      </tr>
                    </thead>
                    {/* end::Thead */}

                    {/* begin::Tbody */}
                    <tbody>
                      {this.props.loading ? (
                        <tr>
                          <td colSpan={9}>
                            <div className={'loader'} />
                          </td>
                        </tr>
                      ) : (
                        this.tabRow()
                      )}
                    </tbody>
                    {/* end::Tbody */}
                  </table>
                  {/* end::Table */}
                </div>

                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  containerClassName={
                    this.props.total == 0
                      ? 'pagination hidden'
                      : 'pagination justify-content-center'
                  }
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  pageCount={this.props.pageCount}
                  onPageChange={this.handlePageChange}
                />

                <div className={'m-widget11__action m--align-right'} />
              </div>
              {/* end::Widget 11 */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.mealList.loading,
    meals: state.mealList.data,
    total: state.mealList.total,
    pageCount: state.mealList.pageCount,
    error: state.mealList.error,
  };
};

export default connect(mapStateToProps, { mealList })(ListMeals);
