import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.PLAN_DELIVERY_TIMES_SUCCESS, data: data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.PLAN_DELIVERY_TIMES_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const planDeliveryTimes = (id) => {
  return async (dispatch) => {
    dispatch({ type: CONST.PLAN_DELIVERY_TIMES_REQUEST });

    axios.get(`/plans/${id}/delivery-times`).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
