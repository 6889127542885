import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data, placeType) => {
  if (placeType === 'list') {
    dispatch({ type: CONST.FOOD_CATEGORY_LIST_UPDATE, foodCategory: data });
  } else {
    dispatch({ type: CONST.FOOD_CATEGORY_EDIT_SUCCESS, foodCategory: data });
  }
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.FOOD_CATEGORY_EDIT_ERROR, error });
};

const handleResponse = (dispatch, response, placeType) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data, placeType);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const foodCategoryEdit = (foodCategoryId, bodyData, placeType) => {
  return async (dispatch) => {
    dispatch({ type: CONST.FOOD_CATEGORY_EDIT_REQUEST });

    axios.post(`/food-categories/${foodCategoryId}`, bodyData).then((response) => {
      handleResponse(dispatch, response, placeType);
    });
  };
};
