import * as CONST from '../../constants';

export const zoomTo = (location) => {
  return async (dispatch) => {
    dispatch({ type: CONST.MAP_ZOOM_TO, location });
  };
};

export const routing = (routing) => {
  return async (dispatch) => {
    dispatch({ type: CONST.MAP_ROUTING, routing });
  };
};
