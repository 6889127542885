import { axios } from '../../../../services/axios';
import * as CONST from '../../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.PLAN_DATE_MEALS_LIST_SUCCESS, data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.PLAN_DATE_MEALS_LIST_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const planDateMealsList = (planId, date, zoneId) => {
  return async (dispatch) => {
    dispatch({ type: CONST.PLAN_DATE_MEALS_LIST_REQUEST });

    axios.get(`/plans/${planId}/meals?date=${date}&zoneId=${zoneId}`).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
