import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { axios } from '../../../services/axios';

export default class ListMerchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      merchants: [],
    };
  }

  componentDidMount() {
    document.title = 'Merchants';
    this.getData();
  }

  getData() {
    axios
      .get('/merchants')
      .then((response) => {
        this.setState({
          merchants: response.data.data,
          loading: false,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.getData();
  }

  render() {
    if (this.state.loading) {
      return <div className={'loader'} />;
    } else {
      return (
        <div>
          <div className={'m-portlet m-portlet--full-height  m-portlet--unair'}>
            <div className={'m-portlet__head'} style={{ height: 'auto' }}>
              <div className={'row'} />
              <div className={'m-portlet__head-tools'}>
                {JSON.parse(localStorage.getItem('user')) &&
                  JSON.parse(localStorage.getItem('user')).user_permissions.includes(
                    'CREATE_MERCHANT',
                  ) && (
                    <div>
                      <Link
                        to={'merchants/create'}
                        color={'success'}
                        className={
                          'm-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--icon m-btn--icon-only m-btn--pill'
                        }>
                        <i className={'la la-plus-circle m--font-brand'} />
                      </Link>
                    </div>
                  )}
              </div>
            </div>
            <div className={'m-portlet__body'}>
              <div className={'tab-content'}>
                {/* begin::Widget */}
                <div className={'m-widget11'}>
                  <div className={'table-responsive'}>
                    {/* begin::Table */}
                    <table className={'table table-striped m-table'}>
                      {/* begin::Thead */}
                      <thead>
                        <tr>
                          <td>{'#'}</td>
                          <td>{'Name'}</td>
                          <td>{'Phone'}</td>
                          <td>{'Country Code'}</td>
                          <td>{'City'}</td>
                          <td>{'Status'}</td>
                          <td>{'Actions'}</td>
                        </tr>
                      </thead>
                      {/* end::Thead */}

                      {/* begin::Tbody */}
                      <tbody>
                        {this.state.merchants.map(function(merchant, i) {
                          return (
                            <tr key={i}>
                              <td>{merchant.id}</td>
                              <td>{merchant.restaurant_name}</td>
                              <td>{merchant.restaurant_phone}</td>
                              <td>{(merchant.country || {}).country_name}</td>
                              <td>{(merchant.merchant_city || {}).en_name}</td>
                              <td>{merchant.status}</td>
                              <td>
                                <span>
                                  <Link to={`merchants/${merchant.id}`}>
                                    <button
                                      className={
                                        'btn btn-lg btn-secondary m-btn m-btn--icon m-btn--icon-only m-btn--pill btn-success'
                                      }>
                                      <i className={'la la-eye m--font-brand'} />
                                    </button>
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      {/* end::Tbody */}
                    </table>
                    {/* end::Table */}
                  </div>

                  <div className={'m-widget11__action m--align-right'} />
                </div>
                {/* end::Widget 11 */}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
