export const validatePassword = (str) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (re.test(str)) {
    return true;
  } else {
    return 'Password must be 8 charachters or more and include capital letters and numbers';
  }
};

export const isNumber = (str) => {
  return /^\d+$/.test(str);
};
