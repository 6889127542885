import { axios } from '../../../../services/axios';
import * as CONST from '../../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.CLIENT_COMPLAINTS_LIST_SUCCESS, data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.CLIENT_COMPLAINTS_LIST_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const clientComplaintsList = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: CONST.CLIENT_COMPLAINTS_LIST_REQUEST });
    axios.get(`/tickets/client/${clientId}`).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
