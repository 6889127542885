import * as CONST from '../../../constants';

const INITIAL_STATE = { order: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.MAP_ASSIGN_ORDER:
      return { ...INITIAL_STATE, order: action.order };
    default:
      return state;
  }
};
