import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, data: [], error: null, total: 0, pageCount: 0 };

export const teams = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.TEAM_LIST_REQUEST:
      return { ...state, loading: true, data: [], error: null };
    case CONST.TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.data.teams,
        total: action.data.paginator.meta.total,
        pageCount: action.data.paginator.meta.last_page,
      };
    case CONST.TEAM_LIST_ERROR:
      return { ...state, loading: false, error: action.error };
    case CONST.TEAM_CREATE_REQUEST:
      return { ...state, loading: true, error: null };
    case CONST.TEAM_CREATE_SUCCESS:
      return { ...state, loading: false, data: [...state.data, action.createdTeam], error: null };
    case CONST.TEAM_CREATE_ERROR:
      return { ...state, loading: false, error: action.error };
    case CONST.TEAM_EDIT_REQUEST:
      return { ...state, loading: true, error: null };
    case CONST.TEAM_EDIT_SUCCESS:
      for (const i in state.data) {
        if (state.data[i].id === action.editedTeam.id) {
          state.data[i] = action.editedTeam;
          break;
        }
      }
      return { ...state, loading: false, data: state.data, error: null };
    case CONST.TEAM_EDIT_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
