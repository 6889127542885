import { StatusCodes } from 'http-status-codes';
import { camelizeKeys } from 'humps';
import jwtDecode from 'jwt-decode';
import { createAction } from 'redux-actions';

import { axios } from '../../../services/axios';
import { SET_CURRENT_USER, SET_CURRENT_USER_TOKEN } from '../../constants';

export const authLogin = (response) => {
  return (dispatch) => {
    const googleToken = response.accessToken;
    const requestData = {
      token: googleToken,
      provider: 'google',
    };

    return axios.post('/login', requestData).then(async (response) => {
      await revokeToken(requestData);
      if (response.status === StatusCodes.CREATED) {
        const token = response.data.user?.token;
        const user = jwtDecode(token);
        user.token = token;

        axios.setHeaderToken(token);

        dispatch(setCurrentUser(camelizeKeys(user)));
        dispatch(setCurrentUserToken(token));

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
      }
      return 1;
    });
  };
};

export const revokeToken = (data) => axios.patch('/revokeToken', data);

export const setCurrentUser = createAction(SET_CURRENT_USER);

export const setCurrentUserToken = createAction(SET_CURRENT_USER_TOKEN);
