import { decamelizeKeys } from 'humps';

import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

export const MealValidationActionTypes = {
  DEACTIVATE_MEAL: 'DEACTIVATE_MEAL',
  REPLACE_MENU_PLAN: 'REPLACE_MENU_PLAN',
};

export const validateAffectedOrders = (params) => {
  return async (dispatch) => {
    return axios
      .post('/meals/validate-affected-orders', decamelizeKeys(params))
      .then((response) => {
        const ordersCount = response?.data?.length;
        dispatch({
          type: CONST.MEALS_VALIDATE_AFFECTED_ORDERS_REQUEST,
          payload: { ordersCount },
        });
        return ordersCount;
      });
  };
};

export const setOnConfirm = (onConfirm) => {
  return async (dispatch) => {
    return dispatch({
      type: CONST.MEALS_VALIDATE_AFFECTED_ORDERS_SET_MODAL_PAYLOAD,
      payload: { onConfirm },
    });
  };
};

export const dependentOrdersModalToggle = (isDependentOrdersModalOpen) => {
  return async (dispatch, getState) => {
    const isDependentOrdersModalOpen = getState().validationModal.isDependentOrdersModalOpen;

    return dispatch({
      type: CONST.MEALS_VALIDATE_AFFECTED_ORDERS_MODAL_TOGGLE,
      payload: { isDependentOrdersModalOpen: !isDependentOrdersModalOpen },
    });
  };
};
