import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, data: null, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.CATEGORY_DELETE_REQUEST:
      return { ...INITIAL_STATE, loading: true, data: null, error: null };
    case CONST.CATEGORY_DELETE_SUCCESS:
      return { ...INITIAL_STATE, loading: false, data: action.data, error: null };
    case CONST.CATEGORY_DELETE_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error, data: null };
    case CONST.CLOSE_MODAL:
      return { ...INITIAL_STATE, loading: false, error: null, data: null };
    default:
      return state;
  }
};
