import * as CONST from '../../constants';

const INITIAL_STATE_CASH = { loading: true, data: [], error: null, total: 0, pageCount: 0 };
const INITIAL_STATE_ONLINE = { loading: true, data: [], error: null, total: 0, pageCount: 0 };
const INITIAL_STATE = { loading: true, data: [], error: null };
export const paymentListCash = (state = INITIAL_STATE_CASH, action) => {
  switch (action.type) {
    case CONST.PAYMENT_LIST_CASH_REQUEST:
      return {
        ...INITIAL_STATE_CASH,
        loading: true,
        data: [],
        error: null,
        total: 0,
        pageCount: 0,
      };
    case CONST.PAYMENT_LIST_CASH_SUCCESS:
      return {
        ...INITIAL_STATE_CASH,
        loading: false,
        data: action.data.data,
        total: action.data && action.data.meta && action.data.meta.pagination.total,
        pageCount: action.data && action.data.meta && action.data.meta.pagination.total_pages,
      };
    case CONST.PAYMENT_LIST_CASH_ERROR:
      return { ...INITIAL_STATE_CASH, loading: false, error: action.error, total: 0, pageCount: 0 };
    default:
      return state;
  }
};

export const paymentListOnline = (state = INITIAL_STATE_ONLINE, action) => {
  switch (action.type) {
    case CONST.PAYMENT_LIST_ONLINE_REQUEST:
      return {
        ...INITIAL_STATE_ONLINE,
        loading: true,
        data: [],
        error: null,
        total: 0,
        pageCount: 0,
      };
    case CONST.PAYMENT_LIST_ONLINE_SUCCESS:
      return {
        ...INITIAL_STATE_ONLINE,
        loading: false,
        data: action.data.data,
        total: action.data && action.data.meta && action.data.meta.pagination.total,
        pageCount: action.data && action.data.meta && action.data.meta.pagination.total_pages,
      };
    case CONST.PAYMENT_LIST_ONLINE_ERROR:
      return {
        ...INITIAL_STATE_ONLINE,
        loading: false,
        error: action.error,
        total: 0,
        pageCount: 0,
      };
    default:
      return state;
  }
};
export const paymentList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.PAYMENT_LIST_REQUEST:
      return { ...INITIAL_STATE, loading: true, data: [], error: null };
    case CONST.PAYMENT_LIST_SUCCESS:
      return { ...INITIAL_STATE, loading: false, data: action.data };
    case CONST.PAYMENT_LIST_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    default:
      return state;
  }
};
