import moment from 'moment';
import React, { Component } from 'react';

import AddMealForm from '../components/add-meal';

export default class AddDateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      stock: '',
      itemsNumber: 7,
      dateItemsSates: [],
    };
    this.handleAddMeal = this.handleAddMeal.bind(this);
  }

  handleAddMeal(data) {
    // add added meal in date items states to hold states of every meal added to date
    this.setState({
      dateItemsSates: this.state.dateItemsSates.concat(data),
    });
    // save meal into item
    this.props.handleAddMealToDate(data);
  }

  tabItems(date) {
    const itemForms = [];
    for (let i = 0; i < this.state.itemsNumber; i++) {
      const randomKey = `${date}#${i}`;
      itemForms.push(
        <div className={'col'} key={randomKey} id={randomKey}>
          <div className={'col'} />
          <div className={'col'}>
            <AddMealForm
              pageRequestForm={'create'}
              itemIndex={i}
              date={date}
              merchants={this.props.merchants}
              handleAddMeal={this.handleAddMeal}
              itemsStates={this.state.dateItemsSates}>
              <i className={'la la-plus-circle m--font-brand'} />
            </AddMealForm>
          </div>
        </div>,
      );
    }
    return itemForms;
  }

  render() {
    return (
      <div>
        <div className={'m-portlet m-portlet--full-height  m-portlet--unair'}>
          <div className={'m-portlet__head'} style={{ height: 'auto' }}>
            <h5>
              {moment(this.props.date).format('dddd')} {this.props.date}
            </h5>
          </div>
          <div className={'m-portlet__body'}>
            <div className={'container row'}>{this.tabItems(this.props.date)}</div>
          </div>
        </div>
      </div>
    );
  }
}
