import {axios} from '../../../../services/axios';
import * as CONST from '../../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({type: CONST.CLIENT_ADDRESS_LIST_SUCCESS, data});
};

const onFailed = (dispatch, error) => {
  dispatch({type: CONST.CLIENT_ADDRESS_LIST_ERROR, error});
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const clientAddressList = (clientId, subscriptionId = null) => {
  return async (dispatch) => {
    dispatch({ type: CONST.CLIENT_ADDRESS_LIST_REQUEST });

    const params = { status: 'ACCEPTED', subscription_id: subscriptionId };

    axios.get(`/clients/${clientId}/addresses`, { params}).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
