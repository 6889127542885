import { Datacenter, datadogRum } from '@datadog/browser-rum';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ApolloClient from 'apollo-boost';
import { camelizeKeys } from 'humps';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import React, { Suspense } from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import toastr from 'toastr';

import packageJson from '../package.json';
import { axios } from './services/axios';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
// ACTIONS
import { setCurrentUser, setCurrentUserToken } from './store/actions';
// holidays
import Holidays from './views/holidays/index';
//MEALS
import ListMeals from './views/meal/list/list';
import ShowMeal from './views/meal/show/show';
//MENU
import CreateMenu from './views/menu/create/create';
import ListMenus from './views/menu/list/list';
import ShowMenu from './views/menu/show/show';
import CreateMerchant from './views/merchant/create/create';
import UpdateMerchant from './views/merchant/edit/edit';
// merchants
import ListMerchant from './views/merchant/list/list';
import ShowMerchant from './views/merchant/show/show';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-lightbox-component/build/css/index.css';

// datadog real user monitor initialization
if (process.env.REACT_APP_DATADOG_ENABLED) {
  datadogRum.init({
    sampleRate: 100,
    version: packageJson.version,
    service: 'dm-dashboard',
    trackInteractions: true,
    datacenter: Datacenter.US,
    env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  });
}

// PLAN
const ListPlan = React.lazy(() => import('./views/plan/listPlans'));
const ShowPlan = React.lazy(() => import('./views/plan/PlanDetails'));

//PLAN QUICK FILTER
const ListPlanQuickFilters = React.lazy(() => import('./views/PlanQuickFilters/list'));

// CRM
const ComplaintList = React.lazy(() => import('./views/tickets/list'));
const ComplainDetails = React.lazy(() => import('./views/tickets/show/details'));
const ActionList = React.lazy(() => import('./views/complaint-action/list'));
const StatusList = React.lazy(() => import('./views/complaint-status/list'));
const CategoryList = React.lazy(() => import('./views/category/list'));
const GroupList = React.lazy(() => import('./views/groups/list'));
// voucher
const ListVoucher = React.lazy(() => import('./views/voucher/list/list'));
const ShowVoucher = React.lazy(() => import('./views/voucher/show/show'));
// LAYOUTS
const AuthLayout = React.lazy(() => import('./views/layouts/auth'));
const MainLayout = React.lazy(() => import('./views/layouts/main'));
const MapLayout = React.lazy(() => import('./views/layouts/map'));
// LOGIN
const Login = React.lazy(() => import('./views/auth/login/'));
// HOME DASHBOARD
const Home = React.lazy(() => import('./views/home'));
// ROUTE RECOMMENDATION
const RouteRecommendation = React.lazy(() => import('./views/route-recommendation'));
const RouteRecommendationV2 = React.lazy(() => import('./views/route-recommendation-v2'));
// SUBSCRIPTION
const ListSubscription = React.lazy(() => import('./views/subscription/list/'));
const ShowSubscription = React.lazy(() => import('./views/subscription/show/'));
const ShowOrder = React.lazy(() => import('./views/subscription/show/order/show'));
// CLIENT
const ListClient = React.lazy(() => import('./views/client/list/'));
const ShowClient = React.lazy(() => import('./views/client/show/'));
const MergeClient = React.lazy(() => import('./views/client/merge/'));
// COMPANY
const ListCompany = React.lazy(() => import('./views/company/list/'));
const ShowCompany = React.lazy(() => import('./views/company/show/'));
// PAYMENT
const ListPayment = React.lazy(() => import('./views/payment/list/'));
const DriverPayments = React.lazy(() => import('./views/DriverPayments/list/'));
const Payment = React.lazy(() => import('./views/payment/list/paymentLog'));
// NOTIFICATION
const ListNotification = React.lazy(() => import('./views/notification/list/'));
//USERS
const ListUser = React.lazy(() => import('./views/user/list/'));
const UserRolesList = React.lazy(() => import('./views/UserRoles/list/'));
// CITIES
const ListCities = React.lazy(() => import('./views/city/list/'));
// RENEWAL
const ListRenewal = React.lazy(() => import('./views/renewal-list/list'));
// DRIVER TEAM
const ListTeam = React.lazy(() => import('./views/team'));
// DRIVER
const ListDriver = React.lazy(() => import('./views/driver/getDrivers'));
const DriversTracking = React.lazy(() => import('./views/driver/tracking/list'));
const TrackDriver = React.lazy(() => import('./views/driver/tracking/tracking'));
const DriverAssign = React.lazy(() => import('./views/driver/assign/'));
// MAP
const ListMap = React.lazy(() => import('./views/map/index.js'));
// ZONE
const ListZone = React.lazy(() => import('./views/zone/list/'));
const CreateZone = React.lazy(() => import('./views/zone/create/'));
const ShowZone = React.lazy(() => import('./views/zone/show/'));
const EditZone = React.lazy(() => import('./views/zone/edit/'));

const ListZoneDiscount = React.lazy(() => import('./views/zoneDiscount/list/'));
const CreateZoneDiscount = React.lazy(() => import('./views/zoneDiscount/create/'));
const EditZoneDiscount = React.lazy(() => import('./views/zoneDiscount/edit/'));
// REPORT
const MealzDriverReport = React.lazy(() => import('./views/reports/MealzDriverReport'));
const CustomerOrdersReport = React.lazy(() => import('./views/reports/CustomerOrdersReport'));
const PaymentCollectionReport = React.lazy(() => import('./views/reports/PaymentCollectionReport'));
const NotavailableMealOrderReport = React.lazy(() =>
  import('./views/reports/NotavailableMealOrderReport'),
);
const PickUpReport = React.lazy(() => import('./views/reports/PickUpReport'));
const MealzDriverPickUpReport = React.lazy(() => import('./views/reports/MealzDriverPickUpReport'));
const PaymentCollectionDriverReport = React.lazy(() =>
  import('./views/reports/PaymentCollectionDriverReport'),
);
const SupervisorsReport = React.lazy(() => import('./views/reports/SupervisorsReport'));
// send intercom notifications to clients
const ClientIntercomNotification = React.lazy(() =>
  import('./views/client/intercom-notification/create'),
);
// food categories
const ListFoodCategories = React.lazy(() => import('./views/food-categories/list/list'));
const ShowFoodCategories = React.lazy(() => import('./views/food-categories/show/show'));
// cancel reasons
const ListCancelReason = React.lazy(() => import('./views/cancel-reasons/list/list'));
// payment type
const ListPaymentType = React.lazy(() => import('./views/payment-type/list/list'));

const NotifiDriver = React.lazy(() => import('./views/notifi-drivers/list/'));

// COMPENSATION

const compensationProblems = React.lazy(() => import('./views/compensation/problems'));
const compensationAmounts = React.lazy(() => import('./views/compensation/credit-amounts'));
moment.tz.setDefault('Asia/Riyadh');

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
  onError: ({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        toastr.error(err.debugMessage);
      }
      // response.errors = [];
    }
  },
});

const AppRoute = ({ title, component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout title={title}>
        <Component {...props} />
      </Layout>
    )}
  />
);

const ProtectedAppRoute = ({
  type,
  title,
  isAllowed,
  hasPermission,
  permissionType,
  component: Component,
  layout: Layout,
  ...rest
}) =>
  isAllowed() ? (
    hasPermission(permissionType) ? (
      <Route
        {...rest}
        render={(props) => (
          <Layout title={title}>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : (
      <Redirect to={'/admin/'} />
    )
  ) : (
    <Redirect to={`/${type}/login`} />
  );

const getCurrentUser = () => {
  const token = localStorage.getItem('token');
  let currentUser;
  if (token) {
    currentUser = camelizeKeys(jwtDecode(token));
  }
  axios.setHeaderToken(token);
  return { token, currentUser };
};

const isLoggedin = () => {
  const { token } = getCurrentUser();
  return !!token;
};

const hasPermission = (permission) => {
  const { currentUser } = getCurrentUser();
  const userPermissions = currentUser.userPermissions;
  if (permission === 'main') {
    return true;
  }
  return userPermissions.includes(permission);
};

const { token, currentUser } = getCurrentUser();
store.dispatch(setCurrentUser(currentUser));
store.dispatch(setCurrentUserToken(token));

const isProduction = process.env.NODE_ENV === 'production';
if (isProduction) {
  const googleAnalyticsTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  ReactGA.initialize(googleAnalyticsTrackingId);
}

const RouteChangeTracker = withRouter(({ history }) => {
  history.listen((location) => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  });
  return null;
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ToastProvider>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            {isProduction ?? <RouteChangeTracker />}
            <Suspense fallback={<div className={'loader'} />}>
              <Switch>
                {/* ADMIN DASHBOARD */}
                <AppRoute exact path={'/admin/login'} layout={AuthLayout} component={Login} />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'main'}
                  exact
                  path={'/admin/'}
                  layout={MainLayout}
                  component={Home}
                  title={'Home'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_SUBSCRIPTIONS'}
                  exact
                  path={'/admin/subscriptions'}
                  layout={MainLayout}
                  component={ListSubscription}
                  title={'Subscriptions List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_SUBSCRIPTIONS'}
                  exact
                  path={'/admin/subscriptions/:id'}
                  layout={MainLayout}
                  component={ShowSubscription}
                  title={'Subscription Details'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_SUBSCRIPTIONS'}
                  exact
                  path={'/admin/subscriptions/:id/logs/:orderId'}
                  layout={MainLayout}
                  component={ShowOrder}
                  title={'Subscription Details'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_CLIENTS'}
                  exact
                  path={'/admin/clients/merge'}
                  layout={MainLayout}
                  component={MergeClient}
                  title={'Clients Merge'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_CLIENTS'}
                  exact
                  path={'/admin/clients/:id'}
                  layout={MainLayout}
                  component={ShowClient}
                  title={'Client Details'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_CLIENTS'}
                  exact
                  path={'/admin/clients'}
                  layout={MainLayout}
                  component={ListClient}
                  title={'Clients List'}
                />
                {/* send client intercom notification route */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'CREATE_CLIENT_INTERCOM_NOTIFICATION'}
                  exact
                  path={'/admin/send-intercom-notificaton'}
                  layout={MainLayout}
                  component={ClientIntercomNotification}
                  title={'Client Send Intercom Notification'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_COMPANIES'}
                  exact
                  path={'/admin/companies'}
                  layout={MainLayout}
                  component={ListCompany}
                  title={'Companies List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_COMPANIES'}
                  exact
                  path={'/admin/companies/:id'}
                  layout={MainLayout}
                  component={ShowCompany}
                  title={'Companies Details'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_PAYMENTS'}
                  exact
                  path={'/admin/payments'}
                  layout={MainLayout}
                  component={ListPayment}
                  title={'Payments List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_PAYMENTS'}
                  exact
                  path={'/admin/driver-payments'}
                  layout={MainLayout}
                  component={DriverPayments}
                  title={'Driver Payments'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_USERS'}
                  exact
                  path={'/admin/users'}
                  layout={MainLayout}
                  component={ListUser}
                  title={'Users List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_USERS'}
                  exact
                  path={'/admin/user-roles'}
                  layout={MainLayout}
                  component={UserRolesList}
                  title={'Users Roles'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_NOTIFICATIONS'}
                  exact
                  path={'/admin/notifications'}
                  layout={MainLayout}
                  component={ListNotification}
                  title={'Notifications List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_PAYMENTS'}
                  exact
                  path={'/admin/payments/:id/:type'}
                  layout={MainLayout}
                  component={Payment}
                  title={'Payment Logs'}
                />
                {/* MAP */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MAP'}
                  exact
                  path={'/admin/map/'}
                  layout={MapLayout}
                  component={ListMap}
                  title={'Map'}
                />
                {/* Route RECOMMENDATION */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MAP'}
                  exact
                  path={'/admin/route-recommendation'}
                  layout={MainLayout}
                  component={RouteRecommendation}
                  title={'Route Recommendation'}
                />
                {/* Route RECOMMENDATION V2 */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MAP'}
                  exact
                  path={'/admin/route-recommendation-v2'}
                  layout={MainLayout}
                  component={RouteRecommendationV2}
                  title={'Route Recommendation V2'}
                />
                {/* ZONE */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_ZONES'}
                  exact
                  path={'/admin/zones'}
                  layout={MainLayout}
                  component={ListZone}
                  title={'Zones List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'CREATE_ZONES'}
                  exact
                  path={'/admin/zones/create'}
                  layout={MainLayout}
                  component={CreateZone}
                  title={'Create Zone'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_ZONES'}
                  exact
                  path={'/admin/zones/:id'}
                  layout={MainLayout}
                  component={ShowZone}
                  title={'Show Zone'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'EDIT_ZONES'}
                  exact
                  path={'/admin/zones/:id/edit'}
                  layout={MainLayout}
                  component={EditZone}
                  title={'Edit Zone'}
                />
                {/* ZONE DISCOUNT */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_ZONES'}
                  exact
                  path={'/admin/zone-discounts'}
                  layout={MainLayout}
                  component={ListZoneDiscount}
                  title={'Zone Discounts List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'CREATE_ZONES'}
                  exact
                  path={'/admin/zone-discounts/create'}
                  layout={MainLayout}
                  component={CreateZoneDiscount}
                  title={'Create Zone Discount'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'EDIT_ZONES'}
                  exact
                  path={'/admin/zone-discounts/:id/edit'}
                  layout={MainLayout}
                  component={EditZoneDiscount}
                  title={'Edit Zone Discount'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_DRIVERS_TRACKING'}
                  exact
                  path={'/admin/tracking-drivers'}
                  layout={MainLayout}
                  component={DriversTracking}
                  title={'Tracking Drivers List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_DRIVERS_TRACKING'}
                  exact
                  path={'/admin/driver/:id/track'}
                  layout={MainLayout}
                  component={TrackDriver}
                  title={'Track Driver'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_TEAMS'}
                  exact
                  path={'/admin/teams'}
                  layout={MainLayout}
                  component={ListTeam}
                  title={'Teams'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MAP'}
                  exact
                  path={'/admin/assigned'}
                  layout={MainLayout}
                  component={DriverAssign}
                  title={'assigned'}
                />
                {/* reports routes */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_REPORTS'}
                  exact
                  path={'/admin/mealz-driver-report'}
                  layout={MainLayout}
                  component={MealzDriverReport}
                  title={'Mealz Driver Report'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_REPORTS'}
                  exact
                  path={'/admin/customer-orders-report'}
                  layout={MainLayout}
                  component={CustomerOrdersReport}
                  title={'Customers Orders Report'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_REPORTS'}
                  exact
                  path={'/admin/payment-collection-report'}
                  layout={MainLayout}
                  component={PaymentCollectionReport}
                  title={'Payment Collection Report'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_REPORTS'}
                  exact
                  path={'/admin/not-available-meals-report'}
                  layout={MainLayout}
                  component={NotavailableMealOrderReport}
                  title={'Not Available Meal Order Report'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_REPORTS'}
                  exact
                  path={'/admin/pick-up-report'}
                  layout={MainLayout}
                  component={PickUpReport}
                  title={'Pick Up Report'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_REPORTS'}
                  exact
                  path={'/admin/mealz-driver-pick-up-report'}
                  layout={MainLayout}
                  component={MealzDriverPickUpReport}
                  title={'Mealz Driver Pick Up Report'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_REPORTS'}
                  exact
                  path={'/admin/payment-collection-driver-report'}
                  layout={MainLayout}
                  component={PaymentCollectionDriverReport}
                  title={'Payment Collection Driver Report'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_REPORTS'}
                  exact
                  path={'/admin/supervisors-report'}
                  layout={MainLayout}
                  component={SupervisorsReport}
                  title={'Supervisors Report'}
                />
                {/* cities routes */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_CITIES'}
                  exact
                  path={'/admin/cities'}
                  layout={MainLayout}
                  component={ListCities}
                  title={'Cities List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_RENEWAL'}
                  exact
                  path={'/admin/renewal-list'}
                  layout={MainLayout}
                  component={ListRenewal}
                  title={'Renewal List'}
                />
                {/* merchants routes */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MERCHANT'}
                  exact
                  path={'/admin/merchants'}
                  layout={MainLayout}
                  component={ListMerchant}
                  title={'Merchants List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'CREATE_MERCHANT'}
                  exact
                  path={'/admin/merchants/create'}
                  layout={MainLayout}
                  component={CreateMerchant}
                  title={'Merchant Creation'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MERCHANT'}
                  exact
                  path={'/admin/merchants/:id'}
                  layout={MainLayout}
                  component={ShowMerchant}
                  title={'Merchants Details'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'EDIT_MERCHANT'}
                  exact
                  path={'/admin/merchants/:id/edit'}
                  layout={MainLayout}
                  component={UpdateMerchant}
                  title={'Merchants Update'}
                />
                {/* meals routes */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MEALS'}
                  exact
                  path={'/admin/meals'}
                  layout={MainLayout}
                  component={ListMeals}
                  title={'Mealz List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MEALS'}
                  exact
                  path={'/admin/meals/:id'}
                  layout={MainLayout}
                  component={ShowMeal}
                  title={'Meal Details'}
                />
                {/* menu routes */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MENUS'}
                  exact
                  path={'/admin/menus'}
                  layout={MainLayout}
                  component={ListMenus}
                  title={'Menus List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'CREATE_MENUS'}
                  exact
                  path={'/admin/menus/create'}
                  layout={MainLayout}
                  component={CreateMenu}
                  title={'Menu Creation'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_MENUS'}
                  exact
                  path={'/admin/menus/:id'}
                  layout={MainLayout}
                  component={ShowMenu}
                  title={'Menus Details'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'EDIT_MENUS'}
                  exact
                  path={'/admin/menus/:id/edit'}
                  layout={MainLayout}
                  component={ListMenus}
                  title={'Menus Update'}
                />
                {/* plans routes */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_PLANS'}
                  exact
                  path={'/admin/plans'}
                  layout={MainLayout}
                  component={ListPlan}
                  title={'Plans List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'CREATE_PLANS'}
                  exact
                  path={'/admin/plans/:id'}
                  layout={MainLayout}
                  component={ShowPlan}
                  title={'Plan Details'}
                />
                {/* plans quick filter routes */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_PLANS'}
                  exact
                  path={'/admin/plan-quick-filters'}
                  layout={MainLayout}
                  component={ListPlanQuickFilters}
                  title={'Plan Quick Filters List'}
                />
                {/* holiday routes */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_HOLIDAYS'}
                  exact
                  path={'/admin/holidays'}
                  layout={MainLayout}
                  component={Holidays}
                  title={'Holidays'}
                />
                {/* CRM */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_TICKETS'}
                  exact
                  path={'/admin/complains'}
                  layout={MainLayout}
                  component={ComplaintList}
                  title={'Tickets List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_TICKETS'}
                  exact
                  path={'/admin/groups'}
                  layout={MainLayout}
                  component={GroupList}
                  title={'Groups List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_TICKETS'}
                  exact
                  path={'/admin/complains/:id'}
                  layout={MainLayout}
                  component={ComplainDetails}
                  title={'Ticket Details'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_ACTION'}
                  exact
                  path={'/admin/actions'}
                  layout={MainLayout}
                  component={ActionList}
                  title={'Actions List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_CATEGORIES'}
                  exact
                  path={'/admin/categories'}
                  layout={MainLayout}
                  component={CategoryList}
                  title={'Categories List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_STATUS'}
                  exact
                  path={'/admin/status'}
                  layout={MainLayout}
                  component={StatusList}
                  title={'Status List'}
                />
                {/* voucher */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_VOUCHERS'}
                  exact
                  path={'/admin/vouchers'}
                  layout={MainLayout}
                  component={ListVoucher}
                  title={'Vouchers List'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_VOUCHERS'}
                  exact
                  path={'/admin/vouchers/:id'}
                  layout={MainLayout}
                  component={ShowVoucher}
                  title={'Vouchers Details'}
                />
                {'//Notifi Drivers'}
                {/*<ProtectedAppRoute*/}
                {/*  type={'admin'}*/}
                {/*  isAllowed={isLoggedin}*/}
                {/*  hasPermission={hasPermission}*/}
                {/*  permissionType={'VIEW_MAP'}*/}
                {/*  exact*/}
                {/*  path={'/admin/notifi-drivers'}*/}
                {/*  layout={MainLayout}*/}
                {/*  component={NotifiDriver}*/}
                {/*  title={'Notifi Driver'}*/}
                {/*/>*/}
                {'// Food Categories'}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_FOOD_CATEGORIES'}
                  exact
                  path={'/admin/food-categories'}
                  layout={MainLayout}
                  component={ListFoodCategories}
                  title={'Food Categories'}
                />
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_FOOD_CATEGORIES'}
                  exact
                  path={'/admin/food-categories/:id'}
                  layout={MainLayout}
                  component={ShowFoodCategories}
                  title={'Food Categories'}
                />
                {/* Cancel Reason */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_CANCEL_REASON'}
                  exact
                  path={'/admin/cancel-reasons'}
                  layout={MainLayout}
                  component={ListCancelReason}
                  title={'Cancel Reaons List'}
                />
                {/* Payment Type */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_PAYMENT_TYPES'}
                  exact
                  path={'/admin/payment-types'}
                  layout={MainLayout}
                  component={ListPaymentType}
                  title={'Payment Types List'}
                />
                {/* COMPENSATIONS PROBLEMS */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_COMPENSATION_PROBLEMS'}
                  exact
                  path={'/admin/compensation/problems'}
                  layout={MainLayout}
                  component={compensationProblems}
                  title={'Problems'}
                />
                {/* COMPENSATIONS TYPES */}
                <ProtectedAppRoute
                  type={'admin'}
                  isAllowed={isLoggedin}
                  hasPermission={hasPermission}
                  permissionType={'VIEW_COMPENSATION_AMOUNTS'}
                  exact
                  path={'/admin/compensation/credit-amounts'}
                  layout={MainLayout}
                  component={compensationAmounts}
                  title={'Credit Amount'}
                />
                <Redirect to={'/admin/'} />
              </Switch>
            </Suspense>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </Provider>
    </ToastProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
