import React from 'react';
import swal from 'sweetalert2';

import { axios } from '../../../services/axios';
import { isNumber } from '../../../utils/validators';
import MerchantForm from '../components/form';

export default class CreateMerchant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant_name: '',
      restaurant_phone: '',
      contact_name: '',
      contact_phone: '',
      secondary_contact_phone: '',
      contact_email: '',
      lat: '',
      long: '',
      street: '',
      username: '',
      password: '',
      country_id: '',
      city_id: '',
      has_zoho_account: 'no',
      tax_registration_id: '',
      zoho_id: '',
      countries: [],
      cities: [],
      has_labeling: 0,
      is_catering_supported: 0,
      contract: '',
      capacity: null,
      validation: {
        restaurant_name: '',
        restaurant_phone: '',
        contact_name: '',
        contact_phone: '',
        secondary_contact_phone: '',
        contact_email: '',
        lat: '',
        long: '',
        street: '',
        username: '',
        password: '',
        city_id: '',
        county_id: '',
        merchantLogo: '',
        contract: '',
        capacity: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.listAllCountries = this.listAllCountries.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  componentDidMount() {
    document.title = 'Create Merchant';
    this.listAllCountries();
  }

  listAllCountries() {
    axios
      .get('/countries')
      .then((response) => {
        this.setState({
          countries: response.data.data,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handleChangeCountry(event) {
    const countryId = event.target.value;
    this.setState(() => {
      return {
        country_id: countryId,
      };
    });

    axios
      .get(`/cities?country_id=${countryId}`)
      .then((response) => {
        this.setState({
          cities: response.data.data,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handleChange(event) {
    const inputName = event.target.name;
    let inputValue = event.target.value;
    if (inputName === 'has_labeling' || inputName === 'is_catering_supported') {
      inputValue = event.target.checked ? 1 : 0;
    }
    this.setState({ [inputName]: inputValue });
  }

  handleUploadFile(e, key) {
    const files = Array.from(e.target.files);
    this.setState({ [key]: files[0] });
  }

  handleSubmit(event) {
    event.preventDefault();

    const data = new FormData();
    data.append('restaurant_name', this.state.restaurant_name);
    data.append('restaurant_phone', this.state.restaurant_phone);
    data.append('contact_name', this.state.contact_name);
    data.append('contact_phone', this.state.contact_phone);
    data.append('contact_phone', this.state.contact_phone);
    data.append('secondary_contact_phone', this.state.secondary_contact_phone);
    data.append('contact_email', this.state.contact_email);
    data.append('country_id', this.state.country_id);
    data.append('city_id', this.state.city_id);
    data.append('street', this.state.street);
    data.append('lontitude', this.state.long);
    data.append('latitude', this.state.lat);
    data.append('has_labeling', this.state.has_labeling);
    data.append('is_catering_supported', this.state.is_catering_supported);
    data.append('username', this.state.username);
    data.append('password', this.state.password);
    data.append('contract', this.state.contract);
    if (this.state.has_zoho_account === 'yes' && this.state.zoho_id !== '') {
      data.append('zoho_id', this.state.zoho_id);
    } else if (this.state.has_zoho_account === 'no' && this.state.tax_registration_id !== '') {
      data.append('tax_registration_id', this.state.tax_registration_id);
    }

    if (this.state.merchantLogo) {
      data.append('merchantLogo', this.state.merchantLogo);
    }
    if (isNumber(this.state.capacity)) {
      data.append('capacity', this.state.capacity);
    }

    axios
      .post('/merchants', data)
      .then((response) => {
        swal.fire({
          title: 'Success',
          type: 'success',
          text: 'Merchant has been created successfully!',
          timer: 2000,
        });
        this.props.history.push('/admin/merchants');
        swal.fire({
          title: 'Success',
          type: 'success',
          text: 'Merchant has been created successfully!',
          timer: 2000,
        });
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          this.setState({
            validation: {
              restaurant_name: e.response.data.errors.restaurant_name
                ? e.response.data.errors.restaurant_name
                : '',
              restaurant_phone: e.response.data.errors.restaurant_phone
                ? e.response.data.errors.restaurant_phone
                : '',
              contact_name: e.response.data.errors.contact_name
                ? e.response.data.errors.contact_name
                : '',
              contact_phone: e.response.data.errors.contact_phone
                ? e.response.data.errors.contact_phone
                : '',
              secondary_contact_phone: e.response.data.errors.secondary_contact_phone
                ? e.response.data.errors.secondary_contact_phone
                : '',
              contact_email: e.response.data.errors.contact_email
                ? e.response.data.errors.contact_email
                : '',
              country_id: e.response.data.errors.country_id
                ? e.response.data.errors.country_id
                : '',
              city_id: e.response.data.errors.city_id ? e.response.data.errors.city_id : '',
              street: e.response.data.errors.street ? e.response.data.errors.street : '',
              long: e.response.data.errors.lontitude ? e.response.data.errors.lontitude : '',
              lat: e.response.data.errors.latitude ? e.response.data.errors.latitude : '',
              zoho_id: e.response.data.errors.zoho_id ? e.response.data.errors.zoho_id : '',
              tax_registration_id: e.response.data.errors.tax_registration_id
                ? e.response.data.errors.tax_registration_id
                : '',
              username: e.response.data.errors.username ? e.response.data.errors.username : '',
              password: e.response.data.errors.password ? e.response.data.errors.password : '',
              contract: e.response.data.errors.contract ? e.response.data.errors.contract : '',
              capacity: e.response.data.errors.capacity ? e.response.data.errors.capacity : '',
            },
          });
        } else {
          swal.fire({
            title: 'Error',
            type: 'error',
            text: `${e.response?.data?.message}!`,
            timer: 2000,
          });
        }
      });
  }

  render() {
    return (
      <div className={'row'}>
        <div className={'col-md-12'}>
          <div className={'m-portlet m-portlet--tab'}>
            <div className={'m-portlet__head'}>
              <div className={'m-portlet__head-caption'}>
                <div className={'m-portlet__head-title'}>
                  <span className={'m-portlet__head-icon m--hide'}>
                    <i className={'la la-gear'} />
                  </span>
                  <h3 className={'m-portlet__head-text'}>{'Merchant Form'}</h3>
                </div>
              </div>
            </div>
            {/* merchnat form begin */}
            <MerchantForm
              formType={'create'}
              countries={this.state.countries}
              cities={this.state.cities}
              values={this.state}
              handleChange={this.handleChange}
              handleUploadFile={this.handleUploadFile}
              handleChangeCountry={this.handleChangeCountry}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}
