import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { axios } from '../../../services/axios';
import DuplicateMenuActionForm from '../components/duplicateAction';

export default class EditMealForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showBody: false,
      showLoader: true,
      merchants: [],
      meals: [],
      old_meal_id: this.props.old_item_id,
      menu_id: this.props.menu_id,
      date: this.props.date,
      merchant_id: this.props.merchant_id,
      meal_id: this.props.old_item_id,
      stock: 100,
    };
    this.toggle = this.toggle.bind(this);
    this.handleMerchantChange = this.handleMerchantChange.bind(this);
    this.listMerchantMeals = this.listMerchantMeals.bind(this);
    this.handleMealChange = this.handleMealChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      showLoader: false,
      showBody: true,
    });
  }

  toggle() {
    // check if showBody is true and get meals
    if (this.state.showBody) {
      this.listMerchantMeals(this.props.merchant_id);
    }
    this.setState({ modal: !this.state.modal });
  }

  handleMerchantChange(event) {
    const merchantId = event.target.value;
    this.listMerchantMeals(merchantId);
    this.setState({
      merchant_id: merchantId,
    });
  }

  listMerchantMeals(merchantId) {
    axios
      .get(`/merchants/${merchantId}/meals`)
      .then((response) => {
        this.setState({
          meals: response.data.data,
          showBody: true,
          showLoader: false,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handleMealChange(e) {
    this.setState({
      meal_id: e.target.value,
      stock: e.target[e.target.selectedIndex].getAttribute('data-stock'),
      date: this.props.date,
    });
  }

  handleSubmit(duplicateActionValue) {

    this.setState({
      showLoader: true,
      showBody: false,
    });

    const data = {
      date: this.state.date,
      old_meal_id: this.state.old_meal_id,
      new_meal_id: this.state.meal_id,
      duplicate: duplicateActionValue,
    };

    axios.patch(`/menus/${this.state.menu_id}/update-item`, data).then((response) => {
      if (response.status === 200) {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false,
        });
        swal.fire({
          title: 'Success',
          type: 'success',
          text: 'Updated successfully!',
          timer: 2000,
        });
        // refresh
        setTimeout(() => {
          this.props.handleEditSubmit();
        }, 1000);
      } else {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false,
        });
        swal.fire({ title: 'Error', type: 'error', text: response.data.message });
      }
    });
  }

  render() {
    return (
      <div>
        <Button
          onClick={this.toggle}
          className={
            'm-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle'
          }>
          {this.props.children}
        </Button>
        <Modal isOpen={this.state.modal}>
          <form>
            <ModalHeader>{'Choose Meal'}</ModalHeader>
            <ModalBody>
              <div style={this.state.showLoader ? {} : { display: 'none' }} className={'loader'} />
              <div
                style={this.state.showBody ? {} : { display: 'none' }}
                className={'modal-body-content'}>
                <div className={'form-group m-form__group'}>
                  <label>{'Merchant'}</label>
                  <select
                    className={'form-control'}
                    value={this.state.merchant_id}
                    onChange={this.handleMerchantChange}>
                    {this.props.merchants.map(function(obj, i) {
                      return (
                        <option key={i} value={obj.id}>
                          {obj.restaurant_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={'form-group m-form__group'}>
                  <label>{'Item'}</label>
                  <select
                    className={'form-control'}
                    value={this.state.meal_id}
                    onChange={this.handleMealChange}
                    required>
                    <option key={'default'} value={''}>
                      {'Select Meal'}
                    </option>
                    {this.state.meals &&
                      this.state.meals.map((obj) => (
                        <option key={obj.item_id} data-stock={obj.stock} value={obj.item_id}>
                          {obj.item_id}
                          {' - '}
                          {obj.ar_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className={'form-group m-form__group'}>
                  <label>{'Stock'}</label>
                  <input
                    type={'text'}
                    className={'form-control'}
                    disabled
                    name={'country'}
                    value={this.state.stock}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {/* eslint-disable-next-line max-len */}
              {/* <input type="submit" value="Submit" color="primary" className="btn btn-primary" /> */}
              <DuplicateMenuActionForm
                actionType={'edit'}
                date={this.state.date}
                handleDuplicateAction={this.handleSubmit}
              />
              <Button color={'danger'} onClick={this.toggle}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}
