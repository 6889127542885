import Swal from 'sweetalert2';

const Alert = {
  success: (text = '', timer = 2000) => {
    return Swal.fire({
      title: 'Success',
      type: 'success',
      text,
      timer,
    });
  },
  error: (text = '', timer = 2000) => {
    return Swal.fire({
      title: 'Error',
      type: 'error',
      text,
      timer,
    });
  },
};

export default Alert;
