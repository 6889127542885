import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.VOUCHER_EDIT_SUCCESS, voucher: data.voucher });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.VOUCHER_EDIT_ERROR, error });
};

export const voucherEdit = (voucherId, bodyData) => {
  return async (dispatch) => {
    dispatch({ type: CONST.VOUCHER_EDIT_REQUEST });

    axios
      .patch(`/vouchers/${voucherId}`, bodyData)
      .then((response) => {
        onSuccess(dispatch, response.data);
      })
      .catch((error) => {
        onFailed(dispatch, error);
      });
  };
};
