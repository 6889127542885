import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.DRIVER_LIST_ALL_SUCCESS, data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.DRIVER_LIST_ALL_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const driverListAll = (params = { count: 0 }) => {
  return async (dispatch) => {
    axios.get('/drivers', { params }).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
