import * as CONST from '../../constants';

const INITIAL_STATE = {
  loadingForm: null,
  loadingList: true,
  loadingShow: true,
  requestType: 'list',
  foodCategory: null,
  per_page : 10 ,
  page: 1,
  pageCount: null,
  error: null,
  modal: false,
  data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.FOOD_CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        loadingForm: true,
        loadingList: true,
        requestType: 'create',
        error: null,
        modal: true,
      };
    case CONST.FOOD_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        loadingForm: false,
        loadingList: false,
        requestType: 'create',
        data:
          (state.page === 1 && state.pageCount === 1) || state.page === state.pageCount
            ? [...state.data, action.foodCategory]
            : state.data,
        error: null,
        modal: false,
      };
    case CONST.FOOD_CATEGORY_CREATE_ERROR:
      return {
        ...state,
        loadingForm: false,
        loadingList: false,
        requestType: 'create',
        data: action.foodCategories,
        error: action.error,
        modal: true,
      };
    case CONST.FOOD_CATEGORY_EDIT_REQUEST:
      return { ...state, loadingForm: true, loadingShow: true, error: null, requestType: 'edit' };
    case CONST.FOOD_CATEGORY_EDIT_SUCCESS:
      return {
        ...state,
        loadingShow: false,
        loadingForm: false,
        requestType: 'edit',
        foodCategory: action.foodCategory,
        error: null,
      };
    case CONST.FOOD_CATEGORY_EDIT_ERROR:
      return { ...state, loadingForm: false, error: action.error, foodCategory: null ,requestType: 'edit', };
    case CONST.FOOD_CATEGORY_LIST_REQUEST:
      return { ...state, loading: true, data: [], requestType: 'list' };
    case CONST.FOOD_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        requestType: 'list',
        page: action?.data?.data?.current_page,
        per_page: action?.data?.data?.per_page,
        data: action?.data?.data?.data,
        total: action?.data?.data?.total,
        pageCount: action?.data?.data?.last_page,
      };
    case CONST.FOOD_CATEGORY_LIST_UPDATE:
      for (const i in state.data) {
        if (state.data[i].id === action.foodCategory.id) {
          state.data[i] = action.foodCategory;
          break;
        }
      }
      return {
        ...state,
        loadingForm: false,
        loadingList: false,
        requestType: 'edit',
        data: [...state.data],
        error: null,
      };
    case CONST.FOOD_CATEGORY_LIST_ERROR:
      return { ...state, loading: false, error: action.error ,requestType: 'list', };
    case CONST.FOOD_CATEGORY_SHOW_REQUEST:
      return { ...state, loadingShow: true ,requestType: 'show',};
    case CONST.FOOD_CATEGORY_SHOW_SUCCESS:
      return { ...state, loadingShow: false, foodCategory: action.data ,requestType: 'show',};
    case CONST.FOOD_CATEGORY_SHOW_ERROR:
      return { ...state, loading: false, error: action.error ,requestType: 'show',};
    default:
      return state;
  }
};
