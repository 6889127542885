import { gql } from 'apollo-boost';

import { axios } from '../axios';

export const getDrivers = (params = {}) => {
  return axios.get('/drivers', { params });
};

export const getDriversQuery = gql`
  query Drivers($where: QueryDriversWhereWhereConditions) {
    drivers(where: $where) {
      data {
        id
        first_name
        last_name
      }
    }
  }
`;
