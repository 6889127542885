import * as CONST from '../../constants';

const INITIAL_STATE = { data: [] };

export const productTypes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.PRODCUCT_TYPES_LIST:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
