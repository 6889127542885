import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';

import { MEAL_STATUS } from '../../../constants/meals';
import { axios } from '../../../services/axios';
import {
  MealValidationActionTypes,
  setOnConfirm as setOnConfirmAction,
  validateAffectedOrders as validateAffectedOrdersAction,
} from '../../../store/actions';
import { SearchMeal } from './searchMeal';

const ACTION_TYPES = { REMOVE: 'remove', REPLACE: 'replace' };

const ChangeStatus = ({ mealId, mealStatus, validateAffectedOrders, setOnConfirm }) => {
  const [modal, setModal] = useState(false);
  const [affectedMenus, setAffectedMenus] = useState([]);
  const [selectedAction, setSelectedAction] = useState('');
  const [loading, setLoading] = useState(false);
  const [newMeal, setNewMeal] = useState(null);

  const toggleModal = () => {
    setNewMeal(null);
    setModal(!modal);
  };
  const handleChangeAction = (event) => {
    setSelectedAction(event.target.value);
    setNewMeal(null);
  };

  const changeMealStatusValidation = () => {
    if (mealStatus === MEAL_STATUS.ACTIVE) {
      validateAffectedOrders({
        mealId,
        type: MealValidationActionTypes.DEACTIVATE_MEAL,
      }).then((ordersCount) => {
        if (ordersCount && ordersCount > 0) {
          setOnConfirm(changeMealStatusAction);
        } else {
          changeMealStatusAction();
        }
      });
    } else {
      changeMealStatusAction();
    }
  };

  const changeMealStatusAction = async () => {
    setLoading(true);
    if (mealStatus === MEAL_STATUS.ACTIVE) {
      return axios
        .get(`/meals/${mealId}/active-menus`)
        .then((response) => {
          const affectedMenu = response.data;
          if (affectedMenu.length === 0) {
            return changeStatusAPI({
              item_id: mealId,
              status: MEAL_STATUS.INACTIVE,
              action: ACTION_TYPES.REMOVE,
            });
          } else {
            setAffectedMenus(affectedMenu);
            toggleModal();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      return changeStatusAPI({
        item_id: mealId,
        status: MEAL_STATUS.ACTIVE,
      });
    }
  };

  const onChangeNewMeal = (selectedMeal) => {
    setNewMeal(selectedMeal);
  };

  const changeStatusAPI = (body) => {
    return axios
      .put('/meals/change-meal-status', body)
      .then(() => {
        return onSuccess(body.status);
      })
      .catch((e) => {
        let errorMessages = 'Something went wrong!';
        if (e.response?.data?.errors) {
          errorMessages = Object.keys(e.response?.data?.errors)
            .map((key) => {
              return `<pre>${key} : ${e.response.data?.errors?.[key]}</pre>`;
            })
            .join(' ');
        } else if (e.response?.data?.message) {
          errorMessages = e.response.data?.message;
        }
        return Swal.fire({
          title: "Meal Status can't be changed",
          type: 'error',
          html: errorMessages,
          timer: 5000,
        });
      });
  };

  const onSubmit = () => {
    let body = {};
    if (selectedAction === ACTION_TYPES.REMOVE) {
      body = { item_id: mealId, status: MEAL_STATUS.INACTIVE, action: ACTION_TYPES.REMOVE };
    } else if (selectedAction === ACTION_TYPES.REPLACE) {
      body = {
        item_id: mealId,
        status: MEAL_STATUS.INACTIVE,
        action: ACTION_TYPES.REPLACE,
        new_item_id: newMeal?.value,
      };
    }
    if (Object.keys(body).length !== 0) {
      return changeStatusAPI(body);
    }
  };

  const onSuccess = (newStatus) => {
    return Swal.fire({
      title: `Meal ${
        newStatus === MEAL_STATUS.INACTIVE ? 'Deactivated' : 'Activated'
      } successufully`,
      type: 'success',
      text: 'Meal status changed successufully!',
      timer: 3000,
    }).then(() => window.location.reload());
  };

  const isSubmitDisabled =
    !selectedAction || (selectedAction === ACTION_TYPES.REPLACE && !newMeal?.value);
  return (
    <div>
      <Button
        color={mealStatus === MEAL_STATUS.ACTIVE ? 'danger' : 'success'}
        onClick={changeMealStatusValidation}
        className={'btn m-btn--pill m-btn m-btn--custom'}
        disabled={loading}>
        {mealStatus === MEAL_STATUS.ACTIVE ? 'Deactivate' : 'Activate'}
      </Button>
      <Modal toggle={toggleModal} isOpen={modal}>
        <ModalHeader>{'Change Status'}</ModalHeader>
        <ModalBody>
          <label className={'col-9 col-form-label'}>{`${affectedMenus?.length ||
            0} Affected Menus`}</label>
          <label className={'col-6 col-form-label'}>{'Menu Id'}</label>
          <label className={'col-form-label'}>{'Days'}</label>
          <div className={'col-12'} style={{ maxHeight: '200px', overflowY: 'scroll' }}>
            {affectedMenus?.map((menu) => {
              return (
                <>
                  <label className={'col-6 col-form-label'}>
                    <a
                      target={'_blank'}
                      href={`${process.env.REACT_APP_NEW_DASHBOARD_URL}/food/menus/${menu?.menu_id}`}>
                      {menu?.menu_id}
                    </a>
                  </label>
                  <label className={'col-form-label'}>{menu?.count}</label>
                </>
              );
            })}
          </div>
          <hr className={'m-4'} />
          <div className={'mb-4'}>
            <label>{'Select action you need to take:'}</label>

            <RadioGroup
              aria-label={'action-type'}
              name={'action-type'}
              value={selectedAction}
              onChange={handleChangeAction}>
              <FormControlLabel
                value={'remove'}
                control={<Radio color={'primary'} />}
                label={'Remove from all menu'}
              />
              <FormControlLabel
                value={'replace'}
                control={<Radio color={'primary'} />}
                label={'Replace with another meal'}
              />
            </RadioGroup>

            {selectedAction === ACTION_TYPES.REPLACE ? (
              <SearchMeal
                mealId={mealId}
                selectedMeal={newMeal}
                onChangeSelectedMeal={onChangeNewMeal}
              />
            ) : null}
          </div>

          <ModalFooter>
            <Button
              color={'success'}
              className={'btn btn-primary'}
              onClick={onSubmit}
              disabled={isSubmitDisabled}>
              {'Submit'}
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default connect(null, {
  validateAffectedOrders: validateAffectedOrdersAction,
  setOnConfirm: setOnConfirmAction,
})(ChangeStatus);
