import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { axios } from '../../../services/axios';

export default class Delete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.createHoliday = this.createHoliday.bind(this);
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  createHoliday(event) {
    event.preventDefault();

    axios.delete(`off-days/${this.props.id}`).then((response) => {
      console.log(response);
      if (!response) {
        this.setState({
          modal: false,
        });
        swal.fire(
          {
            type: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            timer: 1000,
          },
          function() {},
        );
      } else if (response.status === 200) {
        this.setState({
          modal: false,
        });
        this.props.onDelete();
        swal.fire(
          {
            title: 'Delete holiday',
            type: 'success',
            text: 'Holiday has been deleted',
            timer: 1000,
          },
          function() {},
        );
      }
    });
  }
  render() {
    return (
      <div>
        <Button
          className={
            'm-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--icon m-btn--icon-only m-btn--pillm-dropdown__toggle'
          }
          onClick={this.toggle}>
          {this.props.children}
        </Button>

        <Modal isOpen={this.state.modal}>
          <form onSubmit={this.createHoliday}>
            <ModalHeader>{'Delete Holiday'}</ModalHeader>
            <ModalBody>
              <h3>
                {'Are you sure to delete '}
                {this.props.name}
              </h3>
            </ModalBody>
            <ModalFooter>
              <input
                type={'submit'}
                value={'Submit'}
                color={'primary'}
                className={'btn btn-primary'}
              />
              <Button color={'danger'} onClick={this.toggle}>
                {'Cancel'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}
