import { axios } from '../../services/axios';
import Alert from '../../utils/alert';

export const uploadImage = (image) => {
  const formData = new FormData();
  formData.append('image', image);
  return axios
    .post(`/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((err) => {
      return err;
    });
};

export const deleteImage = (image) => {
  return axios
    .post(`/delete-image`, { image_url: image })
    .then((res) => {
      return res?.data?.is_deleted;
    })
    .catch((err) => {
      return false;
    });
};

export const duplicateImages = (urls) => {
  return axios
    .post(`/image/duplicate`, { images: urls })
    .then((res) => res.data.images)
    .catch(() => Alert.error('Something went wrong'));
};
