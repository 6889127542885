import WebsocketWorker from 'workerize-loader?inline!../../../websocket.worker.js'; // eslint-disable-line import/no-webpack-loader-syntax

import { axios } from '../../../services/axios';
import * as CONST from '../../constants';
import { handleUpdateSingleOrderSubmit } from './driverOrders.action';

const websocketWorker = new WebsocketWorker();
let orders = [];

const onSuccess = (dispatch, data) => {
  orders = data.data;
  dispatch({ type: CONST.MAP_ORDERS_LIST_SUCCESS, data: data.data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.MAP_ORDERS_LIST_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const ordersList = (params) => {
  console.log('status: ', params.status);
  console.log('status length: ', params.status.length);
  // TODO need to remove this check from here and fix the bug proparly
  if (params.status.length === 0) {
    params.status = ['ACCEPTED', 'ASSIGNED', 'FAILED', 'SUCCESSFUL'];
  }
  return async (dispatch) => {
    dispatch(initiateSocket)
    dispatch({ type: CONST.MAP_ORDERS_LIST_REQUEST });

    axios.get('/tasks', { params }).then((response) => handleResponse(dispatch, response));
  };
};

export const updateOrder = (updatedOrder, status, driver = null) => {
  return async (dispatch) => {
    orders = orders.map((order) => {
      if (parseInt(order.id) === parseInt(updatedOrder.id)) {
        order.status = status;
        if (driver !== null) {
          order.driver = {
            id: driver.id,
            name: driver.name,
            phone: driver.contact_phone,
          };
        }
        updatedOrder = order;

        console.log('DATA', updatedOrder);

        // toastr.options = { "positionClass" : "toast-bottom-right" }
        // toastr.info("Order "+updatedOrder.id+" has updated");
      }
      return order;
    });

    dispatch({ type: CONST.MAP_ORDERS_LIST_UPDATE, data: orders, updatedData: [updatedOrder] });
  };
};

export const updateOrders = (updatedOrders, driver, status = 'ASSIGNED', reOrder = true) => {
  return (dispatch) => {
    // UPDATE ORDERS PRIORITY
    let priority = 0;
    updatedOrders.map(function(order) {
      if (reOrder == true) {
        order.priority = priority;
      }
      order.status = status;
      if (driver && driver.id) {
        order.driver = {
          id: driver.id,
          name: driver.name,
          phone: driver.phone,
        };
      } else {
        order.driver = null;
      }
      priority++;
      return order;
    });

    // UPDATE ORDERS
    let updatedOrder;
    orders = orders.map(function(order) {
      updatedOrder = updatedOrders.find(
        (updatedOrder) => parseInt(updatedOrder.id) === parseInt(order.id),
      );
      if (updatedOrder) {
        // toastr.options = { "positionClass" : "toast-bottom-right" }
        // toastr.info("Order "+updatedOrder.id+" has updated");
        return updatedOrder;
      }
      return order;
    });

    dispatch({ type: CONST.MAP_ORDERS_LIST_UPDATE, data: orders, updatedData: updatedOrders });
  };
};

export const switchOrdersFromDriverToAnother = (clientsIds, driverTo, driverFrom = null) => {
  return (dispatch, getState) => {
    const reOrder = false;
    let updatedOrders = [];

    const driverObj = getState().mapDrivers.data.find((driver) => driver.id === driverTo.id);

    // COME FROM SWITCH TASK WITHOUT SPECIFIC ORDERS
    if (driverFrom) {
      updatedOrders = orders.filter(
        (order) => order.driver && parseInt(order.driver.id) == parseInt(driverFrom.id),
      );

      dispatch(handleUpdateSingleOrderSubmit(driverObj, updatedOrders.length));
      dispatch(handleUpdateSingleOrderSubmit(driverFrom, Number(`-${updatedOrders.length}`)));
    } else {
      clientsIds = clientsIds
        .toString()
        .split(',')
        .map((id) => parseInt(id));

      updatedOrders = orders
        .filter((order) => clientsIds.indexOf(order.client.id) > -1)
        .sort((a, b) => {
          return clientsIds.indexOf(a.client.id) - clientsIds.indexOf(b.client.id);
        });

      dispatch(handleUpdateSingleOrderSubmit(driverObj, updatedOrders.length));
    }

    dispatch(updateOrders(updatedOrders, driverTo, 'ASSIGNED', reOrder));
  };
};

export const initiateSocket = () => (dispatch, getState) => {
  const { auth: { token, currentUser } } = getState()
  websocketWorker.initiate(token, currentUser.adminId);

  // CONNECT SOCKET THROUGH WEB WORKER
  websocketWorker.postMessage({ event: 'connect' });

  // RECEIVE EVENTS/MESSAGES FROM WEB WORKER
  websocketWorker.onmessage = (e) => {
    const data = e.data.data;
    const event = e.data.event;

    if (event === 'connected') console.log('CONNECTED SUCCESSFULLY');

    if (event === 'disconnected') console.log('DISCONNECTED');

    // UPDATE ORDERS STATUS
    if (event === 'updateTaskStatus') {
      // console.log('Message from Workers to ORDERS: ', event);
      // console.log('Message from Workers to ORDERS: ', data);
      const updatedOrder = orders.find((order) => parseInt(order.id) === parseInt(data.taskId));
      dispatch(updateOrder(updatedOrder, data.status));
    }
  };
};
