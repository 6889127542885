import { Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';

export const ZohoConfigurationSubForm = ({ handleChange, formValues }) => {
  return (
    <>
      <div className={'form-group m-form__group'}>
        <label>{'Zoho Configuration'}</label>
        <RadioGroup
          aria-label='has_zoho_account'
          name='has_zoho_account'
          value={formValues.has_zoho_account}
          onChange={handleChange}>
          <FormControlLabel
            value={'no'}
            className={'m-radio-inline'}
            control={<Radio />}
            label={'New Zoho Account'}
          />
          <FormControlLabel
            value={'yes'}
            className={'m-radio-inline'}
            control={<Radio />}
            label={'Already have Zoho Account'}
          />
        </RadioGroup>
      </div>
      {formValues.has_zoho_account === 'yes' ? (
        <div className={'form-group m-form__group'}>
          <label>{'Enter the Vendor Zoho Id to be linked with'}</label>
          <input
            type={'number'}
            maxLength={20}
            name={'zoho_id'}
            autoComplete='off'
            required
            className={'form-control m-input m-input--air m-input--pill'}
            value={formValues.zoho_id || ''}
            onChange={handleChange}
          />
          <p style={{ color: 'red' }}>
            {formValues.validation.zoho_id != '' ? formValues.validation.zoho_id : ''}
          </p>
        </div>
      ) : (
        <div className={'form-group m-form__group'}>
          <label>{'Enter the Tax Registration Number'}</label>
          <input
            type={'number'}
            maxLength={20}
            autoComplete='off'
            name={'tax_registration_id'}
            className={'form-control m-input m-input--air m-input--pill'}
            value={formValues.tax_registration_id || ''}
            onChange={handleChange}
          />
          <p style={{ color: 'red' }}>
            {formValues.validation.tax_registration_id != ''
              ? formValues.validation.tax_registration_id
              : ''}
          </p>
        </div>
      )}
    </>
  );
};
