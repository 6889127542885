import Swal from 'sweetalert2';
import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.SUBSCRIPTION_START_DATE_CHANGE_SUCCESS, data: data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.SUBSCRIPTION_START_DATE_CHANGE_ERROR, error });
  Swal.fire({
    title: 'Error',
    type: 'error',
    text: error?.message,
    timer: 3500,
  });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const subscriptionChangeStartDate = (subscriptionId, startDate) => {
  return async (dispatch) => {
    dispatch({ type: CONST.SUBSCRIPTION_START_DATE_CHANGE_REQUEST });
    const body = { start_date: startDate };

    axios
      .patch(`/subscriptions/${subscriptionId}/delay`, body)
      .then((response) => {
        handleResponse(dispatch, response);
      })
      .catch((error) => {
        onFailed(dispatch, error?.response?.data);
      });
  };
};
