import React, { useEffect, useState } from 'react';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import swal from 'sweetalert2';

import { submitAttributes } from '../../../api/meals/mealAttributes';
import { useFetchMealAttributes } from '../hooks/useFetchMealAttributes';
import { AMAZON_CLOUD_FRONT_URL } from '../../../constants/meals';

const FormFields = {
  cuisine: 'cuisine',
  dishType: 'dishType',
  diet: 'diet',
  allergies: 'allergies',
  mainIngredient: 'mainIngredient',
};

const EditMealAttributesModal = ({ meal, modal, toggleModal }) => {
  const {
    handleSubmit,
    reset,
    formState: { dirty },
    control,
    errors,
  } = useForm({
    defaultValues: {},
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { attributesOptions, mealAttributes, isLoading } = useFetchMealAttributes(meal?.item_id);

  useEffect(() => {
    if (!!attributesOptions && !!mealAttributes) {
      const defaultValues = {};
      [
        FormFields.cuisine,
        FormFields.dishType,
        FormFields.diet,
        FormFields.allergies,
        FormFields.mainIngredient,
      ].forEach((field) => {
        const selectedOptions = mealAttributes?.[field]?.map((attribute) => attribute.id);
        defaultValues[field] = attributesOptions?.[field]?.filter((option) =>
          selectedOptions?.includes(option.value),
        );
      });

      reset({ ...defaultValues });
    }
  }, [attributesOptions, mealAttributes, reset]);

  const onCancel = () => {
    toggleModal();
  };

  const isSubmitDisabled = isLoading || isSubmitting || !dirty || errors.length > 0;
  const onSubmit = (data) => {
    const parsedAttributes = {
      mealId: meal?.item_id,
      cuisineIds: data[FormFields.cuisine]?.map((option) => option.value),
      dishTypeIds: data[FormFields.dishType]?.map((option) => option.value),
      dietIds: data[FormFields.diet]?.map((option) => option.value),
      allergyIds: data[FormFields.allergies]?.map((option) => option.value),
      mainIngredientIds: data[FormFields.mainIngredient]?.map((option) => option.value),
    };

    setIsSubmitting(true);
    submitAttributes(meal?.item_id, parsedAttributes)
      .then(() => {
        swal.fire({
          title: 'Success',
          type: 'success',
          text: 'Meal Attributes is submitted successfully!',
          timer: 1000,
        });
      })
      .catch(() => {
        swal.fire({
          title: 'Error',
          type: 'error',
          text: 'Something went wrong!',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        toggleModal();
      });
  };

  return (
    <Modal size={'lg'} isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{'Edit Meal Attributes'}</ModalHeader>
      <ModalBody>
        <div style={isLoading ? {} : { display: 'none' }}>
          <div className={'loader'} />
        </div>
        <div style={isLoading ? { display: 'none' } : {}} className={'modal-body-content'}>
          <div>
            <div className={'row mb-3'}>
              <div className={'col-12 row'}>
                <div className={'col-6'}>
                  <label className={'col-12 col-form-label'}>{'Cover Image'}</label>
                  <div className={'col-12'}>
                    {meal.cover_image && (
                      <img
                        className={'img-fluid rounded w-100'}
                        src={`${AMAZON_CLOUD_FRONT_URL}/meals/${meal.cover_image}`}
                      />
                    )}
                  </div>
                </div>
                <div className={'col-6 align-items-center'}>
                  <div className={'col-12'}>
                    <label className={'col-6 col-form-label'}>{'Arabic Name:'}</label>
                    <label id={'meal-ar-name'} className={'col-form-label'}>
                      {meal.ar_name || ''}
                    </label>
                  </div>
                  <hr className={'m-0'} />
                  <div className={'col-12'}>
                    <label className={'col-12 col-form-label pb-1'}>{'Arabic Description:'}</label>
                    <label id={'meal-ar-name'} className={'col-12 col-form-label pt-1'}>
                      {meal.ar_description || ''}
                    </label>
                  </div>
                  <hr className={'m-0'} />
                  <div className={'col-12'}>
                    <label className={'col-6 col-form-label'}>{'English Name:'}</label>
                    <label id={'meal-ar-name'} className={'col-form-label'}>
                      {meal.en_name || ''}
                    </label>
                  </div>
                  <hr className={'m-0'} />
                  <div className={'col-12'}>
                    <label className={'col-12 col-form-label pb-1'}>{'English Description:'}</label>
                    <label id={'meal-ar-name'} className={'col-12 col-form-label pt-1'}>
                      {meal.en_description || ''}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className={'row'}>
              <div className={'col-md-6'}>
                <FormGroup>
                  <Label for={'backdrop'}>{'Cuisine'}</Label>
                  <Controller
                    as={
                      <Select
                        id={'meal-input-cuisine-id'}
                        placeholder={'Select Cuisine'}
                        closeMenuOnSelect={true}
                        isMulti
                        options={attributesOptions.cuisine}
                      />
                    }
                    name={FormFields.cuisine}
                    control={control}
                  />
                  <p className={'invalid-feedback'}>
                    <ErrorMessage errors={errors} name={FormFields.cuisine} />
                  </p>
                </FormGroup>
              </div>

              <div className={'col-md-6'}>
                <FormGroup>
                  <Label for={'backdrop'}>{'Dish Types'}</Label>
                  <Controller
                    as={
                      <Select
                        id={'meal-input-dish-type-id'}
                        placeholder={'Select Dish Type'}
                        closeMenuOnSelect={true}
                        isMulti
                        options={attributesOptions.dishType}
                      />
                    }
                    name={FormFields.dishType}
                    control={control}
                  />
                  <p className={'invalid-feedback'}>
                    <ErrorMessage errors={errors} name={FormFields.dishType} />
                  </p>
                </FormGroup>
              </div>
            </div>

            <div className={'row'}>
              <div className={'col-md-6'}>
                <FormGroup>
                  <Label for={'backdrop'}>{'Diet'}</Label>
                  <Controller
                    as={
                      <Select
                        id={'meal-input-diet-id'}
                        placeholder={'Select Diet'}
                        closeMenuOnSelect={true}
                        isMulti
                        options={attributesOptions.diet}
                      />
                    }
                    name={FormFields.diet}
                    control={control}
                  />
                  <p className={'invalid-feedback'}>
                    <ErrorMessage errors={errors} name={FormFields.diet} />
                  </p>
                </FormGroup>
              </div>

              <div className={'col-md-6'}>
                <FormGroup>
                  <Label for={'backdrop'}>{'Allergies'}</Label>
                  <Controller
                    as={
                      <Select
                        id={'meal-input-allergies-id'}
                        placeholder={'Select Allergies'}
                        closeMenuOnSelect={true}
                        isMulti
                        options={attributesOptions.allergies}
                      />
                    }
                    name={FormFields.allergies}
                    control={control}
                  />
                  <p className={'invalid-feedback'}>
                    <ErrorMessage errors={errors} name={FormFields.allergies} />
                  </p>
                </FormGroup>
              </div>

              <div className={'col-md-6'}>
                <FormGroup>
                  <Label for={'backdrop'}>{'Main Ingredients'}</Label>
                  <Controller
                    as={
                      <Select
                        id={'meal-input-main-ingredient-id'}
                        placeholder={'Select Main Ingredients'}
                        closeMenuOnSelect={true}
                        isMulti
                        options={attributesOptions.mainIngredient}
                      />
                    }
                    name={FormFields.mainIngredient}
                    control={control}
                  />
                  <p className={'invalid-feedback'}>
                    <ErrorMessage errors={errors} name={FormFields.mainIngredient} />
                  </p>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          id={'meal-edit-submit-btn'}
          disabled={isSubmitDisabled}
          color={'primary'}
          onClick={handleSubmit(onSubmit)}>
          {'Submit'}
        </Button>
        <Button color={'danger'} onClick={onCancel}>
          {'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditMealAttributesModal;
