import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  dispatch({ type: CONST.COMPANY_CREATE_SUCCESS, data: data });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.COMPANY_CREATE_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const companyCreate = (data) => {
  return async (dispatch) => {
    dispatch({ type: CONST.COMPANY_CREATE_REQUEST });

    axios.post('/companies', data).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
