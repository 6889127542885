import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { dependentOrdersModalToggle as dependentOrdersModalToggleAction } from '../../../store/actions';

const DependentOrdersModal = ({
  onConfirm,
  ordersCount,
  isDependentOrdersModalOpen,
  dependentOrdersModalToggle,
}) => {
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);

  const submitAddMealsToSingleDayForm = () => {
    if (onConfirm) {
      setIsConfirmButtonLoading(true);
      onConfirm().finally(() => {
        setIsConfirmButtonLoading(false);
        dependentOrdersModalToggle();
      });
    }
  };

  return (
    <Modal isOpen={isDependentOrdersModalOpen} toggle={dependentOrdersModalToggle}>
      <ModalHeader toggle={dependentOrdersModalToggle}>{'Warning!!!'}</ModalHeader>
      <ModalBody>
        <div>
          <div>
            {'Please note that this action affects'}
            <strong>{` ${ordersCount} `}</strong>
            {'active orders and will be '}
            <strong>{'unavailable'}</strong>
            {'.'}
          </div>
          <div>
            {'By confirming, affected clients will be notified via '}
            <strong>{'Intercom'}</strong>
            {' and '}
            <strong>{'email'}</strong>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          id={'meal-edit-submit-btn'}
          disabled={isConfirmButtonLoading}
          color={'primary'}
          onClick={submitAddMealsToSingleDayForm}>
          {'Confirm'}
        </Button>
        <Button
          color={'danger'}
          onClick={dependentOrdersModalToggle}
          disabled={isConfirmButtonLoading}>
          {'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    ordersCount: state.validationModal.ordersCount,
    onConfirm: state.validationModal.onConfirm,
    isDependentOrdersModalOpen: state.validationModal.isDependentOrdersModalOpen,
  };
};

export default connect(mapStateToProps, {
  dependentOrdersModalToggle: dependentOrdersModalToggleAction,
})(DependentOrdersModal);
