import * as CONST from '../../constants';

const INITIAL_STATE = {
  loading: true,
  data: [],
  error: null,
  updatedData: [],
  selectedDrivers: [],
  selectedOrders: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.MAP_DRIVERS_LIST_REQUEST:
      return { ...state, loading: true };
    case CONST.MAP_DRIVERS_LIST_SUCCESS:
      return { ...state, loading: false, data: action.data.data };
    case CONST.MAP_DRIVERS_LIST_ERROR:
      return { ...state, loading: false, error: action.error };
    case CONST.MAP_DRIVERS_LIST_UPDATE:
      return { ...state, loading: false, data: action.data, updatedData: action.updatedData };
    case CONST.MAP_DRIVERS_SELECTED:
      return { ...state, selectedDrivers: action.selectedDrivers };
    case CONST.MAP_DRIVERS_ORDERS_SELECTED:
      return { ...state, selectedOrders: action.selectedOrders };
    default:
      return state;
  }
};
