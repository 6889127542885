import { arrayMove } from 'react-sortable-hoc';
import toastr from 'toastr';

import groupAssignClientsService from '../../../services/assign/groupAssignService';
import unassignService from '../../../services/assign/unassignService';
import _getDriverOrders from '../../../services/driver/getDriverOrders';
import Alert from '../../../utils/alert';
import * as CONST from '../../constants';
import { updateOrders } from '../index';
import { driversList } from './drivers.action';

const onSuccess = (dispatch, data) => {
  dispatch({
    type: CONST.MAP_DRIVER_ORDERS_LIST_SUCCESS,
    data: data.data,
  });
};

const onFailed = (dispatch, error) => {
  dispatch({ type: CONST.MAP_DRIVER_ORDERS_LIST_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response.status === 200) {
    onSuccess(dispatch, response.data);
  } else {
    onFailed(dispatch, response.data);
  }
};

export const handleSorting = ({ oldIndex, newIndex }) => {
  return async (dispatch, getState) => {
    const orders = arrayMove(getState().driverOrders.data, oldIndex, newIndex);

    dispatch({
      type: CONST.MAP_DRIVER_ORDERS_LIST_UPDATE,
      data: orders,
    });
  };
};

export const getDriverOrders = (driverId, params) => {
  return async (dispatch) => {
    dispatch({ type: CONST.MAP_DRIVER_ORDERS_LIST_REQUEST });

    _getDriverOrders(driverId, params).then((response) => handleResponse(dispatch, response));
  };
};

export const addOrdersToDriverOrders = (ordersIds) => {
  return async (dispatch, getState) => {
    toastr.options = { positionClass: 'toast-bottom-right' };

    const orders = getState().driverOrders.data;

    ordersIds.forEach((orderId) => {
      // GET ORDER OBJECT BY ORDER ID
      const order = getState().mapOrders.data.find(
        (order) => parseInt(order.id) === parseInt(orderId),
      );

      // IF ORDER NOT ADDED BEFORE TO THIS DRIVER, ADD IT TO HIM
      if (orders.indexOf(order) == -1) {
        orders.push(order);
        toastr.success('Order added successfully');
      } else {
        toastr.warning('Order already added before');
      }
    });

    dispatch({
      type: CONST.MAP_DRIVER_ORDERS_LIST_UPDATE,
      data: orders,
    });
  };
};

export const removeOrderFromDriverOrders = (orderId) => {
  return async (dispatch, getState) => {
    toastr.options = { positionClass: 'toast-bottom-right' };

    // GET ORDER OBJECT BY ORDER ID
    const order = getState().driverOrders.data.find(
      (order) => parseInt(order.id) === parseInt(orderId),
    );

    if (order) {
      // REMOVE ORDER FROM DRIVER ORDERS
      const orders = getState().driverOrders.data.filter(
        (ord) => parseInt(ord.id) !== parseInt(order.id),
      );

      // ADD ORDER TO UNASSIGN ORDERS IF NOT EXISTS
      const removedOrders = getState().driverOrders.removedOrders;
      const removedOrder = removedOrders.find(
        (removedOrder) => parseInt(removedOrder.id) === parseInt(orderId),
      );

      // CHECK IF ORDER NOT IN REMOVED ORDERS ARRAY
      // && THIS ORDER IS ASSIGNED TO A DRIVER BEFORE
      if (!removedOrder && order.driver) {
        removedOrders.push(order);
        toastr.info('Order removed successfully');
      }

      dispatch({
        type: CONST.MAP_DRIVER_ORDERS_LIST_UPDATE,
        data: orders,
        removedOrders: removedOrders,
      });
    } else {
      toastr.warning('Order not exists !');
    }
  };
};

export const handleUpdateOrdersSubmit = (driver) => {
  return async (dispatch, getState) => {
    let clientsIds = '';
    const driverId = driver.id;
    const date = getState().mapFilter.date;

    getState().driverOrders.data.map((order, i) => {
      clientsIds +=
        i + 1 == getState().driverOrders.data.length ? order.client.id : `${order.client.id},`;
    });

    groupAssignClientsService(clientsIds, driverId, date).then((response) => {
      if (response.status === 200) {
        Alert.success('Group Task Assigned successfully ', 3000);
        // UPDATE DRIVER ORDERS COUNT
        getState().mapDrivers.data.find(
          (driver) => driver.id === driverId,
        ).orders_count = getState().driverOrders.data.length;

        // UPDATE ORDERS ON MAP
        dispatch(updateOrders(getState().driverOrders.data, driver));

        dispatch(
          driversList({
            count: 0,
            status: 'active',
            date,
            include: ['teams'],
          }),
        );

        dispatch({
          type: CONST.MAP_DRIVER_ORDERS_LIST_SUCCESS,
          data: [],
        });
      }
    });

    if (getState().driverOrders.removedOrders.length > 0) {
      unassignService(getState().driverOrders.removedOrders.map((order) => order.id)).then(
        (response) => {
          if (response.status === 200) {
            dispatch(updateOrders(getState().driverOrders.removedOrders, null, 'ACCEPTED', false));
          } // END IF
        },
      );
    }
  };
};

export const handleUpdateSingleOrderSubmit = (driver, count = 1) => {
  return async (dispatch, getState) => {
    let clientsIds = '';
    const driverId = driver?.id;
    const date = getState().mapFilter.date;

    getState().driverOrders.data.map((order, i) => {
      clientsIds +=
        i + 1 === getState().driverOrders.data.length ? order.client.id : `${order.client.id},`;
    });

    const driverObj = getState().mapDrivers.data.find((driver) => driver.id === driverId);

    dispatch(
      driversList({
        count: 0,
        status: 'active',
        date,
        include: ['teams'],
      }),
    );

    // UPDATE DRIVER ORDERS COUNT
    if (driverObj) {
      driverObj.orders_count = driverObj?.orders_count + count;
    }
  };
};
