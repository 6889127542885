import * as CONST from '../../../constants';

const INITIAL_STATE = { loading: true, complaint: null, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.COMPLAINT_ACTION_CREATE_REQUEST:
      return { ...INITIAL_STATE, loading: true };
    case CONST.COMPLAINT_ACTION_CREATE_SUCCESS:
      return { ...INITIAL_STATE, loading: false, complaint: action.complaint };
    case CONST.COMPLAINT_ACTION_CREATE_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    default:
      return state;
  }
};
