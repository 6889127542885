import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, users: [], error: null, paginator: { links: {}, meta: {} } };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.USER_LIST_REQUEST:
      return { ...INITIAL_STATE, loading: true };
    case CONST.USER_LIST_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        users: action.data.data.users,
        paginator: action.data.paginator,
      };
    case CONST.SUBSCRIPTIONS_INDEX_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error };
    default:
      return state;
  }
};
