import { axios } from '../../../services/axios';
import * as CONST from '../../constants';

const onSuccess = (dispatch, data) => {
  return dispatch({ type: CONST.PLAN_EDIT_SUCCESS, plan: data.plan });
};

const onFailed = (dispatch, error) => {
  return dispatch({ type: CONST.PLAN_EDIT_ERROR, error });
};

const handleResponse = (dispatch, response) => {
  if (response?.status === 200) {
    return onSuccess(dispatch, response.data);
  } else {
    return onFailed(dispatch, response.data);
  }
};

export const planEdit = (planId, bodyData) => {
  return async (dispatch) => {
    dispatch({ type: CONST.PLAN_EDIT_REQUEST });

    return axios.post(`/plans/${planId}`, bodyData).then((response) => {
      handleResponse(dispatch, response);
    });
  };
};
