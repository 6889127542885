import * as CONST from '../../../constants';

const INITIAL_STATE = { loading: true, categoryType: null, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.CATEGORY_TYPE_CREATE_REQUEST:
      return { ...INITIAL_STATE, loading: true, categoryType: null, error: null };
    case CONST.CATEGORY_TYPE_CREATE_SUCCESS:
      return { ...INITIAL_STATE, loading: false, categoryType: action.categoryType, error: null };
    case CONST.CATEGORY_TYPE_CREATE_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error, categoryType: null };
    case CONST.CLOSE_MODAL:
      return { ...INITIAL_STATE, loading: false, error: null, categoryType: null };
    default:
      return state;
  }
};
