import * as CONST from '../../constants';

const INITIAL_STATE = { loading: true, menu: null, error: null };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.MENU_DUPLICATE_REQUEST:
      return { ...INITIAL_STATE, loading: true, menu: null, error: null };
    case CONST.MENU_DUPLICATE_SUCCESS:
      return { ...INITIAL_STATE, loading: false, menu: action.menu, error: null };
    case CONST.MENU_DUPLICATE_ERROR:
      return { ...INITIAL_STATE, loading: false, error: action.error, menu: null };
    case CONST.CLOSE_MODAL:
      return { ...INITIAL_STATE, loading: false, error: null, menu: null };
    default:
      return state;
  }
};
